import React, { useEffect, useState } from 'react';
import PollyIcon from '../../../../assets/icons/generalIcons/whitePollyIcon.svg';
import loadingIcon from '../../../../assets/icons/register/terms/loading.svg';
import { Input } from '../../../../components';
import { useRegistration } from '../../../../helpers/hooks/useRegistration';
import { REGISTER_PACKS, PROGRAM_NAMES } from '../../../../helpers/constants';
import { useWeb3React } from '@web3-react/core';
import { shortenAddress } from '../../../../helpers/format';
import { getAddress } from '../../../../helpers/format';
import {
  CHECK_UPLINE_ADDRESS,
  CHECK_UPLINE_INTERNAL_ID,
  CHECK_UPLINE_REFNUMBER,
} from '../../../../helpers/graphRequests';
import { useQuery } from '@apollo/client';
import { parseCookies } from 'nookies';
import { useCallTransaction } from '../../../../helpers/hooks/useCallTransaction';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../../components/Button';
import useTokenPrice from '../../../../helpers/hooks/useTokenPrice';
import config from '../../../../helpers/config';

import { useTierUpline } from '../../../../helpers/hooks/useTierUpline';
import { ActivateModal } from '../../../../components/Modals/ActivateModal';
import { useModal } from '../../../../helpers/hooks/useModal';

const callTypes = {
  WALLET: 'wallet',
  ID: 'id',
  KEY: 'key',
  EXIST: 'exist',
};

export const RegisterBar = ({ planId = 0, totalPrice = 0, fee = 0 }) => {
  const cookies = parseCookies();
  const [uplineValue, setUplineValue] = useState(null);
  const [uplineKey, setUplineKey] = useState(null);
  const [uplineId, setUplineId] = useState(null);
  const [uplineWallet, setUplineWallet] = useState(null);
  const [approval, setApproval] = useState(null);
  const [callType, setCallType] = useState(null);
  const [onlyCallTiers, setOnlyCallTiers] = useState(true);
  const [isExist, setIsExist] = useState(null);

  const [currentError, setCurrentError] = useState(null);

  const { registration, isLoadingRegistration } = useRegistration();
  // call tier upline
  const { upline: uplineTier, getUpline, resetUplineTier, isCalled: isCalledUplineTier } = useTierUpline();
  const { account, provider } = useWeb3React();

  useEffect(() => {
    if (account) {
      setIsExist(null);
      setApproval(null);
      setCallType(null);
      setOnlyCallTiers(true);
      resetUpline();
      setCurrentError(null);
      resetUplineTier();
      getUpline();
    }
  }, [account]);

  useEffect(() => {
    if (account) {
      if (isExist === null) {
        setCallType(callTypes.EXIST);
      }
      if (!isExist && isExist !== null) {
        if (uplineTier && isCalledUplineTier) {
          setUplineWallet(uplineTier?.toLowerCase());
          setCallType(callTypes.WALLET);
          setOnlyCallTiers(true);
        } else {
          if (cookies?.polynetica_upline_key && onlyCallTiers && isCalledUplineTier && !uplineTier) {
            setUplineKey(cookies?.polynetica_upline_key);
            setCallType(callTypes.KEY);
          }
        }
      }
    }
  }, [account, isExist, cookies, onlyCallTiers, uplineTier, isCalledUplineTier]);

  const { onCallTransaction, transactionInfo, resetTransactionInfo } = useCallTransaction();
  const navigate = useNavigate();

  const { fetchTokenAmount, tokenAmount } = useTokenPrice();

  const feeInt = fee / 1e18;

  const feeForContract = feeInt * 0.45 * 0.9;

  useEffect(() => {
    if (account && config.stand === 'prod') {
      fetchTokenAmount(config.contractPollyPool, provider, feeForContract);
    }
  }, [provider, feeForContract]);

  const clickRegistration = async () => {
    try {
      const levelsX2 = REGISTER_PACKS[planId][PROGRAM_NAMES.X2];
      const levelsX4 = REGISTER_PACKS[planId][PROGRAM_NAMES.X4];

      let finalId = currentError === 'User not exist' ? 1 : uplineId;

      if (!uplineWallet && !Number(uplineKey) && !uplineId) {
        finalId = 1;
      }

      const result = await registration(
        uplineWallet,
        Number(uplineKey),
        finalId,
        levelsX2,
        levelsX4,
        totalPrice,
        BigInt(Math.floor(tokenAmount)),
      );

      if (result) {
        onCallTransaction(result);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const resetUpline = () => {
    setUplineValue(null);
    setUplineId(null);
    setUplineKey(null);
    setUplineWallet(null);
  };

  const uplineValidate = (uplineData) => {
    setOnlyCallTiers(false);
    if (uplineData) {
      resetUpline();
      if (getAddress(uplineData)) {
        setUplineWallet(uplineData?.toLowerCase());
        setCallType(callTypes.WALLET);
      } else {
        setUplineId(Number(uplineData));
        setCallType(callTypes.ID);
      }
    } else {
      resetUpline();
      setUplineId(1);
      setCallType(callTypes.ID);
    }
  };

  const onChangeUpline = (value) => {
    setUplineKey(null);
    // setApproval(false);
    setUplineValue(value);

    if (value && !isExist) {
      uplineValidate(value);
    }
  };

  const {
    data: dataAddress,
    loading: loadingAddress,
    called: calledAddress,
    error: errorAddress,
    refetch: refetchAddress,
  } = useQuery(CHECK_UPLINE_ADDRESS, {
    variables: { user: uplineWallet },
    skip: callType !== callTypes.WALLET,
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: dataId,
    loading: loadingId,
    called: calledId,
    error: errorId,
    refetch: refetchId,
  } = useQuery(CHECK_UPLINE_INTERNAL_ID, {
    variables: { internalId: uplineId },
    skip: callType !== callTypes.ID,
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: dataKey,
    loading: loadingKey,
    called: calledKey,
    error: errorKey,
    refetch: refetchKey,
  } = useQuery(CHECK_UPLINE_REFNUMBER, {
    variables: { uplineKey },
    skip: callType !== callTypes.KEY,
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: dataExist,
    loading: loadingExist,
    called: calledExist,
    error: errorExist,
  } = useQuery(CHECK_UPLINE_ADDRESS, {
    variables: { user: account?.toLocaleLowerCase() },
    skip: callType !== callTypes.EXIST,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!!dataExist && !!Object.keys(dataExist)?.length) {
      if (!dataExist?.users.length) {
        setIsExist(false);
      } else {
        setIsExist(true);
      }
    } else {
      setIsExist(false);
    }
  }, [dataExist]);

  useEffect(() => {
    if (loadingAddress || loadingId || loadingKey) {
      setApproval(false);
    }
  }, [loadingAddress, loadingId, loadingKey]);

  useEffect(() => {
    if (!uplineWallet && !Number(uplineKey) && !uplineId) {
      setApproval(true);
    }
  }, [uplineWallet, uplineKey, uplineId]);

  useEffect(() => {
    setCurrentError(null);
    if (callType === callTypes.EXIST && !!dataExist?.users.length) {
      setApproval(false);
      setCurrentError('You already registered');
    } else {
      if (callType === callTypes.WALLET) {
        if (!!dataAddress?.users.length) {
          setApproval(true);
        }
        if ((!!dataAddress && !!Object.keys(dataAddress)?.length && !dataAddress?.users.length) || errorAddress) {
          setCurrentError('User not exist');
          setApproval(true);
        }
      } else if (callType === callTypes.ID) {
        if (!!dataId?.users.length) {
          setApproval(true);
        }
        if ((!!dataId && !!Object.keys(dataId)?.length && !dataId?.users.length) || errorId) {
          setCurrentError('User not exist');
          setApproval(true);
        }
      } else if (callType === callTypes.KEY) {
        if (!!dataKey?.users.length) {
          setApproval(true);
        }
        if ((!!dataKey && !!Object.keys(dataKey)?.length && !dataKey?.users.length) || errorKey) {
          setCurrentError('User not exist');
          setApproval(true);
        }
      }
    }
  }, [
    callType,
    dataAddress,
    dataId,
    dataKey,
    dataExist,
    errorAddress,
    errorId,
    errorKey,
    calledId,
    calledAddress,
    calledKey,
  ]);

  useEffect(() => {
    if (dataKey?.users[0]?.id) {
      setUplineValue(dataKey?.users[0]?.id);
      setUplineWallet(dataKey?.users[0]?.id);
    }
  }, [dataKey]);

  useEffect(() => {
    if (dataId?.users[0]?.id) {
      setUplineValue(dataId?.users[0]?.internalId);
      setUplineId(dataId?.users[0]?.internalId);
    }
  }, [dataId]);

  useEffect(() => {
    if (dataAddress?.users[0]?.id) {
      setUplineValue(dataAddress?.users[0]?.id);
      setUplineWallet(dataAddress?.users[0]?.id);
    }
  }, [dataAddress]);

  useEffect(() => {
    if (transactionInfo.isSuccess) {
      setTimeout(() => {
        navigate('/dashboard');
      }, 5000);
    }
  }, [transactionInfo]);

  const renderLoading = () => {
    if (!account) {
      return {
        title: 'Wait connect wallet',
        icon: 'loading-animation__purple',
      };
    } else if (currentError) {
      return {
        title: currentError,
        icon: 'alert-animation',
      };
    } else if (loadingExist) {
      return {
        title: 'Checking your id',
        icon: 'loading-animation__purple',
      };
    } else if (loadingAddress || loadingId || loadingKey) {
      return {
        title: 'Checking upline',
        icon: 'loading-animation__purple',
      };
    } else {
      return null;
    }
  };

  // connect modal
  const { openedModal, onOpen, onClose } = useModal();

  return (
    <div className="flex space-x-[4rem] ml-[4rem] lg:flex-col lg:ml-0 lg:space-x-0 lg:space-y-[2rem] lg:mt-[2rem] register-plan-animation">
      <div className="border-[0.1rem] border-solid border-white-100" />
      <div className="flex flex-col justify-between space-y-[4.8rem] w-full custom-transition z-[1]">
        <div className="flex flex-col space-y-[2.4rem]">
          <div className="flex items-center justify-between w-full ">
            <img src={PollyIcon} />
            {renderLoading() ? (
              <div className="flex items-center space-x-[1.2rem] shrink-0 pr-[2.4rem] last:pr-0 ">
                <div className={`w-[2.4rem] h-[2.4rem] ${renderLoading()?.icon}`} />
                <span className={`text-[1.4rem] lg:w-full ${'register-text-aniamtion'} lg:whitespace-nowrap`}>
                  {renderLoading()?.title}
                </span>
              </div>
            ) : null}
          </div>
          <div className="flex flex-col space-y-[2.4rem] ">
            <span className="text-[4rem] text-white font-600 space-grotesk tracking-[-0.8px] lg:text-[2.4rem] lg:tracking-[-0.48px]">
              Sign up to Polynetica
            </span>
            {/* <span className="text-[1.4rem] text-white-500 font-500 leading-[2.24rem] max-w-[33rem]">
              Wallet {shortenAddress(account)} is not registered in Forsage. Create an account to join.{' '}
            </span> */}
          </div>
        </div>
        <div className="flex flex-col w-full space-y-[1.2rem]">
          <Input
            type="text"
            readOnly={(uplineTier && isCalledUplineTier) || !!uplineKey}
            onChange={(e) => onChangeUpline(e.target.value)}
            value={uplineValue}
            placeholder="Enter your Upline's ID or Wallet Address"
          />
          {account ? (
            <Button
              className="h-[4.8rem] text-white text-[1.6rem] tracking-[-0.48px] font-500"
              loading={transactionInfo.isWaiting || loadingAddress || loadingId || loadingKey}
              loadingType="white"
              type="purple"
              disabled={!approval || loadingAddress || loadingId || loadingKey}
              onClick={() => clickRegistration()}
            >
              Sign up
            </Button>
          ) : (
            <Button
              className="h-[4.8rem] text-white text-[1.6rem] tracking-[-0.48px] font-500"
              type="purple"
              onClick={() => onOpen()}
            >
              Connect wallet
            </Button>
          )}
        </div>
      </div>
      <ActivateModal openedModal={openedModal} handleCloseModal={onClose} />
    </div>
  );
};
