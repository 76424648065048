import React from 'react';
import WhiteCopyIcon from '../../../../assets/icons/target/earn/spiloverIcon.svg';
import ContractIcon from '../../../../assets/icons/target/earn/contractIcon.svg';
import RefIcon from '../../../../assets/icons/target/earn/refIcon.svg';

export const Cards = () => {
  const cardsList = [
    {
      className: 'left-0 rotate-[-11deg] z-[111] lg:rotate-[-6.54deg] lg:w-[34rem] lg:left-auto lg:right-[1rem]',
      icon: WhiteCopyIcon,
      title: 'Spillover income begins immediately – you unlock levels, you earn crypto',
    },
    {
      className: 'right-0 rotate-[10deg] top-[8rem] z-[11] lg:rotate-[5.129deg] lg:w-[34rem] lg:right-auto lg:left-[2rem] lg:top-[9rem]',
      icon: ContractIcon,
      title: 'Smart contract automation ensures that all earnings are sent directly to your crypto wallet',
    },
    {
      className: 'left-[13rem] rotate-[-4.5deg] top-[20rem] z-[1] lg:rotate-[-6.142deg] lg:w-[34rem] lg:left-auto',
      icon: RefIcon,
      title: 'Referral–based network effect amplifies your total revenue exponentially',
    },
  ];
  return (
    <div className="flex flex-col items-center space-y-[1rem] relative w-full h-[40rem] max-w-[96rem] lg:h-[35rem]">
      {cardsList.map((item, itemIndex) => {
        return (
          <div
            className={`${item?.className} absolute top-0 flex items-center justify-between space-x-[2.5rem] h-[14rem] p-[1rem] max-w-[52rem] border-[0.2rem] border-solid border-white-100 rounded-[3rem] target-cards-bg lg:h-[9rem] lg:rounded-[2rem] lg:p-[0.5rem]`}
            key={itemIndex}
          >
            <div className="flex items-center justify-center bg-[#985AFF] border-[0.1rem] border-solid border-white-300 w-[11.5rem] h-[11.5rem] rounded-[2rem] shrink-0 lg:w-[8rem] lg:h-[8rem] lg:rounded-[1.6rem]">
              <img className="w-[6.4rem] lg:w-[4.4rem]" src={item?.icon} />
            </div>
            <span className="text-[2rem] text-white-500 font-500 leading-[3.2rem] lg:text-[1.4rem] lg:leading-[2.24rem]">
              {item?.title}
            </span>
          </div>
        );
      })}
    </div>
  );
};
