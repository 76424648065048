import React, { useState, useEffect } from 'react';
import { LeftBar, Header } from 'components';
import { MobHeader } from '../../components/MobHeader';
import { useWeb3React } from '@web3-react/core';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { GET_USER_DATA } from '../../helpers/graphRequests';
import { updateUser } from '../../store/userSlice';
import { getUser } from '../../store/userSlice/selectors';
import { useQuery } from '@apollo/client';
import { UserRepository } from '../../connectors/repositories/user';
import { useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import LoadingLogo from '../../assets/lottieAnimations/loadingLogo.json';
import { Footer } from '../../components/Footer';
import { usePreviewMode } from '../../helpers/hooks/useCheckPreviewMode';

export const BaseLayout = ({ children, className, childrenClassName, withShadow }) => {
  const { account } = useWeb3React();
  const { previewUserAddress } = usePreviewMode();
  const [userAddress, setUserAddress] = useState(null);
  const [isCalledUserNick, setIsCalledUserNick] = useState(false);
  const [avatar, setAvatar] = useState(null);
  const [nickname, setNickname] = useState(null);

  useEffect(() => {
    setAvatar(localStorage.getItem('avatar'));
    setNickname(localStorage.getItem('nickname'));
  }, []);

  useEffect(() => {
    if (previewUserAddress) {
      setUserAddress(previewUserAddress);
    } else {
      setUserAddress(account);
    }
  }, [account, previewUserAddress]);

  const dispatch = useDispatch();
  const currentUser = useSelector(getUser);
  const [isNeedToCall, setIsNeedToCall] = useState(false);
  const navigate = useNavigate();

  const setUserInfo = async () => {
    setIsCalledUserNick(false);
    try {
      const result = await UserRepository.getProfileWithoutToken(userAddress);
      if (result) {
        setAvatar(result?.imageUrl);
        setNickname(result?.nickname);
      } else {
        setAvatar(null);
        setNickname(null);
      }
    } catch (e) {
      setAvatar(null);
      setNickname(null);
    } finally {
      setIsCalledUserNick(true);
    }
  };

  useEffect(() => {
    if (userAddress) {
      setUserInfo();
    }
  }, [userAddress]);

  const { data, error, loading, called } = useQuery(GET_USER_DATA, {
    variables: { user: userAddress },
    skip: !isNeedToCall,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    let interval;
    if (userAddress) {
      setUserAddress(userAddress?.toLocaleLowerCase());
      setIsNeedToCall(true);

      interval = setInterval(() => {
        setIsNeedToCall(true);
      }, 20000);
    }
    return () => clearInterval(interval);
  }, [userAddress]);

  const levelsPoints = (levels = [], k = 2) => {
    const activatePoints = levels?.reduce((acc, item) => {
      if (!!item.isActivated) {
        return (acc = acc + 1000);
      }
      return acc;
    }, 0);

    const restartPoints = levels?.reduce((acc, item) => {
      if (!!item.isActivated) {
        return (acc = acc + item?.recyclesTotal * k * 100);
      }
      return acc;
    }, 0);

    return activatePoints + restartPoints;
  };

  const userDataPoints = avatar || nickname ? 3000 : 0;

  useEffect(() => {
    if (data && isCalledUserNick) {
      if (Object.keys(data)?.length && data?.user === null && !loading) {
        navigate('/');
      }
      if (!!data?.user?.id) {
        const {
          id,
          refNumber,
          referral,
          internalId,
          totalPartners,
          partners24,
          partners24Day,
          totalTeam,
          team24,
          team24Day,
          levelsX2,
          levelsX4,
          totalRewardX2,
          totalRewardX4,
          registrationTime,
        } = data?.user;

        const calculateRank =
          data?.user?.totalPartners * 1000 +
          (data?.user?.totalTeam / 10) * 1500 +
          levelsPoints(data?.user?.levelsX2, 2) +
          levelsPoints(data?.user?.levelsX4, 4) +
          userDataPoints;

        dispatch(
          updateUser({
            id,
            rank: calculateRank,
            internalId,
            refNumber,
            referral,
            totalPartners,
            partners24,
            partners24Day,
            totalTeam,
            team24,
            team24Day,
            totalRewardX2,
            totalRewardX4,
            registrationTime,
            levelsX2,
            levelsX4,
            avatar: avatar,
            nickname: nickname,
          }),
        );
        setIsNeedToCall(false);
      } else {
        dispatch(updateUser({ id: '' }));
      }
    }
  }, [data, loading, isCalledUserNick, avatar, nickname]);

  if ((loading && !currentUser?.id) || (data?.user === null && !loading)) {
    return (
      <div className="z-[10] relative flex items-center justify-center w-screen h-screen">
        <Lottie
          className="z-[3] h-[12rem] w-[12rem] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
          animationData={LoadingLogo}
          loop={true}
        />
      </div>
    );
  }

  return (
    <div
      className={`relative flex flex-col bg-[#0C0C0E] items-center justify-center relative min-h-screen lg:bg-[#020204] ${className} `}
    >
      <MobHeader />
      <div className="relative flex w-full z-[1] max-w-[137rem] relative">
        <LeftBar />
        <div className="flex flex-col w-full lg:pt-[6.4rem] lg:pl-0 ">
          <Header />
          <div className="flex flex-col justify-between w-full h-full">
            <div
              className={`flex justify-between flex-col space-y-[2rem] w-full p-[2rem] lg:p-0 ${
                childrenClassName ? childrenClassName : ''
              }`}
            >
              {children}
            </div>
            <div className="p-[2rem] lg:p-0 text-left w-full space-x-[0.4rem] text-[1.4rem] leading-[1.96rem] max-w-[137rem] lg:hidden ">
              <span className="text-white-500">© 2025 Polinetica. All rights reserved.</span>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <img
        className="absolute left-0 max-h-full h-full lg:hidden"
        src="/images/layouts/base/shadows/leftShadow.webp"
        alt=""
      />
    </div>
  );
};
