import React, { useMemo } from 'react';
import GreenArrow from '../../../../assets/icons/dashboard/greenArrow.svg';
import { useSelector } from 'react-redux';
import { getUser } from '../../../../store/userSlice/selectors';
import { getUnixTime } from 'date-fns';

export const Statistics = () => {
  const currentUser = useSelector(getUser);

  const profitPartners24 = Number(currentUser?.partners24Day) === Math.floor(getUnixTime(new Date()) / (60 * 60 * 24)) ? currentUser?.partners24 : 0;
  const profitTeam24 = Number(currentUser?.team24Day) === Math.floor(getUnixTime(new Date()) / (60 * 60 * 24)) ? currentUser?.team24 : 0;

  const statisticsList = useMemo(
    () => [
      {
        title: 'Partners',
        count: currentUser?.totalPartners,
        newCount: profitPartners24,
      },
      {
        title: 'Team',
        count: currentUser?.totalTeam,
        newCount: profitTeam24,
      },
      // {
      //   title: 'Total XP',
      //   count: 0,
      // },
    ],
    [currentUser, profitPartners24, profitTeam24],
  );
  return (
    <div className="flex items-center space-x-[2rem] w-full lg:rounded-[2rem] lg:border-[0.1rem] lg:border-solid lg:border-white-50 lg:bg-white-30 lg:backdrop-blur-[2.5rem] lg:px-[2rem] lg:h-[9.4rem] lg:space-x-0 lg:justify-between">
      {statisticsList.map((item, itemIndex) => {
        return (
          <div
            className="flex items-center justify-between w-full h-[7.2rem] rounded-[2rem] border-[0.1rem] border-solid border-white-50 bg-black-300 p-[2rem] custom-transition hover:bg-black-30 hover:border-white-150 lg:bg-transparent lg:border-none lg:p-0 lg:h-full lg:flex-col lg:items-start lg:space-y-[1.2rem] lg:items-start lg:justify-center lg:w-fit lg:last-of-type:items-end"
            key={itemIndex}
          >
            <span className="text-[1.4rem] text-white-500 font-500 lg:text-[1.2rem]">{item?.title}</span>
            <div className="flex items-center justify-center space-x-[1rem]">
              <span className="text-[2rem] font-600 text-white lg:text-[1.6rem]">{item?.count}</span>
              {!!item?.newCount && (
                <div className="flex items-center justify-center space-x-[0.5rem]">
                  <img src={GreenArrow} />
                  <span className="text-[1.4rem] text-[#51F689] font-400 leading-none">{item?.newCount}</span>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
