import React, { useEffect, useMemo, useState } from 'react';
import LevelIcon from '../../../assets/icons/dashboard/programs/levelCard/levelIcon.svg';
import PolIcon from '../../../assets/icons/main/polCoin.svg';
import FrozenIcon from '../../../assets/icons/dashboard/programs/levelCard/frozenIcon.svg';
import { PartnersPlaceModal } from '../../../components/Modals/PartnersPlaceModal';
import { useModal } from '../../../helpers/hooks/useModal';
import { useSelector } from 'react-redux';
import { getUser } from '../../../store/userSlice/selectors';
import { getMatrixX2X4 } from '../../../store/matrixX2X4Slice/selectors';
import { PROGRAM_NAMES, PROGRAM_PRICES_WEI, PROGRAM_FEE_WEI } from '../../../helpers/constants';
import { PROGRAM_PRICES } from '../../../helpers/constants';
import { useUpgradeLvl } from '../../../helpers/hooks/useUpgradeLvl';
import { Button } from '../../../components/Button';
import { useCallTransaction } from '../../../helpers/hooks/useCallTransaction';
import { usePreviewMode } from '../../../helpers/hooks/useCheckPreviewMode';
import { round } from '../../../helpers/numbers';

export const LevelCard = ({
  lvl,
  itemIndex,
  type,
  matrixs,
  isFirstAfterActive,
  isSecondAfterActive,
  isActivated,
  totalPartners,
  partnersCurrentCycle,
  totalTeam,
  totalReward,
  maxActiveLevel,
  setIsNeedToCall,
  blocked,
  missedPartners,
}) => {
  const { previewUserAddress } = usePreviewMode();
  const { upgradeLvl } = useUpgradeLvl();
  const { onCallTransaction, transactionInfo, resetTransactionInfo } = useCallTransaction();
  const upgradeClick = async (levelsX2, levelsX4) => {
    try {
      const result = await upgradeLvl(levelsX2, levelsX4);

      if (result) {
        onCallTransaction(result);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (transactionInfo.isSuccess) {
      setIsNeedToCall();
      resetTransactionInfo();
    }
  }, [transactionInfo]);

  const freezeLevelsX2 = type === PROGRAM_NAMES.X2 ? [maxActiveLevel + 1] : [];
  const freezeLevelsX4 = type === PROGRAM_NAMES.X4 ? [maxActiveLevel + 1] : [];

  const buyButton = useMemo(() => {
    const levelsX2 = type === PROGRAM_NAMES.X2 ? [maxActiveLevel + 1] : [];
    const levelsX4 = type === PROGRAM_NAMES.X4 ? [maxActiveLevel + 1] : [];

    const totalPrice =
      (Number(PROGRAM_PRICES_WEI[type][maxActiveLevel]) + Number(PROGRAM_FEE_WEI[type][maxActiveLevel])) / 1e18;

    return (
      <Button
        type="purple"
        loading={transactionInfo.isWaiting}
        loadingType="white"
        disabled={transactionInfo.isWaiting || previewUserAddress}
        onClick={() => upgradeClick(levelsX2, levelsX4)}
        className="flex items-center justify-center flex-nowrap min-w-[8.6rem] h-[4rem] bg-[#985AFF] rounded-[3rem] shadow-[0px_0px_40px_0px_rgba(152,90,255,0.30)]"
      >
        <div className="flex items-center justify-center flex-nowrap space-x-[0.4rem] text-[1.4rem] font-500 leading-[1.96rem] text-white">
          <span>Activate for</span>
          <div className="flex items-center justify-center">
            {totalPrice} <img className="w-[1.6rem] h-[1.6rem] ml-[0.4rem]" src={PolIcon} />
          </div>
        </div>
      </Button>
    );
  }, [type, maxActiveLevel, transactionInfo]);

  const { openedModal, onOpen, onClose } = useModal();

  const quattroPlaceces = [
    {
      styles: 'bottom-0 left-[-0.9rem]',
    },
    {
      styles: 'bottom-[4.7rem] left-[1.85rem] rotate-[30deg] lg:bottom-[3.5rem] lg:left-[1.2rem]',
    },
    {
      styles: 'bottom-[7.4rem] left-[6.7rem] rotate-[60deg] lg:bottom-[5.5rem] lg:left-[4.8rem]',
    },
    {
      styles: 'bottom-[7.3rem] right-[7.3rem] rotate-[90deg] lg:bottom-[5.4rem] lg:right-[5.55rem]',
    },
    {
      styles: 'bottom-[4.4rem] right-[2.6rem] rotate-[120deg] lg:bottom-[3.3rem] lg:right-[2.05rem]',
    },
    {
      styles: 'bottom-[-0.4rem] right-[-0.1rem] rotate-[150deg] lg:right-[0.2rem] lg:bottom-[-0.35rem]',
    },
  ];

  const cardTitle = () => {
    return (
      <div
        className={`flex items-center justify-between w-full ${
          missedPartners ? 'sm:flex-col sm:w-full sm:space-x-0 sm:space-y-[1rem] sm:items-end' : ''
        }`}
      >
        <div className={`flex items-center space-x-[0.4rem] ${missedPartners ? 'sm:w-full' : ''}`}>
          <div
            className={`flex items-center justify-start space-x-[1.2rem] w-[11rem] ${
              missedPartners ? 'sm:w-full' : ''
            }`}
          >
            {isActivated && (
              <button
                onClick={onOpen}
                className="flex items-center justify-center w-[2.8rem] h-[2.8rem] rounded-[0.8rem] bg-white-50 border-[0.1rem] border-white-50 border-solid custom-transition hover:bg-white-100"
              >
                <img src={LevelIcon} />
              </button>
            )}

            <span className="text-[1.6rem] text-white font-600 leading-[2.24rem]">Level {lvl}</span>
          </div>
          <div className="flex-shrink-0 flex items-center justify-center bg-white-100 rounded-[2rem] pl-[0.8rem] pr-[0.5rem] h-[2.8rem] space-x-[0.8rem]">
            <span className="text-[1.4rem] font-500 tracking-[-0.28px] text-white opacity-50 leading-none">Price</span>
            <span className="text-white text-[1.4rem] font-500 tracking-[-0.28px] leading-none">
              {PROGRAM_PRICES[type]?.[itemIndex]}
            </span>
            <img className="w-[1.8rem] h-[1.8rem]" src={PolIcon} />
          </div>
        </div>
        {/* {missedPartners > 0 && (
          <div className="flex items-center space-x-[1rem] px-[1.2rem] h-[2.8rem] bg-white-100 rounded-full w-[14rem]">
            <span className="text-[1.2rem] font-500 leading-[1.68rem] text-white-500">Missed Partners</span>
            <div className="flex items-center justify-center rounded-full bg-[#F55456] !w-[2rem] !h-[2rem]">
              <span className="text-[1.2rem] font-500 text-white leading-none">{missedPartners}</span>
            </div>
          </div>
        )} */}

        {!blocked ? (
          missedPartners > 0 ? (
            <div className="flex items-center space-x-[1rem] pl-[1.2rem] h-[2.8rem] bg-white-100 rounded-full pr-[0.4rem]">
              <span className="text-[1.2rem] font-500 leading-[1.68rem] text-white-500">Missed Partners</span>
              <div className="flex items-center justify-center rounded-full bg-[#F55456] !w-[2rem] !h-[2rem]">
                <span className="text-[1.2rem] font-500 text-white leading-[1.68rem]">{missedPartners}</span>
              </div>
            </div>
          ) : (
            <div
              className={`flex items-center space-x-[0.4rem] justify-center h-[2.8rem] px-[1.2rem] rounded-[2rem] ${
                isActivated ? 'bg-[rgba(81,246,137,0.10)] text-[#51F689]' : 'bg-white-100 text-white-500'
              }`}
            >
              <span className="text-[1.2rem] font-500 leading-[1.68rem]">{isActivated ? 'Activated' : 'Inactive'}</span>
            </div>
          )
        ) : (
          <div
            className={`flex items-center space-x-[0.4rem] justify-center h-[2.8rem] pl-[1rem] pr-[0.5rem] rounded-[2rem] bg-[rgba(106,190,255,0.10)] text-[#6ABEFF]`}
          >
            <span className="text-[1.2rem] font-500 leading-[1.68rem]">Frozen</span>
            <img src={FrozenIcon} alt="Frozen" />
          </div>
        )}
      </div>
    );
  };

  const statics = useMemo(() => {
    return (
      <div className={`flex flex-col space-y-[2.4rem] w-full ${isSecondAfterActive && 'opacity-50'}`}>
        <div className="flex space-x-[2.4rem]">
          <div className="flex flex-col space-y-[0.4rem] tracking-[-0.28px]">
            <span className="text-[1.2rem] text-white-500">Personal Partners</span>
            <span className="text-[1.4rem] text-white font-600">{totalPartners}</span>
          </div>
          <div className="flex flex-col space-y-[0.4rem] tracking-[-0.28px]">
            <span className="text-[1.2rem] text-white-500">Team Partners</span>
            <span className="text-[1.4rem] text-white font-600">{totalTeam}</span>
          </div>
        </div>
        <div className="flex w-full items-end justify-between">
          <div className="flex flex-col space-y-[0.5rem]">
            <span className="text-[1.2rem] text-white-500">Profit</span>
            <div className="flex items-center space-x-[0.8rem]">
              <span className="text-[2.4rem] text-white font-600 leading-none">{round(totalReward / 1e18)}</span>
              <img className="w-[2rem] h-[2rem]" src={PolIcon} />
            </div>
          </div>
          {isFirstAfterActive && buyButton}
        </div>
      </div>
    );
  }, [isSecondAfterActive, totalPartners, totalTeam, totalReward, isFirstAfterActive, buyButton]);

  const levelInfo = useMemo(() => {
    return (
      <>
        {isActivated && !blocked && (
          <>
            {type === PROGRAM_NAMES.X2 && (
              <div className="flex space-x-[1rem] absolute right-0 bottom-[-0.4rem]">
                <div className="flex">
                  <img
                    className="h-[11.52rem] lg:h-[8rem]"
                    src="/images/dashboard/programs/levelCard/progress/duo/empty.webp"
                  />
                  {partnersCurrentCycle === 1 && (
                    <img
                      className="h-[11.52rem] absolute right-0 z-[111] -rotate-90 left-0 lg:h-[8rem]"
                      src="/images/dashboard/programs/levelCard/progress/duo/fill.webp"
                    />
                  )}
                </div>
                <div className="flex rotate-90">
                  <img
                    className="h-[11.52rem] lg:h-[8rem]"
                    src="/images/dashboard/programs/levelCard/progress/duo/empty.webp"
                  />
                  {/* {partnersCurrentCycle === 2 && (
                <img
                  className="h-[11.52rem] absolute right-0 z-[111] -rotate-90 left-0 lg:h-[8rem]"
                  src="/images/dashboard/programs/levelCard/progress/duo/fill.webp"
                />
              )} */}
                </div>
              </div>
            )}

            {type === PROGRAM_NAMES.X4 && (
              <div className="flex space-x-[1rem] absolute right-0 bottom-[-0.4rem]">
                <img className="lg:w-[18rem]" src="/images/dashboard/programs/levelCard/progress/quattro/empty.webp" />
                {quattroPlaceces.map((item, itemIndex) => {
                  if (itemIndex < partnersCurrentCycle) {
                    return (
                      <img
                        className={`absolute ${item?.styles} lg:w-[2.55rem]`}
                        src="/images/dashboard/programs/levelCard/progress/quattro/fill.webp"
                        key={itemIndex}
                      />
                    );
                  }
                })}
              </div>
            )}
          </>
        )}
        {blocked && (
          <button
            onClick={() => upgradeClick(freezeLevelsX2, freezeLevelsX4)}
            className="absolute right-[2rem] bottom-[2rem] flex items-center justify-center bg-[rgba(106,190,255,0.10)] h-[4rem] rounded-[2rem] px-[1.6rem] "
          >
            <span className="text-[1.4rem] font-500 text-[#6ABEFF]">Activate level {itemIndex + 2} to unlock</span>
          </button>
        )}
      </>
    );
  }, [isActivated, partnersCurrentCycle, blocked, freezeLevelsX2, freezeLevelsX4]);
  <div className="w-[490px] h-[216px] rounded-[2rem] border-[0.3rem] border-solid border-white-50 overflow-hidden">
    <div className="flex h-full w-full bg-white-40"></div>
  </div>;
  return (
    <div
      className={`relative flex w-full w-full rounded-[2rem] overflow-hidden ${
        isActivated && 'border-[0.3rem] border-white-50 border-solid'
      }`}
    >
      <div
        className={`flex flex-col justify-between space-y-[4.8rem] w-full p-[2rem] ${
          isActivated ? 'bg-white-40' : 'bg-white-20'
        }`}
      >
        {cardTitle()}
        {(isActivated || isFirstAfterActive) && (
          <>
            <div className="flex w-full">{statics}</div>
            {levelInfo}
            {isActivated && !blocked && (
              <img
                className="absolute bottom-0 lg:right-0"
                src={`/images/dashboard/programs/levelCard/${
                  type === PROGRAM_NAMES.X2 ? 'greenShadow' : 'orangeShadow'
                }.webp`}
              />
            )}
            {blocked && (
              <img
                className="absolute bottom-0 lg:right-0"
                src="/images/dashboard/programs/levelCard/blueShadow.webp"
              />
            )}
          </>
        )}
        {isSecondAfterActive && statics}
        {openedModal && (
          <PartnersPlaceModal
            level={lvl}
            type={type}
            matrixs={matrixs}
            openedModal={openedModal}
            handleCloseModal={onClose}
          />
        )}
      </div>
    </div>
  );
};
