import React from 'react';
import { Modal } from '../../Modal';
import config from '../../../helpers/config';
import { useWeb3React } from '@web3-react/core';
import { callNotification } from '../../../helpers/notification';
import { parseErrorToUserReadableMessage } from '../../../helpers/format';
import { ADD_CHAIN_ETHS_PARAMS } from '../../../helpers/constants';

export const InvalidNetworkModal = ({ openedModal, handleCloseModal }) => {
  const { provider, account, chainId } = useWeb3React();

  const onSwitchChain = async () => {
    if (Number(chainId) !== Number(config.allowedChainId) && !!account) {
      const chainIntoHex = '0x' + config.allowedChainId.toString(16);
      try {
        await provider?.send('wallet_switchEthereumChain', [{ chainId: chainIntoHex }, account]);
      } catch (e) {
        callNotification({ type: 'error', message: `${e.code} ` + parseErrorToUserReadableMessage(e) });
        if (e.code === 4902 || e.code === -32603) {
          await provider?.send('wallet_addEthereumChain', [
            {
              chainId: chainIntoHex,
              ...ADD_CHAIN_ETHS_PARAMS[config.allowedChainId],
            },
          ]);
        } else {
          callNotification({ type: 'error', message: `${e.code} ` + parseErrorToUserReadableMessage(e) });
        }
      }
    }
  };

  return (
    <Modal withoutClose isDisableOnClose isOpened={openedModal} onClose={handleCloseModal}>
      <div className="flex flex-col items-center space-y-[4rem] bg-white-50 w-[46rem] p-[4rem] pt-[8rem] rounded-[3rem] border-[0.1rem] border-[0.1rem] border-solid border-white-50 backdrop-blur-[5rem] bg-blend-difference lg:w-full lg:p-[2rem] lg:pt-[4rem] lg:pb-[8rem]">
        <img className="w-[8rem]" src="/images/modals/InvalidNetworkModal/error.webp" />
        <div className="flex flex-col items-center text-center space-y-[1.2rem]">
          <span className="text-white text-[3rem] font-600 sapce-grotesk">invalid Network</span>
          <span className="text-[1.4rem] text-white-500 font-400 leading-[1.96rem]">Oops, something went wrong.</span>
        </div>
        <button
          onClick={() => onSwitchChain()}
          className="flex items-center justify-center w-full h-[4.8rem] rounded-[1.2rem] border-[0.1rem] border-solid border-white-200 bg-[#985AFF] shadow-[0px_10px_50px_5px_rgba(152,90,255,0.35)]"
        >
          <span className="text-[1.6rem] font-500 text-white">Change</span>
        </button>
      </div>
    </Modal>
  );
};
