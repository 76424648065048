import React, { useEffect, useState } from 'react';
import { Modal } from '../../Modal';
import { Input } from '../../Input';
import { Button } from '../../../components/Button';
import { usePreviewMode } from '../../../helpers/hooks/useCheckPreviewMode';
import { CHECK_UPLINE_INTERNAL_ID } from '../../../helpers/graphRequests';
import { useQuery } from '@apollo/client';

export const PreviewModModal = ({ userId, openedModal, handleCloseModal }) => {
  const [isAllowToViewId, setIsAllowToViewId] = useState(false);
  const [previewId, setPreviewId] = useState();
  const [previewAddress, setPreviewAddress] = useState();
  const { setPreviewMode } = usePreviewMode();

  const { data: dataId, loading: loadingId } = useQuery(CHECK_UPLINE_INTERNAL_ID, {
    variables: { internalId: Number(previewId) },
    skip: !previewId,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (userId) {
      setPreviewId(userId);
    }
  }, [userId]);

  useEffect(() => {
    if (dataId?.users[0]?.id && !loadingId) {
      setPreviewAddress(dataId?.users[0]?.id);
      if (Number(previewId) !== 1) {
        setIsAllowToViewId(true);
      }
    } else {
      setIsAllowToViewId(false);
    }
  }, [dataId, loadingId]);

  return (
    <Modal isOpened={openedModal} onClose={handleCloseModal}>
      <div className="h-[55.5rem] !relative flex flex-col items-center justify-start space-y-[4.8rem] p-[4rem] rounded-[3rem] border-[0.1rem] border-solid border-white-50 bg-white-50 bg-blend-difference shadow-[0px_20px_50px_0px_rgba(0,0,0,0.40)] backdrop-blur-[5rem] lg:w-full lg:items-center lg:px-[2rem] lg:rounded-none lg:rounded-t-[2rem] lg:space-y-[2.4rem] lg:h-[51.6rem] lg:border-none">
        <div className="flex flex-col items-center space-y-[1.6rem] text-center z-[2]">
          <span className="text-[3.2rem] font-600 leading-[4.48rem] text-white-500 tracking-[-0.64px] lg:text-[2.4rem] lg:leading-[3.36rem] lg:tracking-[-0.48px]">
            Account <span className="text-white">preview</span>
          </span>
          <span className="text-[1.4rem] text-white-500 font-400 leading-[1.96rem] max-w-[44rem] lg:max-w-full">
            You can look up any registered account in the system. Enter ID address to preview or click Demo to view
            random account.
          </span>
        </div>
        <div className="flex items-center space-x-[1rem] w-full">
          <Input
            type="number"
            value={previewId}
            onChange={(e) => setPreviewId(e.target.value)}
            placeholder="Enter ID"
            className="placeholder:!text-white-500"
          />
          <Button
            disabled={loadingId || !isAllowToViewId}
            loading={loadingId}
            loadingType="white"
            onClick={() => {
              setPreviewMode(previewAddress, '/dashboard');
              handleCloseModal();
            }}
            className="h-[4.8rem] min-w-[9rem]"
            type="purple"
          >
            <span className="text-[1.6rem] text-white ">Preview</span>
          </Button>
        </div>
        <img
          className="lg:hidden absolute bottom-0 pointer-events-none z-[0]"
          src="/images/landing/loginBanner/previewModal/bg.webp"
        />
        <img
          className="hidden lg:flex absolute bottom-0 pointer-events-none z-[0]"
          src="/images/landing/loginBanner/previewModal/bgMob.webp"
        />
      </div>
    </Modal>
  );
};
