import React, { useEffect, useState } from 'react';
import { Item } from './item';
import { useWeb3React } from '@web3-react/core';
import { useEnergyNft } from '../../../helpers/hooks/useEnergyNft';
import { Button } from '../../../components/Button';
import { useCallTransaction } from '../../../helpers/hooks/useCallTransaction';

const stakedTabs = ['Active Stake', 'Next Stake'];

export const StakesList = () => {
  const [currentTab, setCurrentTub] = useState(stakedTabs[0]);

  const { account } = useWeb3React();
  const { getNftOnWeek, weekNfts, getUnclaimedReward, rewardForClaim, claimReward } = useEnergyNft();
  const { onCallTransaction, transactionInfo, resetTransactionInfo } = useCallTransaction();
  const nftList = currentTab === stakedTabs[0] ? weekNfts?.current : weekNfts?.next;

  useEffect(() => {
    if (account) {
      getNftOnWeek();
      getUnclaimedReward();
    }
  }, [account]);

  useEffect(() => {
    if (transactionInfo.isSuccess) {
      resetTransactionInfo();
      getUnclaimedReward();
    }
  }, [transactionInfo]);

  const clickReward = async () => {
    try {
      const res = await claimReward();

      if (res) {
        onCallTransaction(res);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const isHaveStakedNft = (nftList && nftList?.some((item) => item > 0)) || false;

  return (
    <div className="flex flex-col space-y-[4rem] lg:space-y-[2rem]">
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center justify-start space-x-[1rem]">
          {stakedTabs.map((item, itemIndex) => {
            const isActive = currentTab === item;
            return (
              <button
                onClick={() => setCurrentTub(item)}
                className={`custom-transition px-[1.2rem] py-[0.8rem] flex items-center justify-center rounded-[1.2rem] border border-solid border-transparent bg-white-40 lg:h-[4rem] ${
                  isActive ? '!bg-white-100 !border-white-200' : ''
                }`}
                key={itemIndex}
              >
                <span className={`text-white text-[1.4rem] font-400 leading-[2.4rem] ${isActive ? '' : 'opacity-50'}`}>
                  {item}
                </span>
              </button>
            );
          })}
        </div>
        <Button
          disabled={!rewardForClaim}
          loading={transactionInfo.isWaiting}
          loadingType="white"
          onClick={() => clickReward()}
          type="purple"
          className="h-[4rem] w-full max-w-[30rem] lg:max-w-fit lg:h-[3.8rem] lg:rounded-[1.2rem]"
        >
          <span className="text-[1.6rem] font-500 text-white lg:text-[1.4rem]">
            Claim {rewardForClaim?.toFixed(3) || 0}
          </span>
        </Button>
      </div>

      {isHaveStakedNft ? (
        <div className="grid grid-cols-4 gap-[2rem] lg:gap-[2rem] w-full lg:grid-cols-1">
          {nftList.map((item, itemIndex) => {
            if (item) {
              return <Item count={item} lvl={itemIndex + 1} />;
            }
            return null;
          })}
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center text-white h-full z-[11]">
          <img className="!z-[0]" src="/images/energy/states/withoutStake.png" />
          <span className="text-[1.4rem] font-400 text-white-500 leading-[1.96rem]">You dont have Active Stake</span>
        </div>
      )}
    </div>
  );
};
