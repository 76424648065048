import React, { useEffect, useMemo } from 'react';
import { Button } from '../../../../components/Button';
import { useWeb3React } from '@web3-react/core';
import { Link } from 'react-router-dom';
import { useGetUserInfo } from '../../../../helpers/hooks/useGetUserInfo';
import { useModal } from '../../../../helpers/hooks/useModal';
import { ActivateModal } from '../../../../components/Modals/ActivateModal';
import PreviewModicon from '../../../../assets/icons/landing/loginBanner/previewModicon.svg';
import { PreviewModModal } from '../../../../components/Modals/PreviewModModal';

export const Banner = () => {
  const { account } = useWeb3React();
  const { userInfo, getUserInfo } = useGetUserInfo();

  useEffect(() => {
    if (account) {
      getUserInfo(account);
    }
  }, [account]);

  const { openedModal, onOpen, onClose } = useModal();

  const {
    openedModal: openedPreviewModModal,
    onOpen: onOpenPreviewModModal,
    onClose: onClosePreviewModModal,
  } = useModal();

  const renderContent = useMemo(() => {
    if (!account) {
      return (
        <Button onClick={onOpen} type="purple" className="h-[4.8rem]">
          <span className="text-[1.6rem] text-white font-500">Connect Wallet</span>
        </Button>
      );
    } else {
      if (userInfo?.user?.id) {
        return (
          <Link to="/dashboard">
            <Button onClick={onOpen} type="purple" className="h-[4.8rem]">
              <span className="text-[1.6rem] text-white font-500">Login to your account</span>
            </Button>
          </Link>
        );
      }
      return (
        <Link to="/register">
          <Button type="purple" className="h-[4.8rem]">
            <span className="text-[1.6rem] text-white font-500">Join Polynetica</span>
          </Button>
        </Link>
      );
    }
  }, [account, userInfo]);

  return (
    <div className="flex items-center justify-between bg-white-50 rounded-[3rem] h-[60rem] w-full overflow-hidden lg:h-[62rem] lg:rounded-none ">
      <div className="flex items-center relative w-full h-full bg-[#0C0C0E] pl-[8rem] loginBannerBg overflow-hidden lg:pl-0 lg:items-start lg:pt-[4rem]">
        <div className="flex flex-col items-start space-y-[8rem] lg:space-y-[4rem] lg:text-center lg:items-center lg:w-full">
          <div className="flex flex-col space-y-[4rem] lg:items-center lg:w-full">
            <span className="text-[8rem] font-600 leading-[8.8rem] max-w-[49.2rem] loginBannerText lg:text-[4rem] lg:leading-[4.4rem] lg:max-w-[24.6rem]">
              Polynetica Social Mining
            </span>
            <span className="text-[2rem] font-500 text-white-500 lg:text-[1.6rem] ">
              Duo & Quattro | <span className="text-white">Unlimited Earnings in Web3</span>
            </span>
          </div>
          <div className="flex items-center space-x-[1.2rem]">
            {renderContent}
            <Button onClick={onOpenPreviewModModal} className="space-x-[1rem] h-[4.8rem]" type="gray">
              <span className="text-[1.4rem] text-white font-500 tracking-[-0.42px]">Preview mode</span>
              <img src={PreviewModicon} />
            </Button>
          </div>
        </div>
        <img
          className="absolute top-[-24rem] right-[-42rem] lg:right-auto lg:left-1/2 lg:-translate-x-1/2 lg:top-auto lg:bottom-[5rem] lg:scale-[1.4] lg:max-w-[40rem]"
          src="/images/landing/loginBanner/polyPic.webp"
        />
      </div>
      <PreviewModModal openedModal={openedPreviewModModal} handleCloseModal={onClosePreviewModModal} />
      <ActivateModal openedModal={openedModal} handleCloseModal={onClose} />
    </div>
  );
};
