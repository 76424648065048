import React from 'react';
import { BaseLayout } from 'layouts';
import { Charts } from '../../features/stats/Charts';
import { Partners } from '../../features/stats/Partners';
import { MobTitle } from '../../components/MobTitle';
import statisticsActiveIcon from '../../assets/icons/menu/active/statisticsIcon.svg';

export const Stats = () => {
  return (
    <BaseLayout className="lg:!bg-[#0C0C0E]">
      <div className="flex flex-col w-full  relative">
        <MobTitle icon={statisticsActiveIcon} title="Statistics" className="pt-[2rem] pl-[1.6rem]" />
        <div className="flex flex-col space-y-[2rem] lg:space-y-[2.4rem] lg:space-y-[2.4rem] lg:pt-[2rem] lg:px-[1.6rem]">
          {/* <Charts /> */}
          <Partners />
        </div>
        <img className="hidden lg:flex !absolute left-0 h-full  top-0 w-full" src="/images/stats/mobShadow.webp" />
      </div>
    </BaseLayout>
  );
};
