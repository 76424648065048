import React, { useEffect, useState } from 'react';
import { Button } from '../../../../components/Button';

export const NftsList = ({ setChoosedNftId, choosedNftId, balanceNft = [], rewardForClaim }) => {
  const [totalNft, setTotalNft] = useState(0);

  useEffect(() => {
    if (balanceNft.length) {
      let total = balanceNft?.reduce((item, acc) => {
        return acc + item;
      }, 0);

      setTotalNft(total);
    }
  }, [balanceNft]);

  return (
    <div className="flex flex-col space-y-[2rem] h-full">
      <div className="flex items-center space-x-[0.8rem] text-[1.6rem] leading-[2.4rem]">
        <span className="font-500 text-white-500">Your NFTs</span>
        <span className="font-600 text-white">{totalNft}</span>
      </div>
      {totalNft > 0 ? (
        <div className="flex relative w-full h-full overflow-auto">
          <div className="grid grid-cols-3 grid-rows-[22.5rem] gap-[2rem] w-full max-h-[58.7rem] overflow-auto hide-scroll lg:gap-[1.2rem] lg:grid-cols-2 lg:max-h-[69rem]">
            {!!balanceNft &&
              balanceNft?.map((item, itemIndex) => {
                const currentButton = choosedNftId === itemIndex + 1;
                if (item) {
                  return (
                    <button
                      onClick={() => setChoosedNftId(itemIndex + 1)}
                      className={`max-h-[22.5rem] relative flex flex-col space-y-[1.6rem] rounded-[2rem] border-[0.1rem] border-solid border-white-50 p-[1rem] pb-[2rem] w-full custom-transition hover:bg-white-100 hover:border-white-150 lg:items-center ${
                        currentButton ? 'bg-white-100' : 'bg-white-30'
                      }`}
                      key={itemIndex}
                    >
                      <img
                        src={itemIndex < 15 ? '/energynft/img/duo.png' : '/energynft/img/quattro.png'}
                        className="rounded-[1rem] w-[16rem] h-[16rem] lg:w-[15.3rem] lg:h-[15.3rem]"
                      />
                      <span className="text-[1.6rem] text-white-500 font-500 px-[1rem] lg:text-left lg:w-full">
                        lvl {itemIndex + 1}
                      </span>
                      {currentButton && (
                        <div className="absolute right-[2rem] top-[0.4rem] flex items-center justify-center w-[2rem] h-[2rem] bg-[rgba(175,127,255,0.20)] rounded-full">
                          <div className="w-[0.8rem] h-[0.8rem] bg-[#AF7FFF] rounded-full"></div>
                        </div>
                      )}
                      <div className="flex items-center justify-center !mt-0 absolute top-[1.75rem] left-[1.75rem] rounded-full bg-[rgba(81,246,137,0.10)] text-[#51F689] h-[2.8rem] w-[2.8rem]">
                        <span className="text-[1.2rem] font-500">{item}</span>
                      </div>
                    </button>
                  );
                }
                return null;
              })}
            <div className="h-[12.4rem] absolute w-full bottom-0 bg-[linear-gradient(0deg,#0C0C0E_0%,rgba(12,12,14,0.00)_100%)] pointer-events-none lg:hidden"></div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center text-white h-full z-[11]">
          <img className="!z-[0]" src="/images/energy/states/withoutStake.png" />
          <div className="flex flex-col space-y-[2.4rem]">
            <span className="text-[1.4rem] font-400 text-white-500 leading-[1.96rem]">
              You have <span className="text-white font-600">{rewardForClaim?.toFixed(3) || 0} NFTs</span> for stake
            </span>
            {/* <Button type="purple" className="h-[4rem] w-[18rem]">
              <span className="text-[1.4rem] text-white font-500">Claim</span>
            </Button> */}
          </div>
        </div>
      )}
    </div>
  );
};
