import { useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { useDispatch } from 'react-redux';
import { updateBalance } from '../../store/walletSlice';
import { parseErrorToUserReadableMessage } from '../format';
import { callNotification } from '../notification';
import { CONTRACT_NAMES } from '../constants';
import { useGetContract } from './useGetContract';

export const useBalance = (props) => {
  const { withoutFixed = false } = props ?? {};
  const [balancePol, setBalancePol] = useState(0);
  const [balancePolly, setBalancePolly] = useState(0);
  const [isFirstLoaded, setIsFirstLoaded] = useState(true);
  const [isFirstLoadedPolly, setIsFirstLoadedPolly] = useState(true);
  const [isFirstLoadedStPolly, setIsFirstLoadedStPolly] = useState(true);

  const { getContract } = useGetContract();
  const dispatch = useDispatch();

  const { account, provider } = useWeb3React();

  const fetchPOL = async () => {
    try {
      const resultPol = await provider.getBalance(account);
      const balancePol = withoutFixed
        ? parseInt(resultPol) / 1e18 ?? 0
        : parseFloat(parseInt(resultPol) / 1e18 ?? 0).toFixed(3);

      setBalancePol(balancePol);
      setIsFirstLoaded(false);
      return balancePol;
    } catch (e) {
      // console.log(e);
      // callNotification({ type: 'error', message: `balance pl fetch - ${parseErrorToUserReadableMessage(e)}` });
    }
  };

  const fetchPOLLY = async () => {
    try {
      const contract = await getContract(CONTRACT_NAMES.POLLY_TOKEN);
      const resultBalancePolly = await contract?.balanceOf(account);
      const balancePolly = (parseInt(resultBalancePolly) / 1e18).toFixed(2);

      setBalancePolly(balancePolly);
      setIsFirstLoadedPolly(false);
      return balancePolly;
    } catch (e) {
      // console.log(e);
      // callNotification({ type: 'error', message: `balance py fetch - ${parseErrorToUserReadableMessage(e)}` });
    }
  };

  const fetchStPOLLY = async () => {
    try {
      const contract = await getContract(CONTRACT_NAMES.ST_POLLY_TOKEN);
      const resultBalanceStPolly = await contract?.balanceOf(account);
      const balanceStPolly = (parseInt(resultBalanceStPolly) / 1e18).toFixed(2);

      setBalancePolly(balanceStPolly);
      setIsFirstLoadedStPolly(false);
      return balanceStPolly;
    } catch (e) {
      // console.log(e);
      // callNotification({ type: 'error', message: `balance py fetch - ${parseErrorToUserReadableMessage(e)}` });
    }
  };

  const fetchBalance = async () => {
    const resultPol = await fetchPOL();
    const resultPolly = await fetchPOLLY();
    const resultStPolly = await fetchStPOLLY();

    dispatch(
      updateBalance({ pol: parseFloat(resultPol), polly: parseFloat(resultPolly), stPolly: parseFloat(resultStPolly) }),
    );
  };

  return {
    balancePol: balancePol,
    balancePolly: balancePolly,
    isFirstLoaded,
    fetchBalance,
    setIsFirstLoaded,
  };
};
