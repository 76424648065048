import React from 'react';
import CheckIcon from '../../assets/icons/generalIcons/whiteCheckIcon.svg';

export const Advantages = ({ text }) => {
  return (
    <div className="flex items-center space-x-[1.6rem] lg:space-x-[1.2rem]">
      <img className="lg:w-[1.6rem] lg:h-[1.6rem]" src={CheckIcon} alt="Check Icon" />
      <span className="text-[1.4rem] text-white font-500 lg:text-[1rem]">{text}</span>
    </div>
  );
};
