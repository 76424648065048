import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { setCookie } from 'nookies';
import Lottie from 'lottie-react';
import LoadingLogo from '../../assets/lottieAnimations/loadingLogo.json';

export const RefferalCodeTransfer = () => {
  let { refKey } = useParams();

  useEffect(() => {
    if (refKey) {
      setCookie(null, 'polynetica_upline_key', refKey, {
        maxAge: 30 * 24 * 60 * 60,
        path: '/',
      });
      window.location.href = `/register?refKey=${refKey}`;
    } else {
      window.location.href = '/register';
    }
  }, [refKey]);

  return (
    <div className="z-[10] relative flex items-center justify-center w-screen h-screen">
      <Lottie
        className="z-[3] h-[12rem] w-[12rem] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
        animationData={LoadingLogo}
        loop={true}
      />
    </div>
  );
};
