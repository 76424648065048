import React, { useEffect } from 'react';
import PolIcon from '../../../../assets/icons/main/polCoin.svg';
import { useWeb3React } from '@web3-react/core';
import { useEnergyNft } from '../../../../helpers/hooks/useEnergyNft';

export const EnergyProfit = () => {
  const { account } = useWeb3React();
  const { getTotalClaimed, totalClaimed } = useEnergyNft();

  useEffect(() => {
    if (account) {
      getTotalClaimed();
    }
  }, [account]);

  return (
    <div className="flex items-center justify-between w-full h-[7.2rem] bg-white-30 border-[0.1rem] border-solid border-white-50 rounded-[2rem] px-[2rem] lg:hidden">
      <span className="text-[1.4rem] text-white-500 font-500">Energy Profit</span>
      <div className="flex items-center space-x-[0.8rem]">
        <span className="text-[2.4rem] text-white font-600 leading-none">{totalClaimed?.toFixed(4) || 0}</span>
        <img className="w-[2rem] h-[2rem]" src={PolIcon} />
      </div>
    </div>
  );
};
