import React, { useEffect, useState } from 'react';
import whiteCopyIcon from '../../../assets/icons/generalIcons/whiteCopyIcon2.svg';
import linkIcon from '../../../assets/icons/generalIcons/linkIcon.svg';
import PolIcon from '../../../assets/icons/main/polCoin.svg';
import { shortenAddress } from '../../../helpers/format';
import { copy } from '../../../helpers/copy';
import { GET_USER_PARTNERS } from '../../../helpers/graphRequests';

import { useQuery } from '@apollo/client';
import { useWeb3React } from '@web3-react/core';
import { explorerLinks } from '../../../helpers/constants';

import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { usePreviewMode } from '../../../helpers/hooks/useCheckPreviewMode';
import { UserIdBlock } from '../../../components/UserIdBlock';
import { round } from '../../../helpers/numbers';

export const Partners = () => {
  const { account } = useWeb3React();
  const { previewUserAddress } = usePreviewMode();
  const [userAddress, setUserAddress] = useState(null);

  useEffect(() => {
    if (previewUserAddress) {
      setUserAddress(previewUserAddress?.toLocaleLowerCase());
    } else {
      setUserAddress(account?.toLocaleLowerCase());
    }
  }, [account, previewUserAddress]);
  const [isNeedToCall, setIsNeedToCall] = useState(false);

  const { data, error, loading, called } = useQuery(GET_USER_PARTNERS, {
    variables: { user: userAddress },
    skip: !isNeedToCall,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (userAddress) {
      setIsNeedToCall(true);
    }
  }, [userAddress]);

  const refDate = (time) => {
    const unixTime = time * 1000;

    const formattedDate = format(new Date(unixTime), 'dd.MM.yyyy HH:mm');

    return formattedDate;
  };

  const totalActive = (matrix) => {
    return matrix.filter((item) => item.isActivated).length || 0;
  };

  const totalReward = (rewardX2, rewardX4) => {
    return round((Number(rewardX2) + Number(rewardX4)) / 1e18);
  };

  return (
    <div className="flex flex-col space-y-[2.4rem] p-[2rem] bg-white-40 border-[0.1rem] border-solid border-white-50 rounded-[3rem] lg:bg-white-30 lg:rounded-[2rem] z-[11]">
      <div className="flex items-center justify-between w-full lg:flex-col lg:space-y-[2rem] lg:items-start">
        <span className="text-[2.4rem] text-white font-500 tracking-[-0.48px] lg:text-[3rem] lg:tracking-[-0.6px]">
          Partners
        </span>
      </div>
      <div className="min-h-[50rem] lg:min-h-[35rem] flex flex-col rounded-[2rem] !text-white border-[0.1rem] border-solid border-white-50 bg-black-300 h-full w-full pt-[1.2rem] lg:pt-0 lg:bg-transparent lg:border-none">
        <table className="w-full !text-white">
          <thead>
            <tr className="border-b-[0.1rem] border-white-100">
              <th className="p-[2rem] text-left text-[1.4rem] text-white-500 font-400 leading-[1.96rem] lg:hidden">
                Date
              </th>
              <th className="p-[2rem] text-left text-[1.4rem] text-white-500 font-400 leading-[1.96rem] lg:hidden">
                Wallet
              </th>
              <th className="p-[2rem] text-left text-[1.4rem] text-white-500 font-400 leading-[1.96rem] lg:p-0 lg:pb-[2rem] lg:text-[1.2rem] lg:leading-[1.68rem]">
                ID
              </th>
              <th className="p-[2rem] text-left text-[1.4rem] text-white-500 font-400 leading-[1.96rem] lg:p-0 lg:pb-[2rem] lg:text-[1.2rem] lg:leading-[1.68rem] lg:w-[13%]">
                Duo
              </th>
              <th className="p-[2rem] text-left text-[1.4rem] text-white-500 font-400 leading-[1.96rem] lg:p-0 lg:pb-[2rem] lg:text-[1.2rem] lg:leading-[1.68rem] lg:w-[10%]">
                Quattro
              </th>
              <th className="p-[2rem] text-left text-[1.4rem] text-white-500 font-400 leading-[1.96rem] lg:p-0 lg:pb-[2rem] lg:text-[1.2rem] lg:leading-[1.68rem]">
                Partners
              </th>
              <th className="p-[2rem] text-right text-[1.4rem] text-white-500 font-400 leading-[1.96rem] lg:p-0 lg:pb-[2rem] lg:text-[1.2rem] lg:leading-[1.68rem]">
                Profit
              </th>
            </tr>
          </thead>
          <tbody>
            {!!data?.users &&
              data?.users?.map((item, itemIndex) => (
                <tr className="custom-transition hover:bg-white-50" key={itemIndex}>
                  <td
                    className={`p-[2rem] text-left text-white-500 text-[1.4rem] font-400 leading-[1.96rem] lg:hidden ${
                      itemIndex === 0 && 'pt-[4rem]'
                    }`}
                  >
                    {refDate(item?.registrationTime)}
                  </td>
                  <td className={`p-[2rem] text-left lg:hidden ${itemIndex === 0 && 'pt-[4rem]'}`}>
                    <div className="flex items-center space-x-[1rem]">
                      <span className="text-[1.4rem] text-white font-400 leading-[1.96rem]">
                        {shortenAddress(item?.id)}
                      </span>
                      <button
                        className="flex-shrink-0 custom-transition hover:opacity-70"
                        onClick={() => copy(item?.id)}
                      >
                        <img src={whiteCopyIcon} />
                      </button>
                      <Link
                        to={explorerLinks.txUrl + item?.registrationTransaction}
                        target="_blank"
                        className="flex-shrink-0 cursor-pointer custom-transition hover:opacity-70"
                      >
                        <img src={linkIcon} />
                      </Link>
                    </div>
                  </td>
                  <td className={`p-[2rem] text-left lg:p-0 lg:py-[1rem] ${itemIndex === 0 && 'pt-[4rem]'}`}>
                    <UserIdBlock id={item?.internalId} />
                  </td>
                  <td
                    className={`p-[2rem] text-left text-white text-[1.4rem] font-400 leading-[1.96rem] lg:p-0 lg:py-[1rem] lg:text-[1.2rem] lg:leading-[1.68rem] ${
                      itemIndex === 0 && 'pt-[4rem]'
                    }`}
                  >
                    {totalActive(item?.levelsX2)}
                  </td>
                  <td
                    className={`p-[2rem] text-left text-white text-[1.4rem] font-400 leading-[1.96rem] lg:p-0 lg:py-[1rem] lg:text-[1.2rem] lg:leading-[1.68rem] ${
                      itemIndex === 0 && 'pt-[4rem]'
                    }`}
                  >
                    {totalActive(item?.levelsX4)}
                  </td>
                  <td
                    className={`p-[2rem] text-left text-white text-[1.4rem] font-400 leading-[1.96rem] lg:p-0 lg:py-[1rem] lg:text-[1.2rem] lg:leading-[1.68rem] ${
                      itemIndex === 0 && 'pt-[4rem]'
                    }`}
                  >
                    {item?.totalPartners}
                  </td>
                  <td className={`p-[2rem] text-right lg:p-0 lg:py-[1rem] ${itemIndex === 0 && 'pt-[4rem]'} `}>
                    <div className="flex justify-end items-center space-x-[1rem]">
                      <span className="text-[1.4rem] text-white font-600 leading-[1.96rem] lg:text-[1.2rem]">
                        {totalReward(item?.totalRewardX2, item?.totalRewardX4)}
                      </span>
                      <img className="w-[2rem] h-[2rem]" src={PolIcon} />
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
