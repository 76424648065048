import React from 'react';
import { useSelector } from 'react-redux';
import { getUser } from '../../../../store/userSlice/selectors';
import { shortenAddress } from '../../../../helpers/format';
import { Link } from 'react-router-dom';
import PurpleStar from '../../../../assets/icons/dashboard/purpleStar.svg';
import { RankModal } from '../../../../components/Modals/RankModal';
import { useModal } from '../../../../helpers/hooks/useModal';
import { copy } from '../../../../helpers/copy';
import useCalculateRank from '../../../../helpers/hooks/useCalculateRank';
import { usePreviewMode } from '../../../../helpers/hooks/useCheckPreviewMode';
import { PreviewModModal } from '../../../../components/Modals/PreviewModModal';

export const UserInfo = () => {
  const currentUser = useSelector(getUser);
  const { previewUserAddress } = usePreviewMode();
  const { currentRank, totalXP: currentXP, nextRankXP, progressPercentage } = useCalculateRank(currentUser?.rank);

  const { openedModal, onOpen, onClose } = useModal();
  const { openedModal: openedPreviewModal, onOpen: onOpenPreview, onClose: onClosePreview } = useModal();

  const idBlock = () => {
    return (
      <div
        className={`flex items-center justify-center bg-[rgba(175,127,255,0.10)] rounded-[2rem] px-[1.2rem] h-[2.8rem] lg:h-[2.6rem]`}
      >
        <span className="text-[2rem] text-[#AF7FFF] font-500 leading-[3.6rem] lg:text-[1.8rem]">
          id{currentUser?.internalId}
        </span>
      </div>
    );
  };

  const xpCounter = ({ isMobHidden = false }) => {
    return (
      <div
        className={`flex space-x-[0.6rem] text-white-500 font-500 ${
          isMobHidden
            ? 'flex lg:hidden absolute left-[2rem] top-[2.3rem] text-[1.2rem]'
            : 'hidden lg:flex text-[1.4rem]'
        }`}
      >
        <span>XP</span>
        <span className="flex items-center justify-center space-x-[0.2rem]">
          <span className="text-white">{currentXP}</span>
          <span>/</span>
          <span className="">{nextRankXP}</span>
        </span>
      </div>
    );
  };

  const firstLetterNick = currentUser?.nickname
    ? currentUser?.nickname
        .split(' ')
        .map((word) => word[0])
        .join('')
    : '';

  const styleBg =
    currentUser?.avatar !== 'null'
      ? {
          backgroundImage: `url(${currentUser?.avatar})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }
      : {
          backgroundColor: '#8247E5',
        };

  return (
    <div className="flex flex-col items-center justify-end h-[45.5rem] w-full max-w-[36.7rem] relative lg:w-full lg:h-min lg:max-w-full">
      <div className="h-[3.3rem] w-full bg-white-80 absolute top-0 z-[0] rounded-t-[2rem] overflow-hidden lg:hidden">
        <div style={{ width: progressPercentage + '%' }} className="h-full bg-[#985AFF]" />
      </div>
      <div className="w-full flex flex-col h-[98%] items-center p-[4rem] rounded-[2rem] z-[11] bg-[#0f0f11] border-[0.1rem] border-solid border-white-50 lg:!rounded-none lg:!border-0 lg:bg-transparent lg:p-0 lg:pt-[4rem] lg:w-full lg:h-full">
        <div className="flex flex-col items-center justify-between h-full lg:w-full lg:space-y-[2.4rem]">
          <div className="relative flex items-center justify-center mt-[1.5rem] w-[14.8rem] h-[14.8rem] rounded-full border-[0.8rem] border-white-100 border-solid lg:h-[9rem] lg:w-[9rem] lg:border-[0.4rem] lg:mt-[-0.3rem]">
            <button style={styleBg} className="flex items-center justify-center rounded-full w-full h-full">
              {firstLetterNick && (currentUser?.avatar === 'null' || !currentUser?.avatar) && (
                <span className="text-[8rem] text-white font-500 uppercase lg:text-[5rem]">{firstLetterNick}</span>
              )}
            </button>
            <button
              onClick={onOpen}
              className="absolute bottom-[-2rem] flex flex-col justify-center items-center w-[4rem] h-[4rem] rounded-[1.2rem] border-[0.1rem] border-solid border-[#985AFF] bg-[rgba(20,20,20,0.80)] shadow-[0px_4px_15px_0px_rgba(16,16,17,0.40)] backdrop-blur-[1rem] lg:hidden custom-transition hover:bg-[rgba(40,40,40,0.80)]"
            >
              <span className="text-[0.8rem] font-500 text-white-500">Rank</span>
              <span className="text-white text-[2rem] font-600">{currentRank}</span>
            </button>
          </div>
          <div className="pt-[3rem] sm:pt-0 flex-1 w-full flex items-center justify-center flex-col space-y-[1.2rem]">
            {idBlock()}
            <div className="flex flex-col items-center space-y-[2rem]">
              {currentUser?.nickname && (
                <span className="text-[3.2rem] font-500 text-white leading-none lg:text-[2.4rem]">
                  {currentUser?.nickname}
                </span>
              )}

              <div
                onClick={() => onOpenPreview()}
                className="cursor-pointer custom-transition flex space-x-[1.2rem] text-[1.2rem] font-500 bg-white-50 rounded-[10rem] py-[0.8rem] px-[1.2rem] hover:bg-white-100"
              >
                <span className="text-white-500">Your Upline </span>
                <span className="text-[#985AFF]">id{currentUser?.referral?.internalId} </span>
                <div className="border-r border-white-500 h-full"></div>
                <span className="text-[#985AFF]">{shortenAddress(currentUser?.referral?.id)} </span>
              </div>
            </div>
            {/* <span className="text-[1.2rem] text-white-500 leading-[1.68rem] text-center max-w-[28.7rem] lg:hidden">
              You can also share your referral link to your friends or sharing it on social media.
            </span> */}
          </div>
          <div className="hidden lg:flex flex-col items-center w-full relative">
            <div className="flex items-center space-x-[0.4rem] w-[92%] absolute top-[-0.3rem]">
              <div style={{ width: progressPercentage + '%' }} className="rounded-[2rem] bg-[#985AFF] h-[0.8rem]"></div>
              <div className="rounded-[2rem] h-[0.8rem] w-full dashboard-progressBar-bg"></div>
            </div>
            <div className="flex items-center space-x-[1.6rem] h-[5.2rem] w-full bg-white-30 border-[0.1rem] border-solid border-white-50 rounded-[2rem] w-full">
              <div className="flex items-center justify-center space-x-[0.8rem] w-full">
                {xpCounter({ isMobHidden: false })}
              </div>
              <div className="h-full w-[0.1rem] bg-white-100" />
              <div className="flex items-center justify-center space-x-[0.8rem] w-full">
                <div className="flex items-center justify-center relative">
                  <img src={PurpleStar} />
                  <span className="absolute text-white text-[0.6rem] font-600">{currentRank}</span>
                </div>
                <span className="text-[1.8rem] text-white font-600 leading-[2.52rem]">{currentRank}</span>
                <span className="text-[1.4rem] text-white-500 font-500 leading-[1.96rem]">Rank</span>
              </div>
            </div>
          </div>
        </div>
        {!previewUserAddress && (
          <Link
            to="/profile"
            className="absolute right-[2rem] top-[2rem] flex items-center justify-center px-[1.2rem] h-[2.6rem] bg-white-50 rounded-[2rem] lg:right-0 lg:top-[4rem] custom-transition hover:bg-white-100"
          >
            <span className="text-[1.4rem] text-white-500 leading-[3rem]">Edit Profile</span>
          </Link>
        )}
        {xpCounter({ isMobHidden: true })}
      </div>
      <RankModal openedModal={openedModal} handleCloseModal={onClose} />
      <PreviewModModal
        userId={currentUser?.referral?.internalId}
        openedModal={openedPreviewModal}
        handleCloseModal={onClosePreview}
      />
    </div>
  );
};
