import React from 'react';
import { SocialLinksComp } from '../../../components/SocialsListComp';

export const TargetFooter = () => {
  return (
    <div className="flex items-center justify-between w-full px-[8rem] bg-[#0C0C0E] h-[16.4rem] lg:flex-col-reverse lg:items-start lg:space-y-reverse lg:space-y-[2rem] lg:justify-center lg:px-[2rem]">
      <span className="text-white-500 text-[1.4rem] font-400">© 2025 Polinetica. All rights reserved.</span>
      <SocialLinksComp className="!grid-cols-6" />
    </div>
  );
};
