import React, { useEffect } from 'react';
import { Statistics } from './Statistics';
import { ActivityTable } from './ActivityTable';
import { useQuery } from '@apollo/client';
import { GET_ALL_TOTAL_STATS } from '../../../helpers/graphRequests';

export const RecentActivity = () => {
  const { data, error, loading, called } = useQuery(GET_ALL_TOTAL_STATS, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <div className="flex flex-col space-y-[2.4rem] w-full bg-white-40 rounded-[3rem] backdrop-blur-[1.5rem] border-[0.1rem] border-solid border-white-50 p-[2rem] lg:bg-transparent lg:border-none lg:p-[1.6rem]">
      <div className="flex w-full justify-between items-center lg:flex-col lg:items-start lg:space-y-[1rem] ">
        <span className="text-[2.4rem] text-white font-600 tracking-[-0.48px]">Platform recent activity</span>
        <div className="flex items-center justify-center h-[3.3rem] bg-white-50 rounded-[4rem] px-[1.6rem] lg:bg-transparent lg:px-0 lg:h-full">
          <span className="text-[1.2rem] text-white-500 font-500 leading-[1.68rem]">
            Real-time update on the recent events.
          </span>
        </div>
      </div>
      <div className="flex flex-col space-y-[2rem] h-full">
        <Statistics stats={data?.stats[0]} />
        {/* <ActivityTable /> */}
      </div>
    </div>
  );
};
