import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getUser } from '../../../store/userSlice/selectors';
import ImageIcon from '../../../assets/icons/generalIcons/imageIcon.svg';
import TrashIcon from '../../../assets/icons/generalIcons/trashIcon.svg';
import { Input } from '../../../components';
import { parseCookies } from 'nookies';
import { useRequest } from '../../../helpers/hooks/useRequest';
import { UserRepository } from '../../../connectors/repositories/user';
import { ImageUploader } from '../../../components';
import { MobTitle } from '../../../components/MobTitle';
import { Button } from '../../../components/Button';
import userIcon from '../../../assets/icons/generalIcons/userIcon.svg';
import { useAuth } from '../../../helpers/hooks/useAuth';
import { UploadRepository } from '../../../connectors/repositories/upload';
import { callNotification } from '../../../helpers/notification';
import { format } from 'date-fns';

const inputsList = [
  {
    key: 'nickname',
    title: 'Username',
    placeholder: 'Enter username',
  },
  {
    key: 'email',
    title: 'Email Address',
    placeholder: 'Enter your email',
  },
  {
    key: 'x',
    title: 'X (Twitter)',
    placeholder: 'Enter your twitter',
  },
  {
    key: 'instagram',
    title: 'Instagram',
    placeholder: 'Enter your instagram',
  },
];

export const Settings = () => {
  const currentUser = useSelector(getUser);
  const account = localStorage.getItem('account');
  const { authAccount, isComplete } = useAuth();
  const { data, call, isLoading } = useRequest(UserRepository.getProfile);
  const [inputsValue, setInputsValue] = useState({
    nickname: '',
    email: '',
    x: '',
    instagram: '',
  });
  const [avatar, setAvatar] = useState(null);

  const cookies = parseCookies();
  const apiToken = useMemo(() => {
    return cookies?.apiToken;
  }, [isComplete, account]);

  useEffect(() => {
    if (apiToken) {
      call();
    }
  }, [apiToken]);

  const onLogIn = () => {
    authAccount();
  };

  useEffect(() => {
    if (!data) return;
    if (data?.imageUrl) {
      localStorage.setItem('avatar', data?.imageUrl);
      setAvatar(data?.imageUrl);
    }
    setInputsValue({
      nickname: data?.nickname,
      email: data?.email,
      x: data?.x,
      instagram: data?.instagram,
    });
    localStorage.setItem('nickname', data?.nickname);
  }, [data]);

  const onChangeInput = (event, key) => {
    setInputsValue((prev) => ({
      ...prev,
      [key]: event.target.value,
    }));
  };

  const onSaveSettings = async () => {
    await UserRepository.postProfile(inputsValue.nickname, inputsValue.email, inputsValue.x, inputsValue.instagram)
      .then(() => {
        localStorage.setItem('nickname', inputsValue?.nickname);
        callNotification({ type: 'success', message: 'Settings saved' });
      })
      .catch((e) => callNotification({ type: 'error', message: e?.response?.data?.message || 'Something went wrong' }));
  };

  const onSaveAvatar = async () => {
    await UploadRepository.postAvatar(avatar)
      .then((response) => {
        localStorage.setItem('avatar', response?.newImageUrl);
        callNotification({ type: 'success', message: 'Settings saved' });
      })
      .catch((e) => callNotification({ type: 'error', message: e?.response?.data?.message || 'Something went wrong' }));
  };

  const joinedTime = '';

  return (
    <div className="relative flex flex-col p-[4rem] bg-black-300 w-full h-full rounded-[2rem] relative border-[0.1rem] border-solid border-white-50 lg:bg-transparent lg:border-none z-[2] lg:p-[0]">
      {!apiToken && (
        <div className="flex flex-col space-y-[2.4rem] items-center justify-center rounded-[2rem] absolute z-[999] w-full bg-[rgba(12,12,14,0.80)] h-full top-0 left-[0] backdrop-blur-[0.5rem] lg:rounded-none">
          <img src={userIcon} />
          <span className="text-[1.2rem] text-white-500 font-400 leading-[1.68rem] text-center max-w-[21rem]">
            You'll need to sign a contract to tweak the settings.
          </span>
          <Button onClick={onLogIn} type="purple" className="w-[29.35rem] h-[4rem]">
            <span className="text-[1.4rem] font-500 text-white">Load profile info</span>
          </Button>
        </div>
      )}
      <span className="hidden lg:flex text-[2.4rem] font-500 text-white leading-[2.4rem]">Edit Profile</span>
      <div className="flex flex-col space-y-[4.8rem] w-full lg:pt-[6.5rem]">
        <div className="flex items-center justify-between w-full">
          <ImageUploader
            className="cursor-pointer rounded-full w-[17rem] h-[17rem] flex justify-center items-center"
            maxFileSize={2097152}
            value={avatar}
            onChange={(value) => setAvatar(value)}
          >
            <button className="flex items-center justify-center rounded-full w-[17rem] h-[17rem] bg-black-200 border-[0.8rem] border-white-100 border-solid lg:w-[14.8rem] lg:h-[14.8rem]">
              <img src={ImageIcon} />
            </button>
          </ImageUploader>

          <div className="flex space-x-[1rem]">
            <button
              onClick={onSaveAvatar}
              className="flex items-center justify-center px-[1.6rem] h-[4rem] bg-white-100 border-[0.1rem] border-white-50 rounded-[1.2rem]"
            >
              <span className="text-[1.4rem] text-white font-500 tracking-[-0.42px]">Save Image</span>
            </button>
            <button
              onClick={() => setAvatar(null)}
              className="flex items-center justify-center h-[4rem] w-[4rem] bg-white-100 border-[0.1rem] border-white-50 rounded-[1.2rem]"
            >
              <img src={TrashIcon} />
            </button>
          </div>
        </div>

        <div className="flex flex-col space-y-[2.4rem] w-full">
          <div className="grid grid-cols-2 gap-[2.4rem] w-full lg:grid-cols-1">
            {inputsList.map((item, itemIndex) => {
              return (
                <div className={`flex flex-col space-y-[1.2rem] w-full ${item?.className}`}>
                  <span className="text-[1.6rem] text-white font-500 tracking-[-0.32px]">{item?.title}</span>
                  <Input
                    placeholder={item?.placeholder}
                    value={inputsValue?.[item.key] || ''}
                    onChange={(e) => onChangeInput(e, item.key)}
                  />
                </div>
              );
            })}
          </div>
        </div>

        <div className="flex space-x-[2.4rem] w-full lg:space-x-[1.2rem]">
          <button
            onClick={onSaveSettings}
            className="flex items-center justify-center bg-[#985AFF] text-white rounded-[1.2rem] text-[1.4rem] font-500 h-[4rem] w-full"
          >
            Save
          </button>
        </div>
      </div>
      <span className="absolute right-[2rem] top-[2rem] text-[1.2rem] text-white-500 font-400 leading-[2.2rem] lg:top-0 lg:right-0">
        Joined {joinedTime}
      </span>
    </div>
  );
};
