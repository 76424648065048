import React from 'react';
import { useModal } from '../../helpers/hooks/useModal';
import { PreviewModModal } from '../Modals/PreviewModModal';

export const UserIdBlock = ({ id }) => {
  const {
    openedModal: openedPreviewModModal,
    onOpen: onOpenPreviewModModal,
    onClose: onClosePreviewModModal,
  } = useModal();

  return (
    <>
      <div
        onClick={() => onOpenPreviewModModal()}
        className="cursor-pointer whitespace-nowrap flex items-center justify-center px-[1rem] h-[3rem] rounded-full w-fit bg-[rgba(175,127,255,0.10)] lg:h-[2.8rem] hover:opacity-60"
      >
        <span className="text-[1.4rem] font-400 tracking-[-0.42px] text-[#AF7FFF] lg:text-[1.2rem]">ID {id}</span>
      </div>
      <PreviewModModal userId={id} openedModal={openedPreviewModModal} handleCloseModal={onClosePreviewModModal} />
    </>
  );
};
