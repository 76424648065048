import React from 'react';
import { LanguageSelector } from 'components';
import { SocialLinksComp } from '../../components/SocialsListComp';

export const Footer = ({ className }) => {
  return (
    <div
      className={`hidden lg:flex flex-col space-y-[2rem] items-start justify-start py-[4rem] w-full px-[1.6rem] ${className}`}
    >
      <div className="flex flex-col space-y-[2rem] justify-center ">
        <LanguageSelector />
        <SocialLinksComp />
      </div>
      <div className="flex items-center space-x-[0.4rem] text-[1.4rem] font-400">
        <span className="text-white-500">© 2025 Polynetica. All rights reserved.</span>
      </div>
    </div>
  );
};
