import React, { useMemo } from 'react';
import telegramIcon from '../../../assets/icons/landing/polyPower/telegramIcon.svg';
import { Title } from '../../../components/Landing/Title';
import { Link } from 'react-router-dom';
import { socialLinks } from '../../../helpers/socialLinks';
import { ButtonLanding } from '../../../components/Landing/ButtonLanding';
import { Trans } from 'react-i18next';

export const PolyPower = ({ className }) => {
  const styleBg = useMemo(() => {
    return {
      backgroundImage: 'url(/images/landing/polyPower/bgPic.webp)',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    };
  });
  return (
    <div
      style={styleBg}
      className={`flex flex-col items-center space-y-[4rem] pt-[4rem] lg:pt-[2rem] w-full mt-[9.2rem] h-[83.1rem] lg:px-[2rem] lg:h-[62.5rem] ${className}`}
    >
      <Link
        to={socialLinks.telegramChannel}
        target="_blank"
        className="flex items-center jusify-center space-x-[1.2rem] h-[3.6rem] rounded-[1.2rem] botder-solid border-[0.1rem] border-white-300 p-[0.8rem] px-[1.2rem] custom-transition hover:bg-white-50 hover:border-white-500 "
      >
        <img src={telegramIcon} />
        <span className="text-[1.4rem] text-white font-400 lg:text-[1.4rem]">
          {' '}
          <Trans i18nKey="landing.polyPower.telegramLink">Follow us on Telegram</Trans>{' '}
        </span>
      </Link>
      <Title
        className="text-white text-[8rem] leading-[8.8rem] lg:text-[3.2rem] lg:leading-[3.52rem] lg:max-w-[32rem]"
        title={
          <span className="block max-w-[46.6rem] w-full">
            <Trans i18nKey="landing.polyPower.blockTitle">Power for Partnerships</Trans>
          </span>
        }
        desc={
          <span>
            <Trans i18nKey="landing.polyPower.blockDesc">
              Imagine a world where online business partnerships are powered by blockchain technology, seamlessly
              connecting businesses and users. Polynetica is that world.
            </Trans>
          </span>
        }
      />
      <div className="flex space-x-[2.4rem]">
        <Link to={socialLinks.telegramChannel} target="_blank" className="flex items-center justify-center">
          <ButtonLanding type="white" className="h-[4.8rem]">
            <Trans i18nKey="landing.polyPower.joinBtn">Join Polynetica</Trans>
          </ButtonLanding>
        </Link>
        {/* <Button
          type="grey"
          className="flex items-center justify-center h-[4.8rem] rounded-[1.2rem] backdrop-blur-[1rem]"
        >
          <span className="text-[1.6rem] font-500 text-white">Learn More</span>
        </Button> */}
      </div>
    </div>
  );
};
