import React from 'react';
import DuoIcon from '../../../assets/icons/target/streams/duoIcon.svg';

export const DuoTable = () => {
  const levelsList = [
    {
      price: 20,
      oneCycle: 40,
      monthlyCycle: 1200,
    },
    {
      price: 32,
      oneCycle: 64,
      monthlyCycle: 1920,
    },
    {
      price: 43,
      oneCycle: 86,
      monthlyCycle: 2580,
    },
    {
      price: 55,
      oneCycle: 110,
      monthlyCycle: 3300,
    },
    {
      price: 75,
      oneCycle: 150,
      monthlyCycle: 4500,
    },
    {
      price: 105,
      oneCycle: 210,
      monthlyCycle: 6300,
    },
    {
      price: 160,
      oneCycle: 320,
      monthlyCycle: 9600,
    },
    {
      price: 210,
      oneCycle: 420,
      monthlyCycle: 12600,
    },
    {
      price: 320,
      oneCycle: 640,
      monthlyCycle: 19200,
    },
    {
      price: 430,
      oneCycle: 860,
      monthlyCycle: 25800,
    },
    {
      price: 540,
      oneCycle: 1080,
      monthlyCycle: 32400,
    },
    {
      price: 750,
      oneCycle: 1500,
      monthlyCycle: 45000,
    },
    {
      price: 1070,
      oneCycle: 2140,
      monthlyCycle: 64200,
    },
    {
      price: 1600,
      oneCycle: 3200,
      monthlyCycle: 96000,
    },
    {
      price: 2100,
      oneCycle: 4200,
      monthlyCycle: 126000,
    },
  ];
  return (
    <div className="flex flex-col w-full bg-[rgba(12,12,14,0.10)] border-[0.2rem] border-solid border-[#51F689] rounded-[3rem] shadow-[0px_0px_31.091px_0px_rgba(81,246,137,0.10)_inset] space-y-[2.8rem] justify-start w-full mt-[22rem] lg:max-w-full max-w-[120rem] overflow-hidden z-[1] lg:mt-[5rem] lg:rounded-[2.6rem]">
      <div className="flex w-full items-center justify-between pt-[3.4rem] px-[3.4rem] lg:px-[2rem]">
        <span className="text-[2.4rem] font-600 text-white lg:text-[2rem] lg:max-w-[20rem]">
          DUO <span className="text-white-500">Level Costs and Potential Earnings</span>
        </span>
        <img className="lg:w-[2.4rem] lg:h-[2.4rem]" src={DuoIcon} />
      </div>
      <div className="flex flex-col w-full">
        <div className="flex justify-between items-end text-white-300 text-[1.4rem] font-500 h-[3.2rem] mb-[2.8rem] lg:text-[1rem] lg:h-full lg:leading-[1.6rem] lg:px-[2rem]">
          <div className="flex-1 text-center font-semibold lg:text-left">Level</div>
          <div className="flex-1 text-center font-semibold lg:max-w-[8rem] lg:text-left ">Level Price, POL</div>
          <div className="flex-1 text-center font-semibold lg:max-w-[8rem]">
            One cycle Reward
            <br /> (+200%), POL
          </div>
          <div className="flex-1 text-center font-semibold lg:text-right lg:w-[10rem]">
            Monthly cycle Reward
            <br className="lg:hidden" /> (+6000%), POL
          </div>
        </div>
        <div className="flex flex-col space-y-[0.2rem]">
          {levelsList.map((item, itemIndex) => {
            return (
              <div
                className={`${
                  (itemIndex < 5 && ' bg-[rgba(81,246,137,0.10)]') ||
                  (itemIndex > 4 && itemIndex < 10 && 'bg-[rgba(81,246,137,0.30)]') ||
                  (itemIndex > 9 && 'bg-[rgba(81,246,137,0.70)]')
                } flex justify-between py-[1.2rem] text-[1.6rem] font-500 text-white leading-none lg:text-[1.4rem] lg:px-[2rem]`}
                key={itemIndex}
              >
                <div className="flex-1 text-center lg:text-left">{itemIndex + 1}</div>
                <div className="flex-1 text-center lg:text-left">{item?.price}</div>
                <div className="flex-1 text-center">{item?.oneCycle}</div>
                <div className="flex-1 text-center lg:text-right">{item?.monthlyCycle}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
