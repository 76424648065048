import React from 'react';
import WhiteCopyIcon from '../../../../assets/icons/generalIcons/whiteCopyIcon.svg';
import GrayPlusIcon from '../../../../assets/icons/generalIcons/grayPlusIcon.svg';
import { copy } from '../../../../helpers/copy';
import { useSelector } from 'react-redux';
import { getUser } from '../../../../store/userSlice/selectors';
import { Button } from '../../../../components/Button';
import { usePreviewMode } from '../../../../helpers/hooks/useCheckPreviewMode';

export const Reflink = ({ className }) => {
  const currentUser = useSelector(getUser);
  const { previewUserAddress } = usePreviewMode();

  const refLink = `https://polynetica.com/r/${currentUser.refNumber}`;
  return (
    <div
      className={`flex flex-col justify-between p-[2rem] w-full dashboard-block-styles lg:space-y-[2.4rem] ${className}`}
    >
      <div className="flex flex-col space-y-[0.8rem]">
        {previewUserAddress ? (
          <span className="text-[1.6rem] font-500 leading-[2.2rem] text-white">
            ID{currentUser?.internalId} <span className="opacity-50">Partner Link</span>
          </span>
        ) : (
          <>
            <span className="text-[1.6rem] font-500 leading-[2.2rem] text-white">Share your Partner Link</span>
          </>
        )}
        <span className="text-[1.2rem] leading-[1.68rem] text-white-500">Get rewards for inviting new users</span>
      </div>
      <div className="flex flex-col space-y-[1.2rem]">
        <div className="flex w-full h-[4rem] justify-between space-x-[1.2rem] flex items-center text-[1.2rem] justify-center text-white-500 w-full h-[4rem] px-[1.6rem] rounded-[1rem] border-[0.1rem] border-solid border-white-100 border-[0.1rem] border-solid border-white-100 ">
          <input
            readOnly
            className=" outline-none bg-transparent placeholder:text-white-500 w-full "
            value={refLink?.replace('https://', '')}
          />
          <button onClick={() => copy(refLink)} className="shrink-0">
            <img className="lg:w-[2rem] lg:h-[2rem] opacity-50" src={WhiteCopyIcon} />
          </button>
        </div>
        <div className="flex w-full h-[4rem] space-x-[1.2rem]">
          {/* <Button type="gray" className="w-full text-[1.4rem] font-500">
            Share
          </Button> */}
          <Button onClick={() => copy(refLink)} type="purple" className="w-full text-[1.4rem] font-500">
            Copy
          </Button>
        </div>
      </div>
    </div>
  );
};
