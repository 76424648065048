import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../Button';
import polIcon from '../../assets/icons/main/polCoin.svg';
import { Advantages } from './Advantages';

export const TargetPackage = ({ name, type, price, duoLevel, quattroLevel }) => {
  return (
    <div className=" z-[11] flex flex-col justify-between p-[3rem] h-[62.2rem] overflow-hidden w-[41.1rem] shadow-[-19.384px_29.076px_58.152px_0px_#0C0C0E,0px_0px_45.051px_0px_rgba(255,255,255,0.05)inset] backdrop-blur-[1.5rem] bg-white-40 rounded-[4rem] border-[0.1rem] border-solid border-white-200 lg:w-[30.7rem] lg:p-[2rem] lg:rounded-[3rem] lg:h-full lg:shrink-0 ">
      <div className="flex flex-col space-y-[2.4rem] lg:space-y-[2rem]">
        <div className="flex items-center justify-between w-full">
          <span className="text-[4.2rem] text-white font-600 lg:text-[3.2rem]">{name}</span>
          <div
            className={`flex items-center justify-center rounded-[2rem] h-[3rem] px-[1rem] lg:px-[0.8rem] lg:h-[2.2rem]  ${
              name === 'Elite'
                ? 'bg-[#985AFF] text-white shadow-[0px_5.31px_21.238px_-6.371px_rgba(152,90,255,0.90)]'
                : 'text-white-500 bg-white-100'
            }`}
          >
            <span className="text-[1.2rem] font-500 lg:text-[0.9rem]">{type}</span>
          </div>
        </div>
        <div className="flex flex-col justify-center space-y-[1rem] w-full rounded-[1.6rem] h-[9rem] py-[1.6rem] px-[1.8rem] border-[0.1rem] border-solid border-[#985AFF] bg-[rgba(17,17,19,0.01)] shadow-[0px_0px_0px_8px_rgba(152,90,255,0.10)_inset,0px_0px_0px_4px_rgba(152,90,255,0.15)_inset] lg:h-[6.6rem] lg:py-[1.2rem] lg:px-[1.2rem] lg:rounded-[1.2rem] lg:space-y-[0.6rem]">
          <div className="flex items-center justify-between w-full text-[1.6rem] text-white lg:text-[1.2rem]">
            <span className="font-500">DUO</span>
            <span className="font-600">{duoLevel} LVL</span>
          </div>
          <div
            className={`flex items-center justify-between w-full text-[1.6rem] text-white lg:text-[1.2rem] ${
              name === 'Basic' && 'opacity-50'
            }`}
          >
            <span className="font-500">QUATTRO</span>
            {name === 'Basic' ? (
              <span className="font-600 pr-[1rem]">{quattroLevel}</span>
            ) : (
              <span className="font-600">{quattroLevel} LVL</span>
            )}
          </div>
        </div>
        <div className="flex flex-col space-y-[0.8rem] lg:space-y-[0.6rem]">
          {name === 'Basic' && (
            <>
              <Advantages text="Polynetica SMP Dashboard" />
              <Advantages text="+800 XP" />
            </>
          )}
          {name === 'Pro' && (
            <>
              <Advantages text="Polynetica SMP Dashboard" />
              <Advantages text="+7200 XP" />
              <Advantages text="Account Rank: 7" />
              <Advantages text="PRO Tool Access" />
              <Advantages text="Mentorship Platform" />
              <Advantages text="Premium Support (Care Dept.)" />
            </>
          )}
          {name === 'Elite' && (
            <>
              <Advantages text="Polynetica SMP Dashboard" />
              <Advantages text="+45600 XP" />
              <Advantages text="Account Rank: 18" />
              <Advantages text="PRO Tool Access" />
              <Advantages text="Mentorship Platform" />
              <Advantages text="Premium Support (Care Dept.)" />
              <Advantages text="Elite Business Community Access" />
              <Advantages text="Elite Royalty Performance Programs" />
              <Advantages text="Private meeting with Polynetica Сreators" />
            </>
          )}
        </div>
      </div>
      <Link to="/register" className="w-full">
        <Button
          className="flex space-x-[0.5rem] text-[2.2rem] font-500 w-full h-[6.4rem] !rounded-[1.6rem] shadow-[0px_9.692px_58.152px_0px_rgba(152,90,255,0.50)] lg:h-[4.8rem] lg:!rounded-[1.4rem] lg:space-x-[0.4rem] lg:text-[1.6rem] lg:mt-[3.8rem]"
          type="purple"
        >
          <span className="text-white ">Buy for</span>
          <span>{price}</span>
          <img className="lg:w-[1.65rem] lg:h-[1.65rem]" src={polIcon} />
        </Button>
      </Link>
      {name === 'Elite' && (
        <img className="absolute right-0 top-0" src="/images/target/Registration/Elite/shadow.webp" />
      )}
    </div>
  );
};
