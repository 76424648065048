import React from 'react';
import { Link } from 'react-router-dom';

export const MiniPrograms = ({ className }) => {
  const programsList = [
    {
      title: 'Duo',
      img: 'duo',
      shadow: 'douShadow',
      url: '/dashboard/duo',
    },
    {
      title: 'Quattro',
      img: 'quattro',
      shadow: 'quattroShadow',
      url: '/dashboard/quattro',
    },
  ];

  return (
    <div className="flex flex-col justify-between p-[2rem] w-full h-[21.25rem] dashboard-block-styles lg:bg-transparent lg:!border-none lg:p-0 lg:h-full">
      <span className="text-[1.6rem] font-500 leading-[2.2rem] text-white lg:hidden">Polynetica Programs</span>
      <div className="flex flex-col space-y-[2.4rem] lg:flex-row lg:space-y-0 lg:space-x-[1.2rem]">
        {programsList.map((item, itemIndex) => {
          return (
            <div className="relative flex items-center flex-col  w-full group">
              <div className="w-[92%] h-[3rem] absolute bg-[#1C1C1E] top-[-0.8rem] rounded-t-[1rem] custom-transition group-hover:rotate-[-1deg]" />
              <Link
                className="relative flex items-center justify-between w-full rounded-[1rem] h-[4.8rem] px-[1.6rem] bg-[#232325] overflow-hidden transform transition-all duration-300 group-hover:rotate-[1deg]"
                to={item?.url}
                key={itemIndex}
              >
                <span className="text-[1.6rem] text-white font-500 leading-[2.24rem]">{item?.title}</span>
                <img
                  className="w-[4.72rem] h-[2.26rem]"
                  src={`/images/dashboard/profile/miniPrograms/${item?.img}.webp`}
                />
                <img className="absolute right-0" src={`/images/dashboard/profile/miniPrograms/${item?.shadow}.webp`} />
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};
