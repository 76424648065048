import React, { useEffect } from 'react';
import Checkicon from '../../../../assets/icons/generalIcons/whiteCheckIcon.svg';
import { RegisterBar } from '../registerBar';
import PolIcon from '../../../../assets/icons/main/polCoin.svg';

export const PlanCard = ({
  name,
  type,
  price,
  fee,
  color,
  textColor,
  onClick,
  chosePlan,
  id,
  shadow,
  itemIndex,
  duoLevel,
  quattroLevel,
  benefitsList,
}) => {
  const sum = (
    <span
      className={`text-white-500 text-[1.2rem] leading-[1.96rem] max-w-[90%] ${
        chosePlan ? 'text-left max-w-[17rem]' : 'text-center max-w-[90%]'
      }`}
    >
      *Including <span className="text-white font-500">one-time activation fee ({fee / 1e18} POL)</span>.
    </span>
  );

  return (
    <div
      className={`flex rounded-[2rem] border-[0.1rem] border-solid border-white-100 bg-black-300 backdrop-blur-[5rem] overflow-hidden register-plan-animation lg:flex lg:flex-col ${
        chosePlan
          ? 'p-[4rem] lg:p-[2.8rem] lg:mt-0 lg:w-full'
          : 'p-[2.8rem] h-[67.3rem] max-w-[38.7rem] w-full lg:h-full lg:max-w-full'
      }`}
    >
      <div
        className={`flex flex-col justify-between ${
          chosePlan ? 'w-[19rem]' : ' w-full'
        } lg:space-y-[3.2rem] z-[1] lg:w-full`}
      >
        <div className="flex flex-col space-y-[2.4rem] w-full">
          <div className={`flex flex-col space-y-[1.2rem] w-full ${chosePlan && 'lg:space-y-[0.4rem]'}`}>
            <div className="flex items-center justify-between w-full">
              <span className="text-[2.4rem] text-white font-600">{name}</span>
              <div
                className={`flex items-center justify-center bg-${color} ${shadow} rounded-[2rem] px-[1rem] h-[2.8rem] register-plan-animation ${
                  chosePlan && 'hidden'
                }`}
              >
                <span className={`text-[1.2rem] text-${textColor} font-500`}>{type}</span>
              </div>
            </div>
            <div className={`flex flex-col ${chosePlan && 'space-y-[3.2rem] lg:space-y-[0.4rem]'}`}>
              <span className="text-[1.8rem] text-white-500 font-500 leading-[2.88rem] lg:text-[1.6rem] lg:leading-[2.56rem]">
                A one-time payment of <br className="lg:hidden" />
                <span className="text-white font-600">{chosePlan ? (price + fee) / 1e18 : price / 1e18} POL</span>
              </span>
              {chosePlan && sum}
            </div>
          </div>
          {!chosePlan && (
            <div className="flex flex-col space-y-[0.4rem] leading-[2.24rem] p-[1.6rem] border-[0.1rem] border-solid border-[#985AFF] bg-[#020203] shadow-[0px_0px_0px_8px_rgba(152,90,255,0.10)inset,_0px_0px_0px_4px_rgba(152,90,255,0.15)inset] rounded-[1.6rem]">
              <div className="flex w-full justify-between">
                <span className="text-[1.4rem] font-500 text-white">DUO</span>
                <span className="text-[1.4rem] font-600 text-white">{duoLevel} LVL</span>
              </div>
              <div className={`flex w-full justify-between ${itemIndex === 0 && 'opacity-50 pr-[1rem]'}`}>
                <span className="text-[1.4rem] font-500 text-white">QUATTRO</span>
                <span className="text-[1.4rem] font-600 text-white">{quattroLevel}</span>
              </div>
            </div>
          )}

          <div className={`flex flex-col space-y-[0.8rem] register-plan-animation ${chosePlan && 'hidden'}`}>
            {benefitsList.map((item, itemIndex) => (
              <div className="flex items-center space-x-[1.6rem]" key={itemIndex}>
                <img src={Checkicon} />
                <span className="text-[1.4rem] font-500 text-white leading-[2.24rem]">{item}</span>
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col items-center w-full space-y-[1.2rem]">
          <button
            onClick={() => onClick(id)}
            className={`flex items-center justify-center h-[4.8rem] bg-${color} rounded-[1.2rem] w-full custom-transition z-[1] hover:opacity-70 ${
              chosePlan &&
              'bg-white-50 lg:bg-white-100 lg:h-[2.8rem] lg:rounded-[2rem] lg:px-[1.2rem] lg:w-fit  lg:absolute lg:right-[2.8rem] lg:top-[2.8rem] '
            }`}
          >
            <span className={`text-[1.6rem] font-500 text-white leading-[2.24rem] ${chosePlan && 'lg:text-[1.4rem]'}`}>
              {chosePlan ? (
                'Change'
              ) : (
                <div className="flex space-x-[0.4rem] items-center">
                  <span>Buy for</span>
                  <span>{(price + fee) / 1e18}</span>
                  <img className="w-[1.6rem] h-[1.6rem]" src={PolIcon} />
                </div>
              )}
            </span>
          </button>
          {!chosePlan && sum}
        </div>
        {id === 2 && (
          <img
            className="hidden lg:flex absolute bottom-0 right-0 z-[0]"
            src="/images/register/Plans/RegisterBar/shadow.webp"
          />
        )}
      </div>
      {chosePlan && (
        <>
          <RegisterBar totalPrice={price + fee} fee={fee} planId={id} />
          <img className="absolute bottom-0 right-0 z-[0]" src="/images/register/Plans/RegisterBar/shadow.webp" />
        </>
      )}
    </div>
  );
};
