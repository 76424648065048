import React, { useState } from 'react';
import copyIcon from '../../../../../assets/icons/main/copy.svg';
import walletIcon from '../../../../../assets/icons/main/wallet.svg';
import polIcon from '../../../../../assets/icons/main/polCoin.svg';
import pollyIcon from '../../../../../assets/icons/main/pollyCoin.svg';
import { copy } from '../../../../../helpers/copy';
import { useWeb3React } from '@web3-react/core';
import { shortenAddress } from '../../../../../helpers/format';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getBalance } from '../../../../../store/walletSlice/selectors';
import { useDeactivationWallet } from '../../../../../helpers/hooks/useDeactivationWallet';
import { useTranslation } from 'react-i18next';
import { getUser } from '../../../../../store/userSlice/selectors';
import { round } from '../../../../../helpers/numbers';

export const Wallet = ({ onClose }) => {
  const { account } = useWeb3React();
  const { t } = useTranslation();
  const currentUser = useSelector(getUser);
  const { deactivationWallet } = useDeactivationWallet();
  const { pol: balancePol, polly: balancePolly } = useSelector(getBalance);

  const deactivate = () => {
    onClose();
    deactivationWallet();
  };

  if (!account) {
    return (
      <div className="flex-1 flex flex-col items-center justify-center">
        <span className="text-white text-[2rem] font-500 max-w-[20rem] text-center w-full">
          Сonnect your wallet to continue
        </span>
      </div>
    );
  }

  const totalProfit = Number(currentUser?.totalRewardX2) + Number(currentUser?.totalRewardX4);

  return (
    <div className="flex flex-col space-y-[2.4rem] w-full">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-[1.6rem]">
          <button className="flex items-center justify-center w-[4rem] h-[4rem] bg-white-80 rounded-[1.2rem]">
            <img src={walletIcon} />
          </button>
          <div className="flex flex-col space-y-[0.4rem]">
            <span className="text-[1.2rem] font-600 text-white">Polygon</span>
            <span className="text-[1.4rem] font-400 text-white opacity-50">{shortenAddress(account)}</span>
          </div>
        </div>
        <button
          onClick={() => copy(account)}
          className="custom-transition flex items-center justify-center w-[4rem] h-[4rem] bg-white-80 rounded-[1.2rem] hover:bg-white-100"
        >
          <img src={copyIcon} />
        </button>
      </div>
      <div className="flex flex-col items-center justify-center space-y-[1.2rem]">
        <div className="flex items-center justify-between w-full h-[7.2rem] border-[0.1rem] border-solid border-white-50 rounded-[1.2rem] bg-white-50 px-[2.4rem]">
          <span className="text-[1.4rem] font-400 text-white-500">Total Profit</span>
          <div className="flex items-center space-x-[0.8rem]">
            <img className="w-[2.4rem] h-[2.4rem]" src={polIcon} />
            <span className="text-[2rem] font-600 leading-[2.8rem] text-white">
              {round(Number(totalProfit) / 1e18)}
            </span>
            <span className="text-[2rem] font-600 leading-[2.8rem] text-white">POL</span>
          </div>
        </div>
        <div className="flex flex-col items-center space-y-[1.6rem] p-[2.4rem] w-full rounded-[1.2rem] border-[0.1rem] border-solid border-white-100">
          <div className="flex justify-between w-full">
            <div className="flex items-center text-[1.4rem] font-600 leading-[1.96rem] text-white space-x-[0.8rem] ">
              <img src={pollyIcon} />
              <span>{round(balancePolly, 2)}</span>
              <span>POLLY</span>
            </div>
            {/* <span className="text-[1.4rem] text-white opacity-50">$33.8</span> */}
          </div>
          <div className="flex justify-between w-full">
            <div className="flex items-center text-[1.4rem] font-600 leading-[1.96rem] text-white space-x-[0.8rem] ">
              <img src={polIcon} />
              <span>{round(balancePol, 2)}</span>
              <span>POL</span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col space-y-[1.2rem]">
        <button
          onClick={() => deactivate()}
          className="custom-transition flex items-center justify-center h-[4.8rem] rounded-[1.2rem] bg-white-50 border-[0.1rem] border-solid border-white-50 hover:bg-white-100"
        >
          <span className="text-[1.6rem] font-500 text-white tracking-[-0.48px]">
            <Trans i18nKey="main.disconnectWallet">Disconnect wallet</Trans>
          </span>
        </button>
      </div>
    </div>
  );
};
