import { useState } from 'react';
import { ContractNames } from '../constants';
import { useWeb3React } from '@web3-react/core';
import { useGetContract } from './useGetContract';
import { bigIntToInt } from '../numbers';
import config from '../config';

export const useEnergyNft = () => {
  const { account } = useWeb3React();
  const { getContract } = useGetContract();
  const [isApprovalNft, setIsApprovalNft] = useState(null);
  const [totalClaimed, setTotalClaimed] = useState(0);
  const [balanceNft, setBalanceNft] = useState(0);
  const [rewardForClaim, setRewardForClaim] = useState(0);
  const [availableToMint, setAvailableToMint] = useState(null);
  const [currentWeek, setCurrentWeek] = useState(null);
  const [weekNfts, setWeekNfts] = useState([]);

  // total claimed

  const getTotalClaimed = async () => {
    try {
      const contract = await getContract(ContractNames.ENERGY_POOL);

      const result = await contract.userTotalRewardClaimed(account);
      if (result) {
        setTotalClaimed(bigIntToInt(result));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getNftOnWeek = async () => {
    try {
      const contract = await getContract(ContractNames.ENERGY_POOL);
      const week = await contract.getCurrentWeek();

      if (week) {
        const intWeek = parseInt(week);

        const result = await contract.getUserStakedNfts(account, intWeek);
        const result1 = await contract.getUserStakedNfts(account, intWeek + 1);

        if (result && result1) {
          let nfts = result.map((item, itemIndex) => {
            return parseInt(item);
          });
          let nfts1 = result1.map((item, itemIndex) => {
            console.log(item);
            return parseInt(item);
          });
          setWeekNfts({ current: nfts, next: nfts1 });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  // check approve for transfer

  const checkNftApprove = async () => {
    try {
      const contract = await getContract(ContractNames.ENERGY_NFT);

      const result = await contract.isApprovedForAll(account, config.contractEnergyPool);
      console.log(result, 1324);
      if (result) {
        setIsApprovalNft(result);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const approveNft = async () => {
    try {
      const contract = await getContract(ContractNames.ENERGY_NFT);

      const result = await contract.setApprovalForAll(config.contractEnergyPool, true);

      if (result) {
        return result;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getBalanceNft = async () => {
    try {
      const contract = await getContract(ContractNames.ENERGY_NFT);

      const accountArray = new Array(30).fill(account);
      const accountIds = Array.from({ length: 30 }, (_, index) => index + 1);

      const result = await contract.balanceOfBatch(accountArray, accountIds);

      if (result) {
        let balance = result.map((item, itemIndex) => {
          return parseInt(item);
        });
        setBalanceNft(balance);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getUnclaimedReward = async () => {
    try {
      const contract = await getContract(ContractNames.ENERGY_POOL);

      const result = await contract.getUserUnclaimedRewards(account);

      if (result) {
        console.log(result);
        return setRewardForClaim(bigIntToInt(result));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const claimReward = async () => {
    try {
      const contract = await getContract(ContractNames.ENERGY_POOL);

      const result = await contract.claim(account);

      if (result) {
        return result;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const stakeNfts = async (id, amount) => {
    console.log(id, amount);
    try {
      const contract = await getContract(ContractNames.ENERGY_POOL);

      const result = await contract.stakeNfts([id], [amount]);

      if (result) {
        return result;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const claimNfts = async () => {
    try {
      const contract = await getContract(ContractNames.ROUTER);

      const result = await contract.claimNfts();

      if (result) {
        return result;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getAvailableNft = async () => {
    try {
      const contract = await getContract(ContractNames.ROUTER);

      const result = await contract.getUserAvailableNfts(account);

      if (result) {
        setAvailableToMint(result);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return {
    weekNfts,
    currentWeek,
    totalClaimed,
    isApprovalNft,
    setIsApprovalNft,
    availableToMint,
    balanceNft,
    rewardForClaim,
    getAvailableNft,
    claimNfts,
    getBalanceNft,
    stakeNfts,
    getUnclaimedReward,
    claimReward,
    approveNft,
    checkNftApprove,
    getTotalClaimed,
    getNftOnWeek,
  };
};
