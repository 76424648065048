import React from 'react';
import DuoIcon from '../../../../assets/icons/target/streams/duoIcon.svg';
import QuattroIcon from '../../../../assets/icons/target/streams/quattroIcon.svg';

export const Programs = () => {
  const streamsList = [
    {
      title: 'DUO',
      icon: DuoIcon,
    },
    {
      title: 'QUATTRO',
      icon: QuattroIcon,
    },
  ];
  return (
    <div className="flex items-center space-x-[4rem] w-full lg:max-w-full max-w-[120rem] lg:flex-col lg:space-x-0 lg:space-y-[2rem]">
      {streamsList.map((item, itemIndex) => {
        return (
          <div
            className={`${
              itemIndex === 0
                ? ' border-[#51F689] shadow-[0px_0px_130px_-70px_rgba(81,246,137,0.70)inset,0px_0px_35px_0px_rgba(255,255,255,0.10)inset]'
                : 'border-[#F1A13B] shadow-[0px_0px_130px_-70px_rgba(241,161,59,0.70)inset,0px_0px_35px_0px_rgba(255,255,255,0.10)inset]'
            } flex flex-col space-y-[6rem] w-full pb-[6rem] p-[4rem] bg-[#0C0C0E] border-[0.4rem] border-solid rounded-[5rem] lg:space-y-[4rem] lg:p-[2rem] lg:pb-[3rem] lg:rounded-[2.5rem]`}
          >
            <div className="flex flex-col justify-between space-y-[1rem] text-[3rem] font-500 lg:text-[2rem] lg:space-y-[0.5rem]">
              <div className="flex items-center justify-between">
                <span className="text-white">Social Mining Program</span>
                <img className="lg:w-[2rem] lg:h-[2rem]" src={item?.icon} />
              </div>
              <span className={itemIndex === 0 ? 'text-[#51F689]' : 'text-[#F1A13B]'}>{item?.title}</span>
            </div>
            <div className="flex flex-col space-y-[2.4rem] text-[2rem] font-500 lg:text-[1.4rem] lg:space-y-[1.6rem]">
              <span className="text-white-500">
                Cycle ROI: <span className="text-white">{itemIndex === 0 ? '+200%' : '+300%'}</span>
              </span>
              <img src="/images/target/Streams/line.webp" />
              <span className="text-white-500">
                Expected Monthly Earnings: <span className="text-white">{itemIndex === 0 ? '+6000%' : '+9000%'}</span>
              </span>
              <img src="/images/target/Streams/line.webp" />
              <span className="text-white-500">
                Number of Levels in SMP: <span className="text-white">{itemIndex === 0 ? '15' : '15'}</span>
              </span>
              <img src="/images/target/Streams/line.webp" />
              <span className="text-white-500">
                Mining Slots per Level: <span className="text-white">{itemIndex === 0 ? '2' : '6'}</span>
              </span>
              <img src="/images/target/Streams/line.webp" />
              <span className="text-white-500">
                Slot Mining per Level: <span className="text-white">{itemIndex === 0 ? '+100%' : '+50%'}</span>
              </span>
              <img src="/images/target/Streams/line.webp" />
              <span className="text-white-500">
                Spillovers:{' '}
                <span className="text-white">{itemIndex === 0 ? 'Personal Referrals' : 'Unlimited System-Wide'}</span>
              </span>
              <img src="/images/target/Streams/line.webp" />
              <span className="text-white-500 h-[7.1rem] lg:h-full">
                Mining Mechanics:{' '}
                <span className="text-white">
                  {itemIndex === 0
                    ? 'Sequential Activation, Freezing, Overtaking, Bonus, Personal Referral Spillovers'
                    : ' Sequential Activation, Freezing, Overtaking, Bonus, Personal Referral Spillovers, and Unlimited System-Wide Spillovers'}
                </span>
              </span>
              <img src="/images/target/Streams/line.webp" />
              <span className="text-white-500">
                Conclusion:{' '}
                <span className="text-white">
                  {itemIndex === 0
                    ? 'In DUO, cycles complete faster, but partner collaboration is essential.'
                    : 'In Quattro, earnings are generated by the system even without personal partners'}
                </span>
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};
