import React, { useMemo, useState, useEffect, useRef } from 'react';
import { BaseLayout } from '../../layouts';
import { useParams } from 'react-router-dom';
import { LevelCard } from '../../features/programPage/LevelCard';
import PolIcon from '../../assets/icons/main/polCoin.svg';
import ListIcon from '../../assets/icons/dashboard/listicon.svg';
import GridIcon from '../../assets/icons/dashboard/gridIcon.svg';
import { useWeb3React } from '@web3-react/core';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { updateMatrixX2X4 } from '../../store/matrixX2X4Slice';
import { GET_MATRIX_DATA } from '../../helpers/graphRequests';
import { useQuery } from '@apollo/client';
import { getUser } from '../../store/userSlice/selectors';
import { getMatrixX2X4 } from '../../store/matrixX2X4Slice/selectors';
import { PROGRAM_NAMES, PROGRAM_PRICES, PROGRAM_PRICES_WEI, PROGRAM_FEE_WEI } from '../../helpers/constants';
import { ProgramsDropdown } from '../../components/ProgramsDropdown';
import { NftBanner } from '../../features/programPage/NftBanner';
import { usePreviewMode } from '../../helpers/hooks/useCheckPreviewMode';
import { useUpgradeLvl } from '../../helpers/hooks/useUpgradeLvl';
import { useCallTransaction } from '../../helpers/hooks/useCallTransaction';
import { Button } from '../../components/Button';
import { round } from '../../helpers/numbers';

export const Program = () => {
  const { account } = useWeb3React();
  const dispatch = useDispatch();
  let { program } = useParams();
  const { previewUserAddress } = usePreviewMode();
  const [userAddress, setUserAddress] = useState(null);

  useEffect(() => {
    if (previewUserAddress) {
      setUserAddress(previewUserAddress?.toLocaleLowerCase());
    } else {
      setUserAddress(account?.toLocaleLowerCase());
    }
  }, [account, previewUserAddress]);
  const [isNeedToCall, setIsNeedToCall] = useState(false);
  const [isFirstCall, setIsFirstCall] = useState(true);

  useEffect(() => {
    if (userAddress) {
      setIsNeedToCall(true);
      setIsFirstCall(false);
    }
  }, [userAddress]);

  const updateInfo = () => {
    if (!isFirstCall) {
      setIsNeedToCall(true);
    }
  };

  useEffect(() => {
    let interval;
    if (!document?.hidden) {
      interval = setInterval(() => {
        updateInfo();
      }, 30000);
    }
    return () => clearInterval(interval);
  }, []);

  const currentUser = useSelector(getUser);

  const matrixData = useSelector(getMatrixX2X4);

  const { data, error, loading, called } = useQuery(GET_MATRIX_DATA, {
    variables: { user: userAddress },
    skip: !isNeedToCall,
    fetchPolicy: 'cache-and-network',
  });

  const currentMatrix = useMemo(() => {
    if (program.toLocaleLowerCase() === PROGRAM_NAMES.X2.toLocaleLowerCase()) {
      return matrixData?.levelsX2;
    } else {
      return matrixData?.levelsX4;
    }
  }, [matrixData, program]);

  const currentTotalReward = useMemo(() => {
    if (program.toLocaleLowerCase() === PROGRAM_NAMES.X2.toLocaleLowerCase()) {
      return Number(currentUser?.totalRewardX2) / 1e18;
    } else {
      return Number(currentUser?.totalRewardX4) / 1e18;
    }
  }, [currentUser, program]);

  const currentMatrixName = useMemo(() => {
    if (program.toLocaleLowerCase() === PROGRAM_NAMES.X2.toLocaleLowerCase()) {
      return PROGRAM_NAMES.X2;
    } else {
      return PROGRAM_NAMES.X4;
    }
  }, [program]);

  const maxActiveLevel = currentMatrix?.reduce((acc, item) => {
    if (!!item.isActivated) {
      return (acc = acc + 1);
    }
    return acc;
  }, 0);

  useEffect(() => {
    if (data) {
      if (!!data?.user?.levelsX2 || !!data?.user?.levelsX4) {
        dispatch(
          updateMatrixX2X4({
            loading: loading,
            called: called,
            levelsX2: data?.user?.levelsX2,
            levelsX4: data?.user?.levelsX4,
          }),
        );
      }
    }
  }, [data, isNeedToCall]);

  const displaySheet = [
    {
      icon: GridIcon,
    },
    {
      icon: ListIcon,
    },
  ];

  const [currenTub, setCurrentTub] = useState(0);

  const checkPartners = (currenCycleData) => {
    if (!!currenCycleData) {
      const placesArr = Object.values(currenCycleData).slice(2);

      let filledPlaces = placesArr.filter((item) => !!item);

      return filledPlaces.length;
    }
    return 0;
  };

  // click to upgrade

  const levelsX2 = currentMatrixName === PROGRAM_NAMES.X2 ? [maxActiveLevel + 1] : [];
  const levelsX4 = currentMatrixName === PROGRAM_NAMES.X4 ? [maxActiveLevel + 1] : [];

  const { upgradeLvl } = useUpgradeLvl();
  const { onCallTransaction, transactionInfo, resetTransactionInfo } = useCallTransaction();
  const upgradeClick = async () => {
    try {
      const result = await upgradeLvl(levelsX2, levelsX4);

      if (result) {
        onCallTransaction(result);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (transactionInfo.isSuccess) {
      updateInfo();
      resetTransactionInfo();
    }
  }, [transactionInfo]);

  const mobileTotalPrice =
    (Number(PROGRAM_PRICES_WEI[currentMatrixName][maxActiveLevel]) +
      Number(PROGRAM_FEE_WEI[currentMatrixName][maxActiveLevel])) /
    1e18;

  return (
    <BaseLayout>
      <NftBanner mob={false} />
      <div className="flex flex-col w-full p-[4rem] relative rounded-[3rem] bg-[linear-gradient(0deg,rgba(255,255,255,0.04)_0%,rgba(255,255,255,0.04)_100%),#020204] border-[0.1rem] border-solid border-white-50 backdrop-blur-[5rem] lg:rounded-none lg:border-none lg:p-[1.6rem]">
        <div className="flex flex-col space-y-[3.2rem] w-full z-[11]">
          <div className="flex w-full justify-between space-y-0">
            <ProgramsDropdown currentMatrixName={currentMatrixName} />
            <div className="flex items-center space-x-[2.4rem] lg:space-x-0">
              <div className="flex items-center space-x-[0.8rem]">
                <span className="text-white text-[2rem] font-600 tracking-[-0.4px]">
                  {round(currentTotalReward)} POL
                </span>
                <img src={PolIcon} />
              </div>
              {/* <div className="flex items-center space-x-[1.2rem] lg:hidden">
                {displaySheet.map((item, itemIndex) => {
                  const isActive = currenTub === itemIndex;
                  return (
                    <button
                      onClick={() => setCurrentTub(itemIndex)}
                      className={`flex items-center justify-center w-[4rem] h-[4rem] border-[0.1rem] border-solid rounded-[1.2rem] custom-transition ${
                        isActive ? 'bg-white-100 border-white-300' : 'bg-white-50 border-white-50'
                      }`}
                      key={itemIndex}
                    >
                      <img className={`${!isActive && 'opacity-50'} custom-transition`} src={item?.icon} />
                    </button>
                  );
                })}
              </div> */}
            </div>
          </div>
          <NftBanner mob={true} />
          <div className="flex flex-col lg:space-y-[2.4rem]">
            {maxActiveLevel < 15 && (
              <div className="hidden lg:flex flex-col space-y-[1.6rem] w-full justify-between items-center border-[0.1rem] border-dashed border-white-200 rounded-[2rem] p-[2rem]">
                <div className="flex items-center justify-between space-x-[1rem] w-full">
                  <span className="text-[1.6rem] text-white font-600 leading-[2.24rem]">
                    Level {maxActiveLevel + 1}
                  </span>
                  <div className="flex items-center justify-center bg-white-100 rounded-[2rem] pl-[0.8rem] pr-[0.5rem] h-[2.8rem] space-x-[0.8rem]">
                    <span className="text-[1.4rem] font-500 tracking-[-0.28px] text-white opacity-50 leading-none">
                      Price
                    </span>
                    <span className="text-white text-[1.4rem] font-500 tracking-[-0.28px] leading-none">
                      {Number(PROGRAM_PRICES_WEI[currentMatrixName][maxActiveLevel]) / 1e18}
                    </span>
                    <img className="w-[1.8rem] h-[1.8rem]" src={PolIcon} />
                  </div>
                </div>
                <Button
                  type="purple"
                  loading={transactionInfo.isWaiting}
                  loadingType="white"
                  disabled={transactionInfo.isWaiting || previewUserAddress}
                  onClick={() => upgradeClick()}
                  className="w-full min-w-[8.6rem] rounded-[3rem] shadow-[0px_0px_40px_0px_rgba(152,90,255,0.30)] h-[3.6rem]"
                >
                  <div className="flex items-center justify-center flex-nowrap space-x-[0.4rem] text-[1.4rem] font-500 leading-[1.96rem] text-white">
                    <span>Activate for</span>
                    <div className="flex items-center justify-center">
                      {mobileTotalPrice} <img className="w-[1.6rem] h-[1.6rem] ml-[0.4rem]" src={PolIcon} />
                    </div>
                  </div>
                </Button>
              </div>
            )}
            {currenTub === 0 ? (
              <div className="grid grid-cols-2 gap-[2rem] w-full lg:gap-[1.2rem] lg:grid-cols-1">
                {currentMatrix?.map((item, itemIndex) => {
                  const isFirstAfterActive = itemIndex === maxActiveLevel;
                  const isSecondAfterActive = itemIndex % 2 === 1 && itemIndex === maxActiveLevel + 1;

                  return (
                    <LevelCard
                      setIsNeedToCall={() => updateInfo()}
                      lvl={itemIndex + 1}
                      itemIndex={itemIndex}
                      type={currentMatrixName}
                      maxActiveLevel={maxActiveLevel}
                      isFirstAfterActive={isFirstAfterActive}
                      isSecondAfterActive={isSecondAfterActive}
                      partnersCurrentCycle={checkPartners(item.matrixs[item.matrixs.length - 1])}
                      {...item}
                    />
                  );
                })}
              </div>
            ) : (
              <span className="text-white text-5xl">Будет список</span>
            )}
          </div>
        </div>
        <img className="absolute top-0 z-[0] lg:hidden" src="/images/dashboard/topShadow.webp" />
        <img
          className="absolute top-[-2rem] left-0 z-[0] h-[85rem] hidden lg:flex w-full"
          src="/images/dashboard/topShadowMob.webp"
        />
      </div>
    </BaseLayout>
  );
};
