import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from '../../Modal';
import copyIcon from '../../../assets/icons/modals/partnersPlaceModal/copyIcon.svg';
import linkIcon from '../../../assets/icons/modals/partnersPlaceModal/linkIcon.svg';
import { copy } from '../../../helpers/copy';
import PolIcon from '../../../assets/icons/main/polCoin.svg';
import { PROGRAM_NAMES } from '../../../helpers/constants';
import { shortenAddress } from '../../../helpers/format';
import { Button } from '../../Button';
import { useSelector } from 'react-redux';
import { getUser } from '../../../store/userSlice/selectors';
import { UserIdBlock } from '../../UserIdBlock';

export const PartnersPlaceModal = ({ level = 1, type = '', matrixs = [], openedModal, handleCloseModal }) => {
  const [currentCycle, setCurrentCycle] = useState(0);
  const maxCycle = matrixs.length;

  const currentUser = useSelector(getUser);

  useEffect(() => {
    setCurrentCycle(maxCycle);
  }, [maxCycle]);

  const currentRecycle = useMemo(() => {
    if (type === PROGRAM_NAMES.X2) {
      return [matrixs[currentCycle - 1]?.place1, matrixs[currentCycle - 1]?.place2];
    }
    if (type === PROGRAM_NAMES.X4) {
      return [
        matrixs[currentCycle - 1]?.place1,
        matrixs[currentCycle - 1]?.place2,
        matrixs[currentCycle - 1]?.place3,
        matrixs[currentCycle - 1]?.place4,
        matrixs[currentCycle - 1]?.place5,
        matrixs[currentCycle - 1]?.place6,
      ];
    }
    return null;
  }, [currentCycle, matrixs, type]);

  const cycleDown = () => {
    if (currentCycle > 1) {
      setCurrentCycle(currentCycle - 1);
    }
  };

  const cycleUp = () => {
    if (currentCycle < maxCycle) {
      setCurrentCycle(currentCycle + 1);
    }
  };

  const refLink = `https://polynetica.com/r/${currentUser.refNumber}`;

  const refTitle = (
    <span className="text-[1.4rem] leading-[1.96rem] font-500 text-white">
      <span className="font-400 text-white-500">This place is empty</span> invite partners and earn
    </span>
  );

  const refButton = (
    <Button onClick={() => copy(refLink)} className="rounded-full h-[4rem] lg:w-full" type="purple">
      <span className="text-[1.4rem] font-500 leading-[1.96rem] text-white">Copy refferal link</span>
    </Button>
  );

  return (
    <Modal className="!mb-[0rem]" isOpened={openedModal} onClose={handleCloseModal}>
      <div
        className={`w-[90.7rem] !relative flex flex-col items-start justify-start ${
          currentCycle > 0 ? 'space-y-[4.8rem]' : 'space-y-[2.4rem] partnersPlaceModal lg:h-[39rem]'
        } p-[4rem] modal-styles lg:w-full lg:px-[2rem] lg:rounded-none lg:rounded-t-[2rem] lg:space-y-[2.4rem] lg:border-none `}
      >
        <span className="text-white text-[3.2rem] font-600 leading-[4.48rem] tracking-[-0.64px] lg:text-[2.4rem] lg:leading-[3.36rem] lg:tracking-[-0.48px]">
          <span className="text-[#985AFF]">Level {level}</span> partners place
        </span>
        {currentCycle > 0 ? (
          <>
            <div className="flex flex-col space-y-[1.2rem] w-full overflow-auto hide-scroll">
              {currentRecycle.map((item, itemIndex) => {
                return (
                  <div
                    className={`flex items-center justify-between w-full px-[2rem] h-[7rem] rounded-[2rem] border-[0.1rem] border-solid lg:p-[1.6rem] lg:flex-col lg:h-full ${
                      currentRecycle[itemIndex] ? ' border-white-100' : ' border-primary bg-[rgba(152,90,255,0.10)]'
                    }`}
                    key={itemIndex}
                  >
                    <div className="flex items-center justify-center space-x-[2.1rem] pr-[2rem] lg:hidden ">
                      <span className="text-white text-[1.8rem] font-600 leading-[2.52rem]">{itemIndex + 1}</span>
                      <div className="h-[3rem] w-[0.1rem] bg-white-100" />
                    </div>
                    {currentRecycle[itemIndex] ? (
                      <>
                        <div className="flex w-full items-center justify-between hidden lg:flex lg:pb-[1.2rem]">
                          {/* <span className="text-[1.4rem] text-white-500 font-400 leading-[1.96rem]">01.06.2021</span> */}
                          <span className="text-[1.4rem] text-white-500 font-400 leading-[1.96rem] lg:text-primary">
                            {item?.status}
                          </span>
                        </div>
                        <div className="flex w-full items-center justify-between">
                          {/* <span className="text-[1.4rem] text-white-500 font-400 leading-[1.96rem] lg:hidden">
                            01.06.2021
                          </span> */}
                          <UserIdBlock id={item?.user?.internalId} />
                          <span className="text-[1.4rem] text-white-500 font-400 leading-[1.96rem] lg:hidden">
                            {!!item?.realReceiver ? item?.status : 'restart'}
                          </span>
                          <div className="flex items-center space-x-[1rem] lg:space-x-[0.4rem]">
                            <span className="text-[1.4rem] text-white lg:text-[1.2rem]">
                              {shortenAddress(item?.user?.id)}
                            </span>
                            <button className="custom-transition hover:opacity-70" onClick={() => copy(item?.user?.id)}>
                              <img className="lg:w-[1.6rem] lg:h-[1.6rem]" src={copyIcon} />
                            </button>
                            <a className="custom-transition hover:opacity-70 cursor-pointer lg:hidden">
                              <img className="" src={linkIcon} />
                            </a>
                          </div>
                          <div className="flex items-center justify-end space-x-[1rem] lg:space-x-[0.4rem] w-[50px]">
                            <span className="text-[1.4rem] text-white font-600 lg:text-[1.2rem] ">
                              {Number(item?.value) / 1e18}
                            </span>
                            <img className="w-[2rem] h-[2rem] lg:h-[1.6rem] lg:w-[1.6rem]" src={PolIcon} />
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="flex w-full items-center justify-between text-2xl text-white lg:flex-col lg:w-full lg:space-y-[3.2rem]">
                        {refTitle}
                        {refButton}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>

            <div className="flex flex-col space-y-[2.4rem] w-full">
              <span className="text-[1.6rem] text-white-500 font-400">Restart</span>
              <div className="flex items-center space-x-[1.2rem] w-full">
                <Button
                  type="gray"
                  onClick={() => cycleDown()}
                  className={`flex items-center justify-center rounded-[1.2rem] w-full h-[4.8rem] ${
                    !(currentCycle - 1 > 0) && 'opacity-50 pointer-events-none'
                  }`}
                >
                  {currentCycle - 1 > 0 && (
                    <span className="text-[1.6rem] text-white-500 leading-[2.24rem] font-500 lg:text-[1.4rem] lg:leading-[1.96rem]">
                      Restart {currentCycle - 1}
                    </span>
                  )}
                </Button>
                <div className="flex items-center justify-center px-[2rem] h-[4.8rem] rounded-[1.2rem] bg-[#985AFF]">
                  <span className="text-[1.6rem] text-white font-600 lg:text-[1.4rem] lg:leading-[1.96rem]">
                    {currentCycle}
                  </span>
                </div>
                <Button
                  type="gray"
                  onClick={() => cycleUp()}
                  className={`flex items-center justify-center rounded-[1.2rem] bg-white-50 w-full h-[4.8rem] ${
                    !(currentCycle !== maxCycle) && 'opacity-50 pointer-events-none'
                  }`}
                >
                  {currentCycle !== maxCycle && (
                    <span className="text-[1.6rem] text-white-500 leading-[2.24rem] font-500 lg:text-[1.4rem] lg:leading-[1.96rem]">
                      Restart {currentCycle + 1}
                    </span>
                  )}
                </Button>
              </div>
            </div>
          </>
        ) : (
          <div className="flex flex-col items-start space-y-[4.8rem] w-full lg:justify-between lg:h-full">
            {refTitle}
            {refButton}
          </div>
        )}
      </div>
    </Modal>
  );
};
