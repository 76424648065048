import React, { useState } from 'react';

export const Button = ({ className = '', type = 'orange', children, loading, loadingType, onClick, ...props }) => {
  const [isPressed, setIsPressed] = useState(false);

  const handleMouseDown = () => setIsPressed(true);
  const handleMouseUp = () => setIsPressed(false);

  const typeClasses = {
    purple: `bg-primary rounded-[1rem] text-white hover:bg-[#8133FF]`,
    gray: 'bg-white-50 rounded-[1.2rem] text-white hover:bg-white-100 border-[0.1rem] border-solid border-white-50',
    green: 'bg-green rounded-[1rem] text-[#0C0C0E] hover:bg-[#6FFFA7]',
    orange: 'bg-[#F1A13B] rounded-[1rem] text-[#0C0C0E] hover:bg-[#FFBF59]',
    black: 'bg-[rgba(12,12,14,0.50)] rounded-[1rem] text-white hover:bg-[#1E1E20]',
    white: 'bg-white rounded-[1.2rem] '
  };

  const buttonClass = typeClasses[type] || typeClasses.orange;

  const textSizeClass = isPressed ? 'text-[1rem]' : 'text-[2rem]';

  return (
    <button
      onClick={onClick}
      className={`${buttonClass} flex items-center justify-center custom-transition px-[1.6rem] leading-normal disabled:pointer-events-none disabled:opacity-40 ${className}`}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      {...props}
    >
      {loading ? <span className={`loading-animation__${loadingType}`}></span> : children}
    </button>
  );
};
