import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button } from '../Button';
import ArrowLeft from '../../assets/icons/generalIcons/greyArrow.svg';
import ArrowDownIcon from '../../assets/icons/main/arrowDownMini.svg';

export const ProgramsDropdown = ({ currentMatrixName }) => {
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);

  const dropDownList = [
    {
      title: 'Duo',
      href: 'duo',
    },
    {
      title: 'Quattro',
      href: 'quattro',
    },
  ];

  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsOpenDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="flex items-center space-x-[1.2rem]">
        <Link
          to={`/dashboard?${params.toString()}`}
          className="flex items-center justify-center space-x-[0.4rem] rounded-[2rem] bg-white-50 h-[3.2rem] pl-[0.8rem] pr-[1.2rem] custom-transition hover:bg-white-100"
        >
          <img className="w-[1.6rem] h-[1.6rem]" src={ArrowLeft} />
          <span className="text-[1.4rem] font-400 text-white-500 leading-[3rem]">Back</span>
        </Link>
        <button
          ref={buttonRef}
          onClick={() => setIsOpenDropdown(!isOpenDropdown)}
          className="flex items-center justify-center space-x-[1.2rem]"
        >
          <span className="text-[3rem] text-white font-600 leading-[4rem] tracking-[-0.6px] lg:text-[2.4rem] lg:tracking-[-0.48px] ">
            {currentMatrixName} <span className="lg:hidden">Program</span>
          </span>
          <img
            className={`lg:w-[1.8rem] ${isOpenDropdown ? 'rotate-180' : 'rotate-0'} custom-transition`}
            src={ArrowDownIcon}
            alt=""
          />
        </button>
      </div>
      <div
        ref={dropdownRef}
        className={`absolute top-[9rem] left-[6rem] lg:top-[6rem] lg:left-[8rem] ${
          isOpenDropdown ? 'opacity-100 dropdown-animation z-[9] flex' : 'hidden'
        } flex flex-col w-[28.1rem] space-y-[1.2rem] p-[1.2rem] rounded-[2rem] bg-[#262628] shadow-[0px_10px_20px_0px_rgba(0,0,0,0.10)] lg:w-[20rem]`}
      >
        {dropDownList.map((item, itemIndex) => {
          const isActive = location.pathname === `/dashboard/${item.href}`;
          return (
            <Link className="w-full" to={`/dashboard/${item?.href}?${params.toString()}`} onClick={() => setIsOpenDropdown(false)}>
              <Button
                type="purple"
                className={`h-[4.8rem] !justify-start w-full lg:h-[4rem] ${!isActive && 'bg-transparent'}`}
                key={itemIndex}
              >
                <span className="text-[1.6rem] text-white font-500 lg:text-[1.4rem]">Program {item?.title}</span>
              </Button>
            </Link>
          );
        })}
      </div>
    </>
  );
};
