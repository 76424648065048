import React, { useEffect } from 'react';
import { Button } from '../Button';
import previewIcon from '../../assets/icons/dashboard/previewIcon.svg';
import { PreviewModModal } from '../Modals/PreviewModModal';
import { useModal } from '../../helpers/hooks/useModal';
import { useSelector } from 'react-redux';
import { getUser } from '../../store/userSlice/selectors';
import { usePreviewMode } from '../../helpers/hooks/useCheckPreviewMode';

export const PreviewModeButton = ({ func = null }) => {
  const { previewUserAddress } = usePreviewMode();
  const currentUser = useSelector(getUser);
  const isPreviewMode = !!previewUserAddress;
  const {
    openedModal: openedPreviewModModal,
    onOpen: onOpenPreviewModModal,
    onClose: onClosePreviewModModal,
  } = useModal();

  const open = () => {
    onOpenPreviewModModal();
  };

  const closePreviewModal = () => {
    onClosePreviewModModal();
    if (typeof func === 'function') {
      func();
    }
  };

  return (
    <>
      <Button
        onClick={() => open()}
        type="purple"
        className="h-[4rem] py-[0.8rem] px-[1.6rem] text-[1.6rem] space-x-[0.5rem] lg:justify-between lg:w-full"
      >
        <div className="flex items-center justify-start">
          <span>Preview mode</span>
          <div className="h-full border-0.5 border-l border-white-300 lg:hidden"></div>
        </div>
        {isPreviewMode && (
          <div className="flex items-center justify-end space-x-[1rem]">
            <span>id{currentUser?.internalId}</span>
            <img src={previewIcon} className="h-[2rem] w-[2rem]" alt="" />
          </div>
        )}
      </Button>
      <PreviewModModal openedModal={openedPreviewModModal} handleCloseModal={closePreviewModal} />
    </>
  );
};
