import React from 'react';
import { BaseLayout } from 'layouts';
import { Settings } from '../../features/profile/Settings';
import { Preview } from '../../features/profile/Preview';

export const Profile = () => {
  return (
    <BaseLayout className="lg:!bg-[#0C0C0E]">
      <div className="relative flex space-x-[2rem] w-full p-[2rem] items-center profile-bg rounded-[3rem] lg:bg-transparent lg:space-x-0 lg:p-[1.6rem] lg:pt-[2rem] overflow-hidden">
        <Settings />
        <Preview />
        <img className="hidden lg:flex absolute left-0 top-0 w-full" src="/images/stats/mobShadow.webp" />
      </div>
    </BaseLayout>
  );
};
