import React, { useState, useEffect } from 'react';
import PolIcon from '../../../../assets/icons/main/polCoin.svg';
import { useEnergyNft } from '../../../../helpers/hooks/useEnergyNft';
import { useWeb3React } from '@web3-react/core';
import { useCallTransaction } from '../../../../helpers/hooks/useCallTransaction';
import { Button } from '../../../../components/Button';

export const EnergyCard = ({ choosedNftId, balanceNft, updateBalance }) => {
  const { account } = useWeb3React();
  const { stakeNfts, isApprovalNft, checkNftApprove, approveNft, setIsApprovalNft } = useEnergyNft();
  const [count, setCount] = useState(1);

  useEffect(() => {
    setCount(1);
  }, []);

  const { onCallTransaction, transactionInfo, resetTransactionInfo } = useCallTransaction();
  const {
    onCallTransaction: onCallTransactionStake,
    transactionInfo: transactionInfoStake,
    resetTransactionInfo: resetTransactionInfoStake,
  } = useCallTransaction();

  const clickApprove = async () => {
    try {
      const res = await approveNft();

      if (res) {
        onCallTransaction(res);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const clickStake = async (choosedNftId, count) => {
    try {
      const res = await stakeNfts(choosedNftId, count);

      if (res) {
        onCallTransactionStake(res);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (account) {
      setIsApprovalNft(null);
      checkNftApprove();
    }
  }, [account]);

  useEffect(() => {
    if (transactionInfo.isSuccess) {
      setIsApprovalNft(true);
      resetTransactionInfo();
    }
  }, [transactionInfo]);

  useEffect(() => {
    if (transactionInfoStake.isSuccess) {
      updateBalance();
      resetTransactionInfoStake();
    }
  }, [transactionInfoStake]);

  const upCount = () => {
    if (count < balanceNft[choosedNftId - 1]) {
      setCount((prev) => prev + 1);
    }
  };

  const downCount = () => {
    if (count > 1) {
      setCount((prev) => prev - 1);
    }
  };

  return (
    <div className="flex flex-col space-y-[1rem] p-[1rem] rounded-[3rem] border-[0.1rem] border-white-50 border-solid energy-card-bg backdrop-blur-[1.5rem] max-w-[47.2rem] shrink-0 lg:max-w-full lg:items-center">
      <img
        src={choosedNftId < 16 ? '/energynft/img/duo.png' : '/energynft/img/quattro.png'}
        className="w-[45.4rem] h-[45.4rem] rounded-[2rem] lg:w-[33.8rem] lg:h-[33.8rem]"
      />
      <div className="flex flex-col space-y-[3.6rem] p-[1rem] lg:space-y-[2.4rem] lg:w-full">
        <div className="flex flex-col space-y-[1.2rem]">
          <div className="flex items-center space-x-[0.8rem] text-[2.4rem] lg:text-[1.8rem]">
            <span className="text-white-500 font-500">Energy</span>
            <span className="text-white font-600">Restart lvl {choosedNftId}</span>
          </div>
          <span className="text-[1.4rem] text-white-500 font-400 leading-[1.96rem]">
            Stake this NFT to earn passive rewards for 7 days.
          </span>
        </div>
        {2 > 0 && (
          <div className="flex flex-col space-y-[2.4rem]">
            <div className="flex items-center justify-between w-full">
              <span className="text-[1.6rem] font-500 text-white-400 lg:text-[1.4rem]">Count</span>
              <div className="flex items-center space-x-[1.2rem] bg-white-40 rounded-full p-[0.4rem]">
                <button
                  disabled={count === 1}
                  onClick={() => downCount()}
                  className="custom-transition relative flex items-center justify-center h-[2.4rem] w-[2.4rem] bg-white-40 rounded-full hover:opacity-60 disabled:opacity-20"
                >
                  <span className="absolute top-[45%] left-1/2 -translate-x-1/2 -translate-y-1/2 text-[2rem] text-white ">
                    -
                  </span>
                </button>
                <div className="min-w-[3rem] flex items-center justify-center">
                  <span className="text-white text-[2rem] font-500">{count}</span>
                </div>
                <button
                  disabled={count === balanceNft[choosedNftId - 1]}
                  onClick={() => upCount()}
                  className="custom-transition relative flex items-center justify-center h-[2.4rem] w-[2.4rem] bg-white-40 rounded-full hover:opacity-60 disabled:opacity-20"
                >
                  <span className="absolute top-[45%] left-1/2 -translate-x-1/2 -translate-y-1/2 text-[2rem] text-white ">
                    +
                  </span>
                </button>
              </div>
            </div>
            <Button
              type="purple"
              loadingType="white"
              loading={transactionInfoStake.isWaiting || transactionInfo?.isWaiting}
              onClick={isApprovalNft ? () => clickStake(choosedNftId, count) : () => clickApprove()}
              className="h-[4.8rem] w-full"
            >
              {isApprovalNft ? (
                <span className="text-[1.6rem] font-500 text-white">Stake now</span>
              ) : (
                <span className="text-[1.6rem] font-500 text-white">Activate Energy form</span>
              )}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
