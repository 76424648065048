import React from 'react';
import { menuList } from 'helpers/menu';
import { Link } from 'react-router-dom';

export const Menu = ({ onNavigate, onClose, isOpenedBurger, setIsOpenedBurger }) => {
  return (
    <div className="flex flex-col w-full">
      {menuList.map((item, itemIndex) => {
        const isActive = location.pathname.split('/')?.[1] === item?.key;
        return (
          <Link
            onClick={onClose}
            to={item?.href + location.search}
            className={`flex items-center space-x-[1.6rem] py-[1rem] px-[1.2rem] rounded-[1.2rem] w-full custom-transition group hover:bg-white-40 ${
              isActive ? 'bg-white-80 text-white' : 'text-white opacity-50 '
            }`}
            key={itemIndex}
          >
            <img className="w-[2.4rem] h-[2.4rem]" src={isActive ? item?.activeIcon : item?.icon} />
            <span className="text-[1.6rem] font-500">{item?.title}</span>
          </Link>
        );
      })}
    </div>
  );
};
