import React, { useEffect, useState, useRef } from 'react';
import { StakingForm } from '../../features/staking/Form';
import { StakingList } from '../../features/staking/List';
import { StakingLayout } from '../../layouts/StakingLayout';
import { useWeb3React } from '@web3-react/core';
import { useStaking } from '../../helpers/hooks/useStaking';
import IndividualsIcon from '../../assets/icons/staking/aboutIcon.svg';
import { AboutStakingModal } from '../../components/Modals/AboutStakingModal';
import { useModal } from '../../helpers/hooks/useModal';
import { Trans } from 'react-i18next';

export const StakingPage = () => {
  const { account } = useWeb3React();
  const { getUserDeposits, userDeposits } = useStaking();

  const { openedModal, onOpen, onClose } = useModal();

  useEffect(() => {
    if (account) {
      getUserDeposits();
    }
  }, [account]);

  return (
    <StakingLayout>
      <div className="mt-[9rem] text-white text-[4.8rem] font-600 space-grotesk lg:text-[2.4rem] lg:mt-[3.5rem]">
        <div className="flex items-center justify-between lg:flex-col lg:items-start lg:space-y-[1rem]">
          <span>
            <span className="opacity-40">Polynetica</span> Staking
          </span>
          <button
            onClick={() => onOpen()}
            className="flex items-center justify-center px-[1.6rem] space-x-[1rem] border-[0.1rem] border-solid border-white-50 bg-white-40 h-[4rem] rounded-[1.2rem] custom-transition hover:bg-white-100 hover:border-white-100 lg:space-x-[0.8rem]"
          >
            <span className="text-[1.6rem] text-white-500 font-500 tracking-[-0.48px] lg:text-[1.2rem] lg:whitespace-nowrap">
              <Trans i18nKey="site.staking.aboutStaking">About Staking</Trans>
            </span>
            <img className="w-[1.5rem] h-[1.5rem]" src={IndividualsIcon} />
          </button>
        </div>
      </div>
      <StakingForm getUserDeposits={getUserDeposits} />
      <StakingList userDeposits={userDeposits} getUserDeposits={getUserDeposits} />
      <AboutStakingModal openedModal={openedModal} handleCloseModal={onClose} />
    </StakingLayout>
  );
};
