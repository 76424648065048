import React from 'react';

export const StagesSocialMining = () => {
  const stagesList = [
    {
      title: 'Passive income',
      desc1: 'Quattro allows you to earn money without personal invitations due to overflows from the entire system',
      desc2: 'Overflows come from all participants who register at Quattro',
      desc3:
        ' Duo works differently – there are no overflows from the system, but 100% of the income comes from your referrals',
    },
    {
      title: 'Active earnings',
      desc1: 'If you build a team, you earn extra money in both Duo and Quattro',
      desc2:
        'You choose a strategy of either completely passive income, through Quattro overflows, or fast cycles in Duo',
      desc3: 'Energy NFT Rewards with Native Ecosystem Token ($POLLY)',
    },
  ];
  return (
    <div className="flex flex-col relative space-y-[10.2rem] items-center justify-start w-full pt-[10rem] lg:max-w-full stagesSocialMining-bg lg:space-y-[8rem] lg:pt-[2rem]">
      <div className="flex flex-col items-start w-full max-w-[120rem] lg:max-w-full">
        <span className="target-title-color text-[9rem] font-600 leading-[9.9rem] max-w-[99.1rem] lg:text-[3.2rem] lg:leading-[3.52rem] lg:px-[2rem]">
          Two-Stage Social Mining Framework
        </span>
      </div>
      <div className="flex flex-col items-center relative h-[120rem] z-[1] lg:h-[80rem]">
        {stagesList.map((item, itemIndex) => {
          return (
            <>
              <div
                className={`${
                  itemIndex === 0
                    ? 'rotate-[-5.6deg] z-[1] right-[-18rem] border-[#FCBEFF] stagesSocialMining-shadow-purple lg:right-auto'
                    : 'rotate-[15deg] left-[-8rem] top-[57rem] border-[#F1A13B] stagesSocialMining-shadow-orang lg:left-auto lg:top-[42rem]'
                } flex flex-col items-center space-y-[5.6rem] w-[62rem] absolute p-[4rem] rounded-[4.6rem] border-[0.3rem] border-solid bg-[#0C0C0E] lg:space-y-[2rem] lg:rounded-[2.4rem] lg:pt-[2rem] lg:p-[1.6rem] lg:w-[30.9rem] lg:border-[0.2rem]`}
              >
                <span className="text-[4.6rem] text-white font-600 leading-none lg:text-[2.4rem]">{item?.title}</span>
                <div className="flex flex-col space-y-[1.8rem] h-full lg:space-y-[1rem]">
                  <div
                    className={`${
                      itemIndex === 0 ? 'bg-[#985AFF]' : 'bg-[#F1A13B]'
                    } flex items-center justify-center py-[2rem] px-[3rem] h-full rounded-[3rem] lg:py-[1.6rem] lg:px-[1.6rem] lg:rounded-[1.4rem]`}
                  >
                    <span className="text-[2rem] text-white font-500 leading-[3.2rem] lg:text-[1.4rem] lg:leading-[2.24rem]">
                      {item?.desc1}
                    </span>
                  </div>
                  <div
                    className={`${
                      itemIndex === 0 ? 'bg-[#985AFF]' : 'bg-[#F1A13B]'
                    } flex items-center justify-center py-[2rem] px-[3rem] h-full rounded-[3rem] lg:py-[1.6rem] lg:px-[1.6rem] lg:rounded-[1.4rem]`}
                  >
                    <span className="text-[2rem] text-white font-500 leading-[3.2rem] lg:text-[1.4rem] lg:leading-[2.24rem]">
                      {item?.desc2}
                    </span>
                  </div>
                  <div
                    className={`${
                      itemIndex === 0 ? 'bg-[#985AFF]' : 'bg-[#F1A13B]'
                    } flex items-center justify-center py-[2rem] px-[3rem] h-full rounded-[3rem] lg:py-[1.6rem] lg:px-[1.6rem] lg:rounded-[1.4rem]`}
                  >
                    <span className="text-[2rem] text-white font-500 leading-[3.2rem] lg:text-[1.4rem] lg:leading-[2.24rem]">
                      {item?.desc3}
                    </span>
                  </div>
                </div>
                <div
                  className={`${
                    itemIndex === 0
                      ? 'rotate-[-21.696deg] left-[-3rem] top-[-12rem] z-[11] bg-[#985AFF] stagesSocialMining-counter-shadow-purple lg:rotate-[-15deg] lg:top-[-5rem] lg:left-[-1rem]'
                      : 'rotate-[10.518deg] right-[2rem] top-[-15rem] bg-[#F1A13B] stagesSocialMining-counter-shadow-orange lg:top-[-7rem]'
                  } absolute flex items-center justify-center w-[12.6rem] h-[12.6rem] rounded-[3.2rem] border-[0.3rem] border-solid border-white-500 lg:w-[6.4rem] lg:h-[6.4rem] lg:rounded-[1.6rem] lg:border-[0.13rem]`}
                >
                  <span className="stagesSocialMining-counter-number text-[11rem] font-700 leading-none lg:text-[4.8rem]">
                    {itemIndex + 1}
                  </span>
                </div>
              </div>
            </>
          );
        })}
      </div>
      <img
        className="absolute right-0 top-[-80rem] z-[0] lg:hidden"
        src="/images/target/StagesSocialMining/orangeShadow.webp"
      />
    </div>
  );
};
