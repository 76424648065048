import React, { useEffect, useMemo, useState, useRef } from 'react';
import mobPolyicon from '../../assets/icons/header/mobPolyicon.svg';
import MenuIcon from '../../assets/icons/main/menu.svg';
import CloseIcon from '../../assets/icons/main/closeWhite.svg';
import WalletIcon from '../../assets/icons/main/wallet.svg';
import BlackWalletIcon from '../../assets/icons/generalIcons/blackWalletIcon.svg';
import { BurgerMenu } from './BurgerMenu';
import { ButtonLanding } from '../Landing/ButtonLanding';
import { Trans } from 'react-i18next';
import { useWeb3React } from '@web3-react/core';
import { useModal } from '../../helpers/hooks/useModal';
import { ActivateModal } from '../Modals/ActivateModal';

export const MobHeader = ({ className }) => {
  const [isOpenedBurger, setIsOpenedBurger] = useState(false);

  const OPENED_TYPE = {
    WALLET: 'wallet',
    MENU: 'menu',
  };

  const [openedType, setOpenedType] = useState(OPENED_TYPE.MENU);

  const openWallet = () => {
    setOpenedType(OPENED_TYPE.WALLET);
    setIsOpenedBurger((value) => !value);
  };

  const toggleMenu = () => {
    setOpenedType(OPENED_TYPE.MENU);
    setIsOpenedBurger((value) => !value);
  };

  const { account } = useWeb3React();

  const { openedModal, onOpen, onClose } = useModal();

  const connectWalletBtn = () => {
    return (
      <ButtonLanding
        onClick={() => onOpen()}
        type="white"
        className="!h-[3.2rem] rounded-full !border-white whitespace-nowrap !text-[1.4rem] !leading-normal !pr-[1.2rem] !pl-[1.4rem]"
      >
        <Trans i18nKey="main.connectWallet"></Trans>
        <img className="ml-[0.8rem]" src={BlackWalletIcon} />
      </ButtonLanding>
    );
  };

  const walletState = useMemo(() => {
    if (account) {
      return (
        <div className="flex items-center space-x-[2.4rem]">
          <div className="flex items-center justify-center space-x-[1rem]">
            <button
              onClick={() => openWallet()}
              className="w-[2.4rem] h-[2.4rem] items-center justify-center space-y-[0.4rem]"
            >
              <img src={WalletIcon} alt="Wallet" />
            </button>
          </div>
          <button
            onClick={toggleMenu}
            className="w-[2.4rem] h-[2.4rem] flex items-center justify-center space-y-[0.4rem]"
          >
            <img src={isOpenedBurger ? CloseIcon : MenuIcon} alt="" />
          </button>
        </div>
      );
    } else {
      return connectWalletBtn();
    }
  }, [account, isOpenedBurger]);

  return (
    <div
      className={`z-[111111] hidden lg:flex fixed top-0 px-[1.6rem] flex-col lg:space-y-[2.4rem] py-[2rem] bg-[linear-gradient(0deg,rgba(255,255,255,0.04)_0%,rgba(255,255,255,0.04)_100%),#020204] z-[11] backdrop-blur-[5rem] w-full ${className} ${
        isOpenedBurger ? 'h-screen items-start pt-[2rem]' : 'h-[6.4rem] items-center'
      }`}
    >
      <div className="flex items-center justify-between w-full">
        <img src={mobPolyicon} alt="Logo" />
        {walletState}
      </div>
      {isOpenedBurger && (
        <div className="w-full h-full">
          <BurgerMenu
            openedType={openedType}
            onClose={() => setIsOpenedBurger(false)}
            setIsOpenedBurger={setIsOpenedBurger}
            isOpenedBurger={isOpenedBurger}
          />
        </div>
      )}
      <ActivateModal openedModal={openedModal} handleCloseModal={onClose} />
    </div>
  );
};
