import { formatUnits, parseUnits } from '@ethersproject/units';

export const increaseByPercent = (number, percent = 50) => {
  const onePercent = number.div(100);

  return number.add(onePercent.mul(percent));
};
export function toWei(value) {
  return parseUnits(String(value), 'ether');
}

export const bigIntToInt = (value) => {
  if (!!Number(value)) {
    return parseInt(value) / 1e18;
  }
};

export function fromWei(balance, unitName = 'ether') {
  return formatUnits(String(balance), unitName);
}

export const round = (value, nums = 1) => {
  const rounded = Math.round(value * 10) / 10;

  if (rounded % 1 === 0) {
    return rounded.toString();
  } else {
    return rounded.toFixed(nums);
  }
}
