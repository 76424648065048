import React from 'react';
import PolIcon from '../../../../assets/icons/main/polCoin.svg';
import { useSelector } from 'react-redux';
import { getUser } from '../../../../store/userSlice/selectors';
import { round } from '../../../../helpers/numbers';
import GreenArrow from '../../../../assets/icons/dashboard/greenArrow.svg';
import { getUnixTime } from 'date-fns';
import { bigIntToInt } from '../../../../helpers/numbers';

export const Profit = () => {
  const currentUser = useSelector(getUser);
  const totalProfit = round((Number(currentUser?.totalRewardX2) + Number(currentUser?.totalRewardX4)) / 1e18);

  const reward24X2 =
    !!currentUser?.levelsX2 &&
    currentUser?.levelsX2?.reduce((result, item) => {
      let isTodayReward = Number(item?.lastRewardDay) === Math.floor(getUnixTime(new Date()) / (60 * 60 * 24));
      let temp = isTodayReward ? bigIntToInt(item?.reward24) || 0 : 0;
      return result + temp;
    }, 0);

  const reward24X4 =
    !!currentUser?.levelsX4 &&
    currentUser?.levelsX4?.reduce((result, item) => {
      let isTodayReward = Number(item?.lastRewardDay) === Math.floor(getUnixTime(new Date()) / (60 * 60 * 24));
      let temp = isTodayReward ? bigIntToInt(item?.reward24) || 0 : 0;
      return result + temp;
    }, 0);

  const totalReward24 = round(reward24X2 + reward24X4);

  return (
    <div className="relative flex items-center h-full overflow-hidden rounded-[2rem] dashboard-profits-bg">
      <div className="flex items-center w-full h-full p-[4rem] dashboard-block-styles lg:p-[2rem]">
        <div className="flex flex-col space-y-[1.2rem]">
          <span className="text-[1.6rem] text-white-500 font-500">Total profits</span>
          <div className="flex items-center space-x-[1.6rem]">
            <span className="text-[3.2rem] font-700 text-white lg:text-[3.2rem]">{totalProfit}</span>
            <img className="w-[3.2rem] h-[3.2rem] lg:w-[3.2rem] lg:h-[3.2rem]" src={PolIcon} />
          </div>
          {totalReward24 > 0 && (
            <div className="flex items-center justify-start space-x-[0.5rem]">
              <img src={GreenArrow} />
              <span className="text-[#51F689] text-[1.4rem]">{totalReward24}</span>
            </div>
          )}
        </div>
      </div>
      <img
        className="absolute right-0 bottom-0 w-[31rem] lg:w-[22rem] z-[1]"
        src={`/images/dashboard/profile/profit/pollyPlanet.webp`}
      />
      <img className="hidden lg:flex absolute z-[0] h-full lg:right-0" src="/images/dashboard/profile/profit/bg.webp" />
    </div>
  );
};
