import React, { useState } from 'react';
import { useGetContract } from './useGetContract';
import { DEFAULT_GAS_LIMIT, ContractNames, PROGRAM_NAMES, PROGRAM_PRICES_WEI, PROGRAM_FEE_WEI } from '../constants';
import { increaseByPercent } from '../numbers';
import { useWeb3React } from '@web3-react/core';
import { callNotification } from '../notification';
import { parseErrorToUserReadableMessage } from '../format';
import useTokenPrice from './useTokenPrice';
import config from '../config';

export const useUpgradeLvl = () => {
  const [isLoadingUpgrade, setIsLoadingUpgrade] = useState(false);
  const { getContract } = useGetContract();
  const { account, provider } = useWeb3React();
  const { fetchTokenAmount } = useTokenPrice();

  const upgradeLvl = async (levelsX2 = [], levelsX4 = []) => {
    if (!isLoadingUpgrade) {
      setIsLoadingUpgrade(true);
      let priceX2 = 0;
      let priceX4 = 0;
      let feeX2 = 0;
      let feeX4 = 0;

      if (levelsX2.length) {
        priceX2 = levelsX2.reduce((acc, item) => {
          if (!!PROGRAM_PRICES_WEI[PROGRAM_NAMES.X2][item - 1]) {
            return (acc =
              acc + PROGRAM_PRICES_WEI[PROGRAM_NAMES.X2][item - 1] + PROGRAM_FEE_WEI[PROGRAM_NAMES.X2][item - 1]);
          }
          return acc;
        }, 0);

        feeX2 = levelsX2.reduce((acc, item) => {
          if (!!PROGRAM_PRICES_WEI[PROGRAM_NAMES.X2][item - 1]) {
            return (acc = acc + PROGRAM_FEE_WEI[PROGRAM_NAMES.X2][item - 1]);
          }
          return acc;
        }, 0);
      }

      if (levelsX4.length) {
        priceX4 = levelsX4.reduce((acc, item) => {
          if (!!PROGRAM_PRICES_WEI[PROGRAM_NAMES.X4][item - 1]) {
            return (acc =
              acc + PROGRAM_PRICES_WEI[PROGRAM_NAMES.X4][item - 1] + PROGRAM_FEE_WEI[PROGRAM_NAMES.X4][item - 1]);
          }
          return acc;
        }, 0);

        feeX4 = levelsX4.reduce((acc, item) => {
          if (!!PROGRAM_PRICES_WEI[PROGRAM_NAMES.X4][item - 1]) {
            return (acc = acc + PROGRAM_FEE_WEI[PROGRAM_NAMES.X4][item - 1]);
          }
          return acc;
        }, 0);
      }

      const totalPrice = BigInt(priceX2 + priceX4);

      try {
        const contract = await getContract(ContractNames.ROUTER);
        const feeForContract = ((feeX2 + feeX4) / 1e18) * 0.45 * 0.9;
        let gasEstimate = 0;
        let minPollyReceive = 0;
        if (config.stand === 'prod') {
          const minPolly = await fetchTokenAmount(config.contractPollyPool, provider, feeForContract);
          minPollyReceive = BigInt(Math.floor(minPolly));

          gasEstimate = await contract.estimateGas.buyLevelsX2X4(account, levelsX2, levelsX4, minPollyReceive, {
            value: totalPrice,
          });
        }

        return await contract.buyLevelsX2X4(account, levelsX2, levelsX4, minPollyReceive, {
          value: totalPrice,
          gasLimit: parseInt(gasEstimate) ? increaseByPercent(gasEstimate) : DEFAULT_GAS_LIMIT,
        });
      } catch (e) {
        callNotification({ type: 'error', message: parseErrorToUserReadableMessage(e) });
        return Promise.reject(e);
      } finally {
        setIsLoadingUpgrade(false);
      }
    }
  };

  return {
    upgradeLvl,
    isLoadingUpgrade,
  };
};
