import React from 'react';
import { LanguageSelector } from 'components';
import { SocialLinksComp } from '../../../components/SocialsListComp';

export const Footer = ({ className }) => {
  return (
    <div
      className={`mx-auto max-w-[136rem] lg:max-w-full flex justify-between w-full mt-[5.5rem] mb-[4rem] lg:flex-col-reverse lg:items-start ${className}`}
    >
      <div className="flex items-center space-x-[0.4rem] text-[1.4rem] font-400 lg:space-x-0 lg:pt-[2rem]">
        <span className="text-white-500">© 2025 Polynetica. All rights reserved.</span>
        {/* <span className="text-white">Disclaimer</span> */}
      </div>
      <div className="flex items-center justify-center space-x-[6rem] lg:flex-col lg:space-y-[2rem] lg:items-start lg:space-x-0">
        <LanguageSelector />
        <SocialLinksComp />
      </div>
    </div>
  );
};
