import React, { useState } from 'react';
import { Menu } from './Sections/Menu';
import { Wallet } from './Sections/Wallet';
import { Trans } from 'react-i18next';
import { LanguageSelector } from 'components';
import { PreviewModeButton } from '../../PreviewModeButton';

const BURGER_SECTIONS = {
  WALLET: 'wallet',
  MENU: 'menu',
};

export const BurgerMenu = ({
  onClose,
  openedType = BURGER_SECTIONS.MENU,
  onNavigate,
  isOpenedBurger,
  setIsOpenedBurger,
}) => {
  const [currentTab, setCurrentTab] = useState(openedType);

  const burgerList = [
    {
      title: <Trans i18nKey="main.wallet">Wallet</Trans>,
      key: BURGER_SECTIONS.WALLET,
    },
    {
      title: <Trans i18nKey="main.menu">Menu</Trans>,
      key: BURGER_SECTIONS.MENU,
    },
  ];

  const renderTabContent = () => {
    switch (currentTab) {
      case BURGER_SECTIONS.WALLET:
        return <Wallet onClose={onClose} />;
      case BURGER_SECTIONS.MENU:
        return <Menu onNavigate={onNavigate} isOpenedBurger={isOpenedBurger} setIsOpenedBurger={setIsOpenedBurger} />;
      default:
        return null;
    }
  };

  return (
    <>
      <div className="hidden lg:flex pt-[1.2rem] flex-1 w-full h-full flex lg:flex-col justify-start items-start space-y-[2.4rem] w-full z-[1111] pb-[4rem]">
        <div className="flex-1 flex flex-col w-full space-y-[2.4rem]">
          <div className="flex p-[0.4rem] bg-white-80 rounded-[1.2rem] h-[4.8rem] shrink-0 w-full">
            {burgerList.map((item) => {
              const isActive = currentTab === item.key;
              return (
                <button
                  onClick={() => {
                    setCurrentTab(item.key);
                  }}
                  className={`flex items-center justify-center w-full text-white ${
                    isActive ? 'bg-[rgba(2,2,4,0.50)]' : 'opacity-50'
                  } rounded-[0.8rem]`}
                  key={item.key}
                >
                  <span className="text-[1.6rem] font-500">{item?.title}</span>
                </button>
              );
            })}
          </div>
          {openedType === BURGER_SECTIONS?.MENU && <PreviewModeButton func={() => onClose()} />}
          {renderTabContent()}
        </div>
      </div>
    </>
  );
};
