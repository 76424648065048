import React from 'react';
import { Modal } from '../../Modal';
import useCalculateRank from '../../../helpers/hooks/useCalculateRank';
import { useSelector } from 'react-redux';
import { getUser } from '../../../store/userSlice/selectors';

export const RankModal = ({ openedModal, handleCloseModal }) => {
  const currentUser = useSelector(getUser);
  const { currentRank, totalXP: currentXP, nextRankXP, progressPercentage } = useCalculateRank(currentUser?.rank);

  return (
    <Modal className="!mb-[0rem]" isOpened={openedModal} onClose={handleCloseModal}>
      <div className="w-[52rem] h-[49.7rem] !relative flex flex-col items-start justify-between space-y-[4.8rem] p-[4rem] rounded-[3rem] border-[0.1rem] border-solid border-white-50 bg-white-50 bg-blend-difference shadow-[0px_20px_50px_0px_rgba(0,0,0,0.40)] backdrop-blur-[5rem] lg:w-full lg:items-center lg:px-[2rem] lg:rounded-none lg:rounded-t-[2rem] lg:space-y-[2.4rem] lg:h-full lg:border-none">
        <div className="flex flex-col items-center space-y-[1.6rem]">
          <span className="text-[3.2rem] text-white font-600 tracking-[-0.64px] lg:text-[2.4rem] lg:tracking-[-0.48px]">
            <span className="text-white-500">Your</span> Rank and XP
          </span>
          <span className="text-[1.4rem] font-400 leading-[1.96rem] text-white-500 text-center max-w-[43rem]">
            Earn XP points and achieve new Ranks to have the best experience with Polynetica
          </span>
        </div>
        <div className="flex flex-col items-center w-full space-y-[4.8rem]  lg:flex-col-reverse lg:space-y-reverse lg:space-y-[2.4rem]">
          <div className="relative flex justify-center items-center w-full lg:w-fit">
            <div className="z-[0] absolute left-[7.7rem] lg:left-[-7rem] flex flex-col items-center justify-start w-[11rem] h-[11rem] border-[0.15rem] border-solid border-white-200 rounded-[2.75rem] bg-[rgba(16,16,17,0.80)] shadow-[0px_11px_41.25px_0px_rgba(16,16,17,0.40)] backdrop-blur-[2.75rem] py-[1.5rem] opacity-40">
              <span className="text-[1.88rem] font-500 leading-none text-white-500">Rank</span>
              <span className="text-[5.5rem] text-white-500 font-600 leading-none">{currentRank - 1}</span>
            </div>
            <div className="z-[1] flex flex-col items-center justify-start w-[14rem] h-[14rem] border-[0.2rem] border-solid border-[#985AFF] rounded-[3.5rem] bg-[rgba(16,16,17)] shadow-[0px_20px_30px_10px_#121214] backdrop-blur-[3.5rem] py-[1.5rem]">
              <span className="text-[2.4rem] font-500 leading-none text-white-500">Rank</span>
              <span className="text-[7rem] text-white font-600 leading-none">{currentRank}</span>
            </div>
            <div className="z-[0] absolute right-[7.7rem] lg:right-[-7rem] flex flex-col items-center justify-start w-[11rem] h-[11rem] border-[0.15rem] border-solid border-white-200 rounded-[2.75rem] bg-[rgba(16,16,17,0.80)] shadow-[0px_11px_41.25px_0px_rgba(16,16,17,0.40)] backdrop-blur-[2.75rem] py-[1.5rem] opacity-40">
              <span className="text-[1.88rem] font-500 leading-none text-white-500">Rank</span>
              <span className="text-[5.5rem] text-white-500 font-600 leading-none">{currentRank + 1}</span>
            </div>
          </div>
          <div className="flex flex-col space-y-[1.2rem] w-full">
            <span className="text-[1.6rem] text-white-500 font-500 leading-[3rem]">XP</span>
            <div className="flex items-center justify-center space-x-[0.4rem] h-[1.2rem]">
              <div style={{ width: progressPercentage + '%' }} className="h-full bg-[#985AFF] rounded-[1.2rem]" />
              <div className="flex-1 h-full bg-white-100 rounded-[2rem]"></div>
            </div>
            <div className="flex w-full justify-between text-[1.6rem] font-600 ">
              <span className="text-white">{currentXP}</span>
              <span className="text-white-500">{nextRankXP}</span>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
