import { useMemo } from 'react';

const useCalculateRank = (totalXP) => {
  // Массив с XP для каждого ранга
  const rankXP = [
    500, 1100, 1800, 2700, 3700, 4900, 6300, 8000, 10000, 12400, 15000, 17900, 21100, 24600, 28500, 32800, 37500, 42700,
    48400, 54700, 61300, 68200, 75400, 83000, 91000, 99400, 108200, 117400, 127100, 137300, 147800, 158600, 169700,
    181100, 192900, 205000, 217500, 230400, 243600, 257200, 271100, 285300, 299800, 314600, 329700, 345100, 360800,
    376800, 393100, 409800, 426600, 443500, 460600, 477800, 495200, 512700, 530400, 548200, 566200, 584300, 602500,
    620800, 639200, 657700, 676300, 695000, 713800, 732700, 751700, 770800, 790000, 809300, 828700, 848200, 867800,
    887500, 907300, 927200, 947200, 967300, 987500, 1007800, 1028200, 1048700, 1069300, 1090000, 1110800, 1131700,
    1152700, 1173800, 1195000, 1216300, 1237700, 1259200, 1280800, 1302500, 1324300, 1346200, 1368200,
  ];

  // Определяем текущий ранг и XP для следующего ранга
  const { currentRank, nextRankXP } = useMemo(() => {
    let rank = 0; // Начинаем с 0 ранга
    while (rank < rankXP.length && totalXP >= rankXP[rank]) {
      rank++;
    }
    return {
      currentRank: rank,
      nextRankXP: rank < rankXP.length ? rankXP[rank] : null, // Если ранг максимальный, следующего нет
    };
  }, [totalXP]);

  // Рассчитываем процент прогресса до следующего ранга
  const progressPercentage = useMemo(() => {
    if (currentRank === 0) {
      // Если текущий ранг 0, прогресс считается от 0 до XP для 1 ранга
      return Math.round((totalXP / rankXP[0]) * 100);
    }
    if (nextRankXP === null) {
      // Если ранг максимальный, прогресс 100%
      return 100;
    }
    const currentRankXP = rankXP[currentRank - 1]; // XP для текущего ранга
    return Math.round(((totalXP - currentRankXP) / (nextRankXP - currentRankXP)) * 100);
  }, [totalXP, currentRank, nextRankXP]);

  return {
    currentRank,
    totalXP,
    nextRankXP,
    progressPercentage,
  };
};

export default useCalculateRank;
