import React from 'react';

export const Item = ({ count, lvl }) => {
  return (
    <div className="flex flex-col space-y-[1.6rem] p-[1rem] pb-[2rem] bg-white-50 border-[0.1rem] border-solid border-white-50 rounded-[2rem] max-w-[25.5rem] lg:max-w-full">
      <div className="flex relative">
        <img
          src={lvl < 15 ? '/energynft/img/duo.png' : '/energynft/img/quattro.png'}
          className="rounded-[1rem] w-[23.5rem] h-[23.5rem] lg:w-full lg:h-full"
        />
        <div className="absolute top-[1rem] left-[1rem] flex items-center justify-center px-[1.2rem] h-[2.8rem] rounded-[2rem] bg-[rgba(81,246,137,0.10)]">
          <span className="text-[1.2rem] text-[#51F689] font-500">{count}</span>
        </div>
      </div>
      <div className="flex items-center justify-between w-full px-[1rem]">
        <div className="flex space-x-[0.8rem] text-[1.6rem]">
          <span className="font-500 text-white-500">Energy</span>
          <span className="font-600 text-white">Restart lvl {lvl}</span>
        </div>
        <div className="flex items-center justify-center w-[2rem] h-[2rem] bg-[rgba(81,246,137,0.10)] rounded-full">
          <div className="w-[0.8rem] h-[0.8rem] rounded-full bg-[#51F689]"></div>
        </div>
      </div>
      {/* <div className="flex flex-col space-y-[1.2rem] p-[1rem] rounded-[1rem] bg-white-30">
        <div className="flex space-x-[4.8rem]">
          <div className="flex flex-col space-y-[0.4rem]">
            <span className="text-[1.2rem] font-500 text-white-400">APY</span>
            <span className="text-[1.6rem] font-600 text-white">81,4%</span>
          </div>
          <div className="flex flex-col space-y-[0.4rem]">
            <span className="text-[1.2rem] font-500 text-white-400">Staking Period</span>
            <span className="text-[1.6rem] font-600 text-white">30%</span>
          </div>
        </div>
        <div className="flex items-center relative w-full h-[1.2rem] overflow-hidden rounded-full">
          <div className="w-full bg-white-70 h-full" />
          <div className="absolute left-0 w-[60%] bg-[#51F689] h-full" />
        </div>
      </div> */}
    </div>
  );
};
