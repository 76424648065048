import React from 'react';
import polIcon from '../../../../assets/icons/main/polCoin.svg';
import { Button } from '../../../../components/Button';
import WhiteCopyIcon from '../../../../assets/icons/landing/whiteCopyIcon.svg';
import config from '../../../../helpers/config';
import { copy } from '../../../../helpers/copy';
import { shortenAddress } from '../../../../helpers/format';

export const ContractAddress = () => {
  const tokenAddress = config.contractPollyToken;
  return (
    <div className="flex items-center justify-between w-full bg-white-50 h-[8rem] rounded-[2rem] px-[2rem] lg:flex-col lg:h-[11.9rem] lg:rounded-none lg:bg-white-70 lg:py-[2rem] ">
      <div className="flex items-center space-x-[1.6rem] lg:w-full lg:space-x-0">
        <div className="h-[3.6rem] w-[0.4rem] rounded-[2rem] bg-[#AF7FFF] shadow-[0px_0px_20px_0px_rgba(175,127,255,0.50)] lg:hidden" />
        <div className="flex items-center space-x-[2.4rem] lg:w-full lg:justify-between">
          <span className="text-[2rem] text-white font-600 tracking-[-0.2px] lg:text-[1.6rem] lg:tracking-[-0.16px]">
            $POLLY Smart Contract
          </span>
          <div className="flex items-center space-x-[1rem]">
            <span className="text-[1.4rem] font-500 text-white lg:text-[1.2rem]">
              <span className="text-white-500">Network:</span> Polygon
            </span>
            <img className="w-[2.4rem] h-[2.4rem] lg:w-[1.6rem] lg:h-[1.6rem]" src={polIcon} />
          </div>
        </div>
      </div>
      <div className="flex items-center space-x-[1.2rem] lg:w-full">
        <div className="flex items-center justify-center h-[4rem] px-[2rem] rounded-[1.2rem] border-[0.1rem] border-solid border-white-50 bg-white-50 lg:px-[1.6rem] lg:w-full">
          <span className="text-[1.4rem] font-400 text-white-500 lg:hidden">{tokenAddress}</span>
          <span className="text-[1.3rem] font-400 text-white-500 hidden lg:flex">{shortenAddress(tokenAddress)}</span>
        </div>
        <Button onClick={() => copy(tokenAddress)} className="h-[4rem] w-[4rem] !p-0 shrink-0" type="purple">
          <img className="w-[2rem] h-[2rem]" src={WhiteCopyIcon} />
        </Button>
      </div>
    </div>
  );
};
