import React from 'react';

export const Table = () => {
  const packagesList = [
    {
      name: 'Basic',
      price: '20',
      forecast: '1.200',
    },
    {
      name: 'Pro',
      price: '147',
      forecast: '11.670',
    },
    {
      name: 'Elite',
      price: '1030',
      forecast: '82.800',
    },
  ];
  return (
    <div className="flex flex-col items-center space-y-[2rem] w-full max-w-[120rem] lg:max-w-full lg:px-[2rem]">
      <div className="flex flex-col items-center space-y-[3rem] py-[4rem] w-full lg:py-[2rem] lg:space-y-[1.5rem]">
        <div className="flex w-full justify-between items-center  ">
          <span className="text-[2rem] text-white-300 font-500 lg:text-[1.2rem]">
            Registration <br className="hidden lg:flex" /> Packages
          </span>
          <span className="text-[2rem] text-white-300 font-500 absolute left-1/2 -translate-x-1/2 lg:text-[1.2rem]">
            Cost (POL)
          </span>
          <span className="text-[2rem] text-white-300 font-500 lg:text-[1.2rem] lg:text-right">
            Forecast PEV <br className="hidden lg:flex" />
            in First Month*
          </span>
        </div>
        <div className="flex flex-col w-full space-y-[3rem] lg:space-y-[1.5rem]">
          {packagesList.map((item, itemIndex) => {
            return (
              <>
                {itemIndex === 0 && <img src="/images/target/Earn/tableLine.webp" />}
                <div className="flex w-full justify-between relative" key={itemIndex}>
                  <span className="text-[2rem] text-white font-600 lg:text-[1.4rem]">{item?.name}</span>
                  <span className="text-white-500 text-[2rem] font-600 absolute left-1/2 -translate-x-1/2 lg:text-[1.4rem]">
                    {item?.price} POL
                  </span>
                  <span className="text-[2rem] text-white font-600 lg:text-[1.4rem]">~ {item?.forecast} POL*</span>
                </div>
                {itemIndex < 2 && <img src="/images/target/Earn/tableLine.webp" />}
              </>
            );
          })}
        </div>
      </div>
      <span className="text-[1.6rem] font-400 text-white leading-[2.88rem] text-center lg:text-[1.2rem] lg:leading-[2.16rem]">
        *Forecast PEV (Possible Expected Value) in First Month{' '}
        <span className="text-white-500">
          – this figure represents the amount of POL coins that can be earned as a reward by each participant in the
          Social Mining Program, provided they complete one full cycle on every activated level within a 30-day period.
        </span>
      </span>
    </div>
  );
};
