import React from 'react';
import { EnergyCard } from './EnergyCard';
import { EnergyProfit } from './EnergyProfit';
import { NftsList } from './NftsList';

export const EnergyInfo = ({ setChoosedNftId, choosedNftId, balanceNft, updateBalance, rewardForClaim, stakeNfts }) => {
  return (
    <div className="relative flex w-full space-x-[2.8rem] lg:flex-col lg:space-x-0 lg:space-y-[2rem] z-[11]">
      <EnergyCard choosedNftId={choosedNftId} balanceNft={balanceNft} updateBalance={updateBalance} />
      <div className="flex flex-col space-y-[2rem] w-full lg:space-y-0">
        <EnergyProfit />
        <NftsList
          setChoosedNftId={setChoosedNftId}
          choosedNftId={choosedNftId}
          balanceNft={balanceNft}
          rewardForClaim={rewardForClaim}
          stakeNfts={stakeNfts}
        />
      </div>
    </div>
  );
};
