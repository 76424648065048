import React from 'react';

export const WithoutNft = () => {
  return (
    <div className="flex flex-col items-center space-y-[1.2rem] h-full w-full pt-[6rem] z-[1] without-nft-ng">
      <span className="text-[2.4rem] text-white font-600 leading-[3.36rem]">Dont have Energy</span>
      <span className="text-[1.4rem] font-400 leading-[1.96rem] text-white-300">You dont have NFTs</span>
      <img className="absolute max-w-[85rem] lg:max-w-full pt-[5rem]" src="/images/energy/states/withoutNft.png" />
    </div>
  );
};
