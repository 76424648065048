import React from 'react';
import { Trans } from 'react-i18next';

export const Unstaking = () => {
  return (
    <div className="flex flex-col items-start space-y-[1.2rem] p-[2rem] rounded-[2rem] bg-white-30 w-full">
      <span className="text-white-500 text-[1.4rem] font-500 leading-[2.24rem]">
        <Trans i18nKey="site.staking.modal.unstaking.title">Unstaking</Trans>
      </span>
      <div className="flex items-center space-x-[1.2rem] lg:flex-col lg:space-x-0 lg:space-y-[1.2rem] lg:items-start ">
        <div className="flex items-center justify-center rounded-full bg-white-100 h-[3.4rem] px-[1.6rem] lg:h-[3.1rem] ">
          <span className="text-white-500 text-[1.4rem] font-500 leading-[2.24rem] lg:text-[1.2rem] lg:leading-[1.92rem]">
            <Trans i18nKey="site.staking.modal.unstaking.noEarly">No early unstaking.</Trans>
          </span>
        </div>
        <div className="flex items-center justify-center rounded-full bg-white-100 h-[3.4rem] px-[1.6rem] lg:h-[3.1rem] lg:px-[1rem] ">
          <span className="text-white-500 text-[1.4rem] font-500 leading-[2.24rem] lg:text-[1.2rem] lg:leading-[1.92rem]">
            <Trans i18nKey="site.staking.modal.unstaking.rewardsStop">Rewards stop when the staking period ends.</Trans>
          </span>
        </div>
      </div>
    </div>
  );
};
