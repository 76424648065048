import React from 'react';
import { Programs } from './Programs';

export const Streams = () => {
  return (
    <div className="flex flex-col space-y-[12rem] items-center justify-start w-full pt-[10rem] lg:max-w-full streams-bg lg:space-y-[4rem] lg:px-[2rem]">
      <div className="flex flex-col items-start w-full lg:max-w-full max-w-[120rem]">
        <span className="target-title-color text-[9rem] font-600 leading-[9.9rem] max-w-[80.1rem] lg:text-[3.2rem] lg:leading-[3.52rem] lg:max-w-[30rem]">
          Duo & Quattro – How the Two SMP Streams Work
        </span>
      </div>
      <Programs />
    </div>
  );
};
