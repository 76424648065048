import { useState } from 'react';
import { useGetContract } from './useGetContract';
import { DEFAULT_GAS_LIMIT, ContractNames, REGISTER_PRICE } from '../constants';
import { getAddress } from '../format';
import { toWei } from '../numbers';
import { increaseByPercent } from '../numbers';
import { useWeb3React } from '@web3-react/core';
import { callNotification } from '../notification';
import { parseErrorToUserReadableMessage } from '../format';
import config from '../config';

export const useRegistration = () => {
  const [isLoadingRegistration, setIsLoadingRegistration] = useState(false);
  const { getContract } = useGetContract();
  const { account } = useWeb3React();

  const registration = async (
    uplineWallet,
    uplineKey = 0,
    uplineId = 0,
    levelsX2 = 1,
    levelsX4 = 0,
    totalPrice = 0,
    minPolly = 0,
  ) => {
    const id = uplineId ? uplineId : 0;
    const refkey = uplineKey ? uplineKey : 0;
    const wallet = uplineWallet ? uplineWallet : '0xa86da3b9f21021aac95ce365e88564f2ae85de28';

    if (!isLoadingRegistration) {
      setIsLoadingRegistration(true);

      try {
        const contract = await getContract(ContractNames.ROUTER);
        let gasEstimate = 0;
        if (config.stand === 'prod') {
          gasEstimate = await contract.estimateGas.registrationX2X4(
            account,
            wallet,
            id,
            refkey,
            levelsX2,
            levelsX4,
            minPolly,
            {
              value: BigInt(totalPrice),
            },
          );
        }

        return await contract.registrationX2X4(account, wallet, id, refkey, levelsX2, levelsX4, minPolly, {
          gasLimit: parseInt(gasEstimate) ? increaseByPercent(gasEstimate) : DEFAULT_GAS_LIMIT,
          value: BigInt(totalPrice),
        });
      } catch (e) {
        console.log(e);
        callNotification({ type: 'error', message: parseErrorToUserReadableMessage(e) });
        setIsLoadingRegistration(false);
        return Promise.reject(e);
      } finally {
        setIsLoadingRegistration(false);
      }
    }
  };

  return {
    registration,
    isLoadingRegistration,
  };
};
