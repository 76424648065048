import { createSlice } from '@reduxjs/toolkit';

export const matrixX2X4Slice = createSlice({
  name: 'matrixX2X4',
  initialState: {
    matrixX2X4: {},
  },
  reducers: {
    updateMatrixX2X4(state, action) {
      state.matrixX2X4 = { ...state.matrixX2X4, ...action.payload };
    },
    clearMatrixX2X4(state) {
      state.matrixX2X4 = {};
    },
  },
});

export const matrixX2X4Reducer = matrixX2X4Slice.reducer;

export const { updateMatrixX2X4, clearMatrixX2X4 } = matrixX2X4Slice.actions;
