import { gql } from '@apollo/client';

export const GRAPH_TYPES = {
  MATRIX_INFO: 'matrixInfoRequest',
  USER_INFO: 'userInfoRequest',
};

export const matrixInfoRequest = (userAddress) => {
  const addressFilter = userAddress.toLowerCase();
  return `{
      user(id: "${addressFilter}") { 
        levels {
          levelNumber
          recyclesTotal
          totalReward
          reward24
          lastRewardDay
          expiredAt
          activationTimes
          matrixs {
            id
            place1 {
              value
              receiver {
                id
              }
              realReceiver {
                id
              }
              user {
                id
              }
              status
            }
            place2 {
              value
              receiver {
                id
              }
              realReceiver {
                id
              }
              user {
                id
              }
              status
            }
            place3 {
              value
              receiver {
                id
              }
              realReceiver {
                id
              }
              user {
                id
              }
              status
            }
            place4 {
              value
              receiver {
                id
              }
              realReceiver {
                id
              }
              user {
                id
              }
              status
            }
            place5 {
              value
              receiver {
                id
              }
              realReceiver {
                id
              }
              user {
                id
              }
              status
            }
            place6 {
              value
              receiver {
                id
              }
              realReceiver {
                id
              }
              user {
                id
              }
              status
            }
          }
        }
      }
    }`;
};

export const CHECK_UPLINE_ADDRESS = gql`
  query userData($user: String!) {
    users(where: { id: $user }) {
      id
      internalId
      refNumber
    }
  }
`;

export const CHECK_UPLINE_REFNUMBER = gql`
  query userData($uplineKey: String!) {
    users(where: { refNumber: $uplineKey }) {
      id
      internalId
      refNumber
    }
  }
`;

export const CHECK_UPLINE_INTERNAL_ID = gql`
  query userData($internalId: Int!) {
    users(where: { internalId: $internalId }) {
      id
      internalId
      refNumber
    }
  }
`;

export const GET_TOTAL_STATS = gql`
  {
    stats(first: 1) {
      id
      totalUsers
      totalUsers24
      lastUsers24Day
      totalTokens
      totalTokens24
      lastTokens24Day
      totalBurned
      totalBurned24
      lastBurned24Day
    }
  }
`;

export const GET_ALL_TOTAL_STATS = gql`
  {
    stats(first: 1) {
      totalUsers
      totalUsers24
      lastUsers24Day

      x2TotalTokens
      x2TotalTokens24
      x2LastTokens24Day

      x4TotalTokens
      x4TotalTokens24
      x4LastTokens24Day
    }
  }
`;

export const GET_USER_DATA = gql`
  query userData($user: String!) {
    user(id: $user) {
      id
      internalId
      totalPartners
      partners24
      partners24Day
      totalTeam
      team24
      team24Day
      totalRewardX2
      totalRewardX4
      registrationTime
      referral {
        id
        internalId
      }
      refNumber
      levelsX2 {
        isActivated
        levelNumber
        recyclesTotal
        totalReward
        reward24
        firstLineReward24
        firstLineRewardDay
        structureReward24
        structureRewardDay
        lastRewardDay
      }
      levelsX4 {
        isActivated
        levelNumber
        recyclesTotal
        totalReward
        reward24
        firstLineReward24
        firstLineRewardDay
        structureReward24
        structureRewardDay
        lastRewardDay
      }
    }
  }
`;

export const GET_PARTNERS_24 = gql`
  query userData($user: String!) {
    users(where: { referral: $user }) {
      id
      internalId
      registrationTime
    }
  }
`;

export const GET_USER_PARTNERS = gql`
  query userData($user: String!) {
    users(where: { referral: $user }) {
      id
      internalId
      totalPartners
      totalRewardX2
      totalRewardX4
      registrationTime
      registrationTransaction
      levelsX2 {
        levelNumber
        isActivated
      }
      levelsX4 {
        levelNumber
        isActivated
      }
    }
  }
`;

export const GET_MATRIX_DATA = gql`
  query matrixData($user: String!) {
    user(id: $user) {
      levelsX2 {
        levelNumber
        recyclesTotal
        totalReward
        reward24
        lastRewardDay
        totalPartners
        totalTeam
        isActivated
        blocked
        missedPartners
        missedProfit
        matrixs {
          id
          place1 {
            transaction
            value
            receiver {
              id
              internalId
            }
            realReceiver {
              id
              internalId
            }
            user {
              id
              internalId
            }
            status
          }
          place2 {
            transaction
            value
            receiver {
              id
              internalId
            }
            realReceiver {
              id
              internalId
            }
            user {
              id
              internalId
            }
            status
          }
        }
      }
      levelsX4 {
        levelNumber
        recyclesTotal
        totalReward
        reward24
        lastRewardDay
        totalPartners
        totalTeam
        isActivated
        blocked
        missedPartners
        missedProfit
        matrixs {
          id
          place1 {
            transaction
            value
            receiver {
              id
              internalId
            }
            realReceiver {
              id
              internalId
            }
            user {
              id
              internalId
            }
            status
          }
          place2 {
            transaction
            value
            receiver {
              id
              internalId
            }
            realReceiver {
              id
              internalId
            }
            user {
              id
              internalId
            }
            status
          }
          place3 {
            transaction
            value
            receiver {
              id
              internalId
            }
            realReceiver {
              id
              internalId
            }
            user {
              id
              internalId
            }
            status
          }
          place4 {
            transaction
            value
            receiver {
              id
              internalId
            }
            realReceiver {
              id
              internalId
            }
            user {
              id
              internalId
            }
            status
          }
          place5 {
            transaction
            value
            receiver {
              id
              internalId
            }
            realReceiver {
              id
              internalId
            }
            user {
              id
              internalId
            }
            status
          }
          place6 {
            transaction
            value
            receiver {
              id
              internalId
            }
            realReceiver {
              id
              internalId
            }
            user {
              id
              internalId
            }
            status
          }
        }
      }
    }
  }
`;

export const GET_LEADERBOARD_USERS = gql`
  {
    users(first: 100, orderBy: totalReward, orderDirection: desc) {
      id
      totalReward
    }
  }
`;

export const GET_LEADERBOARD_USERS_FLINE = gql`
  query userData($partners: [String]) {
    users(where: { referral_in: $partners }, block: { number: 36941078 }) {
      id
      referral {
        id
      }
      totalReward
    }
  }
`;
