import React, { useMemo, useState } from 'react';
import { Modal } from '../../Modal';
import { Period } from './Period';
import { DepositRules } from './DepositRules';
import { Unstaking } from './Unstaking';
import { Trans } from 'react-i18next';

export const AboutStakingModal = ({ openedModal, handleCloseModal }) => {
  return (
    <Modal className="!mb-[0rem]" isOpened={openedModal} onClose={handleCloseModal}>
      <div className=" flex flex-col items-center w-[62.5rem] p-[4rem] space-y-[4.8rem] max-h-[70rem] overflow-auto hide-scroll rounded-[3rem] border-[0.1rem] border-solid border-white-50 bg-white-50 bg-blend-difference shadow-[0px_20px_50px_0px_rgba(0,0,0,0.40)] backdrop-blur-[5rem] text-center lg:w-full lg:px-[2rem] lg:rounded-none lg:rounded-t-[2rem] lg:space-y-[2.4rem] lg:h-[50rem] lg:border-none">
        <div className="flex flex-col space-y-[1.6rem]">
          <span className="text-[3.2rem] text-white font-600 leading-[4.48rem] tracking-[-0.48px] lg:text-[2.4rem] lg:leading-[3.36rem]">
            $POLLY Staking
            <br /> Rewards Overview
          </span>
          <span className="text-white-500 text-[1.4rem] font-500 leading-[2.24rem]">
            Stake $POLLY tokens to <span className="text-white">earn passive income.</span>
            <br /> Receive stPOLLY at a 1:1 ratio for staked $POLLY (non-transferable).
          </span>
        </div>
        <div className="flex flex-col items-center space-y-[2.4rem] lg:w-full">
          <div className="flex flex-col space-y-[1.2rem] lg:w-full">
            <Period />
            <DepositRules />
            <Unstaking />
          </div>
          <span className="text-[1.4rem] font-500 text-white-500 leading-[2.24rem]">
            <Trans i18nKey="site.staking.modal.deposits">Deposits can be paused/resumed by the system!</Trans>
          </span>
          <span className="text-[2.4rem] font-600 text-white leading-[3.84rem] max-w-[41.3rem]">
            <Trans i18nKey="site.staking.modal.startStaking">
              Start staking today and maximize your <span className="text-[#AF7FFF]">$POLLY earnings!</span>
            </Trans>
          </span>
        </div>
      </div>
    </Modal>
  );
};
