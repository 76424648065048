import { BigNumber } from '@ethersproject/bignumber';
import config from './config';

export const MAX_VALUE = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';
export const DEFAULT_GAS_LIMIT = BigNumber.from(2000000);

export const ContractNames = {
  MATRIX_X2X4: 'x2x4',
  ROUTER: 'router',
  NFT_PASS: 'nftPass',
  NFT_PROMO: 'nftPromo',
  TIERS_SALE: 'tiersSale',
  PANCAKE: 'pancake',
  POLLY_TOKEN: 'pollyToken',
  ST_POLLY_TOKEN: 'stPollyToken',
  STAKING: 'staking',
  ENERGY_NFT: 'energyNft',
  ENERGY_POOL: 'energyPool',
};

export const CONTRACT_NAMES = {
  MATRIX_X2X4: 'x2x4',
  ROUTER: 'router',
  NFT_PASS: 'nftPass',
  NFT_PROMO: 'nftPromo',
  TIERS_SALE: 'tiersSale',
  PANCAKE: 'pancake',
  POLLY_TOKEN: 'pollyToken',
  ST_POLLY_TOKEN: 'stPollyToken',
  STAKING: 'staking',
  ENERGY_NFT: 'energyNft',
  ENERGY_POOL: 'energyPool',
};

export const PROGRAM_NAMES = {
  X2: 'Duo',
  X4: 'Quattro',
};

export const nameToType = {
  [PROGRAM_NAMES.X2]: CONTRACT_NAMES.MATRIX_X2X4,
  [PROGRAM_NAMES.X4]: CONTRACT_NAMES.MATRIX_X2X4,
};

export const PROGRAM_MAX_LEVELS = {
  [PROGRAM_NAMES.X2]: 15,
  [PROGRAM_NAMES.X4]: 15,
};

// price in wei

const dev_price = {
  [PROGRAM_NAMES.X2]: [
    2000000000000000, 3200000000000000, 4300000000000000, 5500000000000000, 7500000000000000, 10500000000000000,
    16000000000000000, 21000000000000000, 32000000000000000, 43000000000000000, 54000000000000000, 75000000000000000,
    107000000000000000, 160000000000000000, 210000000000000000,
  ],
  [PROGRAM_NAMES.X4]: [
    2000000000000000, 3200000000000000, 4300000000000000, 5500000000000000, 7500000000000000, 10500000000000000,
    16000000000000000, 21000000000000000, 32000000000000000, 43000000000000000, 54000000000000000, 75000000000000000,
    107000000000000000, 160000000000000000, 210000000000000000,
  ],
};

const prod_price = {
  [PROGRAM_NAMES.X2]: [
    20000000000000000000, 32000000000000000000, 43000000000000000000, 55000000000000000000, 75000000000000000000,
    105000000000000000000, 160000000000000000000, 210000000000000000000, 320000000000000000000, 430000000000000000000,
    540000000000000000000, 750000000000000000000, 1070000000000000000000, 1600000000000000000000,
    2100000000000000000000,
  ],
  [PROGRAM_NAMES.X4]: [
    20000000000000000000, 32000000000000000000, 43000000000000000000, 55000000000000000000, 75000000000000000000,
    105000000000000000000, 160000000000000000000, 210000000000000000000, 320000000000000000000, 430000000000000000000,
    540000000000000000000, 750000000000000000000, 1070000000000000000000, 1600000000000000000000,
    2100000000000000000000,
  ],
};

export const PROGRAM_PRICES_WEI = config.stand === 'prod' ? prod_price : dev_price;

// commisions in wei

const prod_fee = {
  [PROGRAM_NAMES.X2]: [
    2000000000000000000, 3000000000000000000, 4000000000000000000, 5000000000000000000, 6000000000000000000,
    8000000000000000000, 10000000000000000000, 12000000000000000000, 14000000000000000000, 16000000000000000000,
    20000000000000000000, 24000000000000000000, 28000000000000000000, 32000000000000000000, 36000000000000000000,
  ],
  [PROGRAM_NAMES.X4]: [
    2000000000000000000, 3000000000000000000, 4000000000000000000, 5000000000000000000, 6000000000000000000,
    8000000000000000000, 10000000000000000000, 12000000000000000000, 14000000000000000000, 16000000000000000000,
    20000000000000000000, 24000000000000000000, 28000000000000000000, 32000000000000000000, 36000000000000000000,
  ],
};

const dev_fee = {
  [PROGRAM_NAMES.X2]: [
    200000000000000, 300000000000000, 400000000000000, 500000000000000, 600000000000000, 800000000000000,
    1000000000000000, 1200000000000000, 1400000000000000, 1600000000000000, 2000000000000000, 2400000000000000,
    2800000000000000, 3200000000000000, 3600000000000000,
  ],
  [PROGRAM_NAMES.X4]: [
    200000000000000, 300000000000000, 400000000000000, 500000000000000, 600000000000000, 800000000000000,
    1000000000000000, 1200000000000000, 1400000000000000, 1600000000000000, 2000000000000000, 2400000000000000,
    2800000000000000, 3200000000000000, 3600000000000000,
  ],
};

export const PROGRAM_FEE_WEI = config.stand === 'prod' ? prod_fee : dev_fee;

export const PROGRAM_PRICES = {
  [PROGRAM_NAMES.X2]: [20, 32, 43, 55, 75, 105, 160, 210, 320, 430, 540, 750, 1070, 1600, 2100],
  [PROGRAM_NAMES.X4]: [20, 32, 43, 55, 75, 105, 160, 210, 320, 430, 540, 750, 1070, 1600, 2100],
};

export const PROGRAM_FEE = {
  [PROGRAM_NAMES.X2]: [2, 3, 4, 5, 6, 8, 10, 12, 14, 16, 20, 24, 28, 32, 36],
  [PROGRAM_NAMES.X4]: [2, 3, 4, 5, 6, 8, 10, 12, 14, 16, 20, 24, 28, 32, 36],
};

export const REGISTER_PACKS = [
  {
    [PROGRAM_NAMES.X2]: 1,
    [PROGRAM_NAMES.X4]: 0,
  },
  {
    [PROGRAM_NAMES.X2]: 2,
    [PROGRAM_NAMES.X4]: 3,
  },
  {
    [PROGRAM_NAMES.X2]: 6,
    [PROGRAM_NAMES.X4]: 8,
  },
];

export const REGISTER_PRICE = PROGRAM_PRICES[PROGRAM_NAMES.X2][0] + PROGRAM_PRICES[PROGRAM_NAMES.X4][0];

export const PROGRAM_CURRENCY = {
  [PROGRAM_NAMES.MATRIX_X2X4]: 'POL',
};

export const DEFAULT_SLIPPAGE_SWAP = 0.5;
export const BUY_TIMEOUT = 10000;
export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

export const PUBLIC_URLS = {
  137: 'https://polygon-bor-rpc.publicnode.com',
  97: 'https://bsc-testnet-rpc.publicnode.com',
  80002: 'https://polygon-amoy-bor-rpc.publicnode.com',
};

export const explorerLinks =
  config.allowedChainId === 137
    ? {
        accountUrl: 'https://polygonscan.com/address/',
        txUrl: 'https://polygonscan.com/tx/',
      }
    : {
        accountUrl: 'https://amoy.polygonscan.com/address/',
        txUrl: 'https://amoy.polygonscan.com/tx/',
      };

export const ADD_CHAIN_ETHS_PARAMS = {
  97: {
    chainName: 'Binance Smart Chain Testnet',
    nativeCurrency: {
      name: 'Binance Coin',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: ['https://data-seed-prebsc-2-s3.binance.org:8545'],
    blockExplorerUrls: ['https://testnet.bscscan.com'],
    chainId: '0x61',
  },
  137: {
    chainName: 'Polygon Mainnet',
    nativeCurrency: {
      name: 'POL',
      symbol: 'POL',
      decimals: 18,
    },
    rpcUrls: ['https://polygon-rpc.com'],
    blockExplorerUrls: ['https://polygonscan.com/'],
    chainId: '0x89',
  },
  80002: {
    chainName: 'Polygon Testnet',
    nativeCurrency: {
      name: 'POL',
      symbol: 'POL',
      decimals: 18,
    },
    rpcUrls: ['https://api.zan.top/polygon-amoy'],
    blockExplorerUrls: ['https://amoy.polygonscan.com/'],
    chainId: '0x13882',
  },
};

export const EXPLORER_URL = ADD_CHAIN_ETHS_PARAMS?.[config.allowedChainId]?.blockExplorerUrls[0];
