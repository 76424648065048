import React, { useMemo, useState } from 'react';
import { Modal } from '../../Modal';
import WhaleIcon from '../../../assets/icons/whaleChat/whaleIcon.svg';
import CheckIcon from '../../../assets/icons/whaleChat/blueCheck.svg';
import ShadowAnimation from '../../../assets/icons/whaleChat/shadowAnimation.svg';
import { Input } from '../../Input';
import axios from 'axios';
import { useWeb3React } from '@web3-react/core';

import { useSelector } from 'react-redux';
import { getBalance } from '../../../store/walletSlice/selectors';
import { encode, decode } from 'base-64';
import { copy } from '../../../helpers/copy';
import CopyIcon from '../../../assets/icons/main/copy.svg';
import { setCookie } from 'nookies';
import { Trans } from 'react-i18next';

export const WhaleChatModal = ({ scrollToSale, openConnectWallet, openedModal, handleCloseModal }) => {
  const [inviteLink, setInviteLink] = useState(null);
  const [loadingInviteLink, setLoadingInviteLink] = useState(false);
  const { polly: balancePolly, stPolly: balanceStPolly } = useSelector(getBalance);
  const isNeededBalance = balancePolly + balanceStPolly >= 7500000;
  const needStringBalance = '7,500,000';
  const { account } = useWeb3React();

  const code = account
    ? encode(account.slice(0, 4) + account + account.slice(account.length - 4, account.length))
    : null;

  const titleClassname = 'text-[2.4rem] font-600 tracking-[-0.48px] text-white lg:text-[2rem] lg:tracking-[-0.4px]';

  const descClassname = 'text-[1.4rem] text-white-500 font-500 leading-[1.96rem]';

  const buttonClassname =
    'custom-transition flex items-center justify-center w-full h-[4.8rem] rounded-[1.2rem] bg-[#49A0E9] shadow-[0px_10px_40px_0px_rgba(87,178,255,0.30)] text-[1.6rem] text-white font-500 leading-[2.24rem] hover:opacity-60 disabled:opacity-60';

  const getInviteLink = async () => {
    setLoadingInviteLink(true);
    try {
      const result = await axios.get(`https://whalebot.polynetica.com/decode_wallet/${code}`);
      if (result) {
        setInviteLink(result?.data?.url);
        setLoadingInviteLink(false);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingInviteLink(false);
    }
  };

  const connectWalletFunc = () => {
    setCookie(null, 'needToOpenWhale', true, {
      maxAge: 30 * 24 * 60 * 60,
      path: '/',
    });
    openConnectWallet();
  };

  const renderContent = useMemo(() => {
    if (!isNeededBalance && account) {
      return (
        <div className="flex flex-col space-y-[4rem] text-center max-w-[42.6rem] lg:max-w-full lg:space-y-[3.4rem]">
          <div className="flex flex-col items-center space-y-[2.4rem] max-w-[34.6rem] lg:max-w-full lg:space-y-[1.2rem]">
            <span className={titleClassname}>
              <Trans i18nKey="site.whaleChatModal.insufficientBalance.title">Insufficient $POLLY Balance</Trans>
            </span>
            <span className={descClassname}>
              <Trans i18nKey="site.whaleChatModal.insufficientBalance.desc">
                You currently hold less than <span className="text-white font-600">{needStringBalance}</span>. To join
                the Whale Chat, increase your balance and unlock access to exclusive privileges
              </Trans>
            </span>
          </div>
          <div className="flex flex-col items-center space-y-[2.4rem] lg:space-y-[1.6rem]">
            <span className="text-[1.4rem] text-[#49A0E9] font-500 leading-[1.96rem] max-w-[26.6rem] lg:max-w-full">
              <Trans i18nKey="site.whaleChatModal.insufficientBalance.descButton">
                Buy $POLLY now and secure your spot in the elite community!
              </Trans>
            </span>
            <button
              onClick={() => {
                closeModal();
                scrollToSale();
              }}
              className={buttonClassname}
            >
              <span>
                <Trans i18nKey="site.whaleChatModal.insufficientBalance.buy">Buy $POLLY</Trans>
              </span>
            </button>
          </div>
        </div>
      );
    }
    if (!!inviteLink) {
      return (
        <div className="flex flex-col space-y-[3.4rem] text-center w-full lg:space-y-[3.4rem]">
          <div className="flex flex-col space-y-[2.4rem] w-full lg:space-y-[1.2rem]">
            <span className={titleClassname}>
              <Trans i18nKey="site.whaleChatModal.welcome.title">Welcome to the Whale Chat!</Trans>
            </span>
            <span className={descClassname}>
              <Trans i18nKey="site.whaleChatModal.welcome.desc">Join now through the private link below</Trans>
            </span>
          </div>
          <div className="flex flex-col space-y-[1rem]">
            <div className="flex items-center space-x-[1rem] w-full">
              <Input value={inviteLink} placeholder="Link" readOnly />
              <button onClick={() => copy(inviteLink)} className={`${buttonClassname} !w-[7.2rem] `}>
                <img className="w-[2rem] h-[2rem]" src={CopyIcon} alt="copyIcon" />
              </button>
            </div>
            <a href={inviteLink} className={`${buttonClassname} !w-full `} target="_blank" rel="noreferrer">
              <span>
                <Trans i18nKey="site.whaleChatModal.welcome.openTelegram">Open Telegram</Trans>
              </span>
            </a>
          </div>
        </div>
      );
    }
    if ((!inviteLink && isNeededBalance) || !account) {
      return (
        <div className="flex flex-col space-y-[4.8rem] text-center w-full max-w-[61.8rem] lg:max-w-full lg:space-y-[3.4rem]">
          <div className="flex flex-col items-center space-y-[2.4rem] w-full lg:space-y-[1.2rem]">
            <span className={`${titleClassname} max-w-[30rem] lg:max-w-full`}>
              <Trans i18nKey="site.whaleChatModal.join.title">Join the Exclusive $POLLY Whale Chat</Trans>
            </span>
            <span className={`${descClassname} max-w-[53.8rem] lg:max-w-full`}>
              <Trans i18nKey="site.whaleChatModal.join.desc">
                Enter a private community for elite $POLLY holders, where industry leaders, top contributors, and
                visionary minds connect.
              </Trans>
            </span>
          </div>
          <div className="flex flex-col space-y-[1.2rem] lg:text-left">
            <div className="flex items-center justify-between w-full lg:flex-row-reverse lg:space-x-[1.2rem] lg:space-x-reverse lg:justify-end">
              <span className="text-[1.4rem] text-white leading-[1.96rem]">
                <Trans i18nKey="site.whaleChatModal.join.advantages.item1">
                  Access to exclusive project updates and brainstorming sessions
                </Trans>
              </span>
              <img src={CheckIcon} />
            </div>
            <div className="flex items-center justify-between w-full lg:flex-row-reverse lg:space-x-[1.2rem] lg:space-x-reverse lg:justify-end">
              <span className="text-[1.4rem] text-white leading-[1.96rem]">
                <Trans i18nKey="site.whaleChatModal.join.advantages.item2">
                  Premium support and guidance from the Polynetica team
                </Trans>
              </span>
              <img src={CheckIcon} />
            </div>
            <div className="flex items-center justify-between w-full lg:flex-row-reverse lg:space-x-[1.2rem] lg:space-x-reverse lg:justify-end">
              <span className="text-[1.4rem] text-white leading-[1.96rem]">
                <Trans i18nKey="site.whaleChatModal.join.advantages.item3">
                  Mentorship programs and educational resources to grow your expertise
                </Trans>
              </span>
              <img src={CheckIcon} />
            </div>
          </div>
          <div className="flex flex-col space-y-[4.8rem] lg:space-y-[1.6rem]">
            <div className="flex items-center justify-between h-[5.2rem] px-[1.6rem] rounded-[1.2rem] border-[0.1rem] border-dashed border-white-200 lg:h-[4.8rem]">
              <span className="text-[1.4rem] text-white-500 font-500 leading-[1.96rem] lg:text-[1.2rem] lg:font-400">
                <Trans i18nKey="site.whaleChatModal.join.hold">To join, you must hold at least</Trans>
              </span>
              <span className="text-[1.4rem] text-white font-600 leading-[1.96rem] lg:text-[1.2rem]">
                {needStringBalance} $POLLY
              </span>
            </div>
            <div className="flex flex-col space-y-[1.6rem]">
              <button
                disabled={loadingInviteLink}
                onClick={account ? () => getInviteLink() : () => connectWalletFunc()}
                className={buttonClassname}
              >
                {account ? (
                  loadingInviteLink ? (
                    <span>
                      <Trans i18nKey="site.whaleChatModal.join.button.generateCode">Generate code</Trans>
                    </span>
                  ) : (
                    <span>
                      <Trans i18nKey="site.whaleChatModal.join.button.getAccess">Get Access</Trans>
                    </span>
                  )
                ) : (
                  <span>
                    <Trans i18nKey="site.whaleChatModal.join.button.connectWallet">Connect wallet</Trans>
                  </span>
                )}
              </button>
              <span className="text-[1.2rem] text-white-500 leading-[1.68rem] lg:text-[1rem] lg:leading-[1.4rem]">
                <Trans i18nKey="site.whaleChatModal.join.missChance">
                  Don’t miss your chance to be part of this elite community. Click below to get access!
                </Trans>
              </span>
            </div>
          </div>
        </div>
      );
    }
  }, [isNeededBalance, inviteLink, account, loadingInviteLink]);

  const closeModal = () => {
    setInviteLink(null);
    handleCloseModal();
  };

  return (
    <Modal isOpened={openedModal} onClose={closeModal}>
      <div className="flex flex-col rounded-[3rem] backdrop-blur-[5rem] shadow-[0px_20px_50px_0px_rgba(0,0,0,0.40)] bg-blend-normal-difference overflow-hidden lg:w-full lg:rounded-b-none lg:rounded-t-[2rem]">
        <div className="flex flex-col items-center space-y-[4rem] z-[11] w-full h-full rounded-[3rem] px-[4rem] pb-[3.5rem] pt-[6rem] border-[0.1rem] border-solid border-white-50 lg:space-y-[2.4rem] lg:pt-[4rem] lg:p-[2rem] lg:rounded-b-none lg:rounded-t-[2rem] lg:pb-[4rem]">
          <div className="flex items-center relative justify-center w-[10rem] h-[10rem] bg-[#E6ECF1] rounded-[2.6rem] lg:h-[8rem] lg:w-[8rem]">
            <img className="w-[8rem] h-[8rem] lg:w-[6.4rem] lg:h-[6.4rem]" src={WhaleIcon} />
            <img className="absolute shadowAnimation-whaleModal left-0" src={ShadowAnimation} />
          </div>
          {renderContent}
        </div>
        <img className="absolute top-0 w-full h-full z-[0]" src="/images/modals/WhaleChatModal/shadow.webp" />
      </div>
    </Modal>
  );
};
