import React, { useEffect, useState } from 'react';
import { LanguageSelector } from '../LanguageSelector';
import { SocialLinksComp } from '../SocialsListComp';
import { Link } from 'react-router-dom';
import { menuList } from 'helpers/menu';
import { usePreviewMode } from '../../helpers/hooks/useCheckPreviewMode';

export const LeftBar = () => {
  const [filteredMenuList, setFilteredMenuList] = useState(menuList);
  const [currentTab, setCurrentTab] = useState(0);
  const { previewUserAddress } = usePreviewMode();

  useEffect(() => {
    if (previewUserAddress) {
      const updatedMenuList = menuList.filter((item) => item.key !== 'energy');
      setFilteredMenuList(updatedMenuList);
    } else {
      setFilteredMenuList(menuList);
    }
  }, [previewUserAddress]);

  return (
    <div className="flex flex-col justify-between sticky top-0 w-[30rem] shrink-0 h-screen pl-[4rem] pb-[4rem] pr-[2rem] pt-[2rem] border-r-[0.1rem] border-[#161616] border-solid lg:hidden">
      <div className="flex flex-col space-y-[4rem]">
        <img className="w-[15.3rem] m-[0.8rem]" src="/images/layouts/logo.webp" alt="" />
        <div className="flex flex-col">
          {filteredMenuList.map((item, itemIndex) => {
            const isActive = location.pathname.split('/')?.[1] === item?.key;
            return (
              <Link
                onClick={() => setCurrentTab(itemIndex)}
                to={item?.href + location.search}
                className={`flex items-center space-x-[1.6rem] py-[1rem] px-[1.2rem] rounded-[1.2rem] w-full custom-transition group hover:bg-white-40 ${
                  isActive ? 'bg-white-80 text-white' : 'text-white opacity-50 '
                }`}
                key={itemIndex}
              >
                <img className="w-[2.4rem] h-[2.4rem]" src={isActive ? item?.activeIcon : item?.icon} />
                <span className="text-[1.6rem] font-500">{item?.title}</span>
              </Link>
            );
          })}
        </div>
      </div>
      <div className="pt-[4.8rem] flex flex-col space-y-[1rem] w-full">
        {/* <button>
          <Link
            to="/"
            className={`flex items-center space-x-[1.6rem] py-[1rem] px-[1.2rem] rounded-[1.2rem] w-full custom-transition group hover:bg-white-40`}
          >
            <img className="w-[2.4rem] h-[2.4rem]" src={logoutIcon} />
            <span className="text-white text-[1.6rem] font-500">Logout</span>
          </Link>
        </button> */}
        <LanguageSelector className="!w-full" />
        <SocialLinksComp className="justify-between w-full" />
      </div>
    </div>
  );
};
