import React from 'react';
import { TargetPackage } from '../../../components/TargetPackage';

export const Registration = () => {
  return (
    <div className="relative flex flex-col items-center justify-start w-full pt-[10rem] h-[157rem] lg:max-w-full overflow-hidden mt-[20rem]">
      <div className="flex flex-col items-start w-full lg:max-w-full max-w-[120rem] z-[2] mb-[12rem] lg:px-[2rem]">
        <span className="target-title-color text-[9rem] font-600 leading-[9.9rem] max-w-[90.1rem] lg:text-[3.2rem] lg:leading-[3.52rem] lg:max-w-[40rem]">
          Polynetica SMP Registration Package
        </span>
      </div>
      <div className="flex space-x-[2rem] w-full h-full relative max-w-[148rem]">
        <div className="flex items-center space-x-[2rem] absolute top-0 w-full left-[-25rem] z-[0] lg:top-[89rem] lg:space-x-[1.4rem] lg:left-[0rem]">
          <div className="w-[41rem] rounded-[4rem] h-[62.2rem] bg-[linear-gradient(270deg,rgba(255,255,255,0.04)_0%,rgba(12,12,14,0.00)_74.5%)] border-[0.1rem] border-solid border-white-100 backdrop-blur-[1.5rem] lg:rounded-[3rem] lg:rounded-l-none lg:h-[31rem]" />
          <TargetPackage name="Basic" type="Base" price="20" duoLevel="1" quattroLevel="-" />
          <div className="hidden lg:flex w-[41rem] rounded-[4rem] h-[62.2rem] bg-[linear-gradient(270deg,rgba(255,255,255,0.04)_0%,rgba(12,12,14,0.00)_74.5%)] border-[0.1rem] border-solid border-white-100 backdrop-blur-[1.5rem] lg:rounded-[3rem] lg:rounded-r-none lg:h-[31rem]" />
        </div>
        <div className="flex flex-col items-center space-y-[2rem] absolute top-[23rem] w-full left-[-10rem] z-[0] lg:flex-row lg:space-y-0 lg:space-x-[1.4rem] lg:top-[46.5rem] lg:left-auto lg:w-full lg:right-[-5rem]">
          <TargetPackage name="Pro" type="Middle" price="147" duoLevel="2" quattroLevel="3" />
          <div className="w-[41rem] rounded-[4rem] h-[62.2rem] bg-white-40 border-[0.1rem] border-solid border-white-100 backdrop-blur-[1.5rem] lg:h-[41rem]" />
        </div>
        <div className="flex items-end space-x-[2rem] absolute top-[-60rem] w-full right-[-84rem] lg:top-[-65rem] lg:pl-[2rem] lg:space-x-[1.4rem] lg:right-[-0rem]">
          <div className="flex flex-col space-y-[2rem]">
            <div className="w-[41rem] rounded-[4rem] h-[62.2rem] bg-white-20 border-[0.1rem] border-solid border-white-100 backdrop-blur-[1.5rem] lg:w-[30.7rem]" />
            <TargetPackage name="Elite" type="Popular" price="1030" duoLevel="6" quattroLevel="8" />
          </div>
          <div className="w-[41rem] rounded-[4rem] h-[62.2rem] bg-white-20 border-[0.1rem] border-solid border-white-100 backdrop-blur-[1.5rem] lg:w-full lg:h-[46.4rem] lg:right-[-2rem] lg:rounded-[3rem] lg:rounded-r-none " />
        </div>
      </div>
      <div className="absolute bottom-0 rotate-[180deg] h-[28.3rem] w-full bg-[linear-gradient(180deg,#0C0C0E_0%,rgba(12,12,14,0.00)_100%)] lg:hidden" />
      <div className="absolute top-0 h-[41.6rem] w-full bg-[linear-gradient(180deg,#0C0C0E_12.6%,rgba(12,12,14,0.00)_100%)]"></div>
      <img className="absolute right-0 top-0 z-[0]" src="/images/target/Registration/shadow.webp" />
    </div>
  );
};
