import React from 'react';
import { Trans } from 'react-i18next';

export const Period = () => {
  const daysTable = [
    {
      days: 7,
      value: '—',
    },
    {
      days: 30,
      value: 10,
    },
    {
      days: 90,
      value: 20,
    },
    {
      days: 180,
      value: 30,
    },
  ];
  return (
    <div className="flex flex-col space-y-[2.4rem] p-[2rem] rounded-[2rem] bg-white-30 w-full lg:p-[1.6rem]">
      <div className="flex flex-col space-y-[2rem] lg:space-y-[1.6rem]">
        <div className="flex w-full justify-between items-center">
          <span className="text-[1.4rem] font-500 leading-[2.24rem] text-white-500 lg:text-[1.2rem] lg:leading-[1.92rem]">
            <Trans i18nKey="site.staking.modal.perioud.title">Staking Periods and APY</Trans>
          </span>
          <div className="flex items-center justify-center px-[1.6rem] h-[3.4rem] rounded-full bg-[rgba(81,246,137,0.10)] lg:h-[3.1rem]">
            <span className="text-[1.4rem] font-500 leading-[2.24rem] text-[#51F689] lg:text-[1.2rem] lg:leading-[1.92rem]">
              <Trans i18nKey="site.staking.modal.perioud.baseRate">APY Base Rate 24,5%</Trans>
            </span>
          </div>
        </div>
        <div className="w-full bg-white-100 h-[0.1rem]" />
      </div>
      <div className="flex flex-col space-y-[1.2rem]">
        {daysTable.map((item, itemIndex) => {
          return (
            <div
              className="relative flex items-center justify-between w-full text-[1.4rem] font-500 leading-[2.24rem]"
              key={itemIndex}
            >
              <span className="text-white">{item?.days} days</span>
              <span className="text-white-500 absolute left-1/2 -translate-x-1/2">
                <Trans i18nKey="site.staking.modal.perioud.baseApy">Base APY</Trans>
              </span>
              {itemIndex === 0 ? (
                <span className="text-white-500">-</span>
              ) : (
                <span className="text-[#51F689]">+ {item?.value}%</span>
              )}
            </div>
          );
        })}
      </div>
      <div className="flex items-center px-[2rem] h-[6.2rem] bg-white-50 rounded-[1.2rem] lg:text-left lg:px-[1.6rem] lg:h-[7.6rem]">
        <span className="text-[1.4rem] text-white-500 font-500 leading-[2.24rem]">
          <Trans i18nKey="site.staking.modal.perioud.example">
            Example: Base APY = 50%. For 180 days: 50% + 30% = 65% APY.
          </Trans>
        </span>
      </div>
    </div>
  );
};
