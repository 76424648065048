import React, { useState, useEffect } from 'react';
import { BaseLayout } from 'layouts';
import { EnergyInfo } from '../../features/energy/EnergyInfo';
import { StakesList } from '../../features/energy/StakesList';
import { useEnergyNft } from '../../helpers/hooks/useEnergyNft';
import { useWeb3React } from '@web3-react/core';

import { Button } from '../../components/Button';
import { WithoutNft } from '../../features/energy/States/WithoutNft';
import energyActiveIcon from '../../assets/icons/menu/active/energyIcon.svg';

export const Energy = () => {
  const { account } = useWeb3React();
  const [choosedNftId, setChoosedNftId] = useState(null);
  const [isUpdateWeek, setIsUpdateWeek] = useState(false);
  const [isLoadingState, setIsLoadingState] = useState(false);
  const {
    getAvailableNft,
    availableToMint,
    weekNfts,
    balanceNft,
    getBalanceNft,
    getUnclaimedReward,
    stakeNfts,
    getNftOnWeek,
  } = useEnergyNft();

  useEffect(() => {
    if (account) {
      getNftOnWeek();
      getAvailableNft();
      getBalanceNft();
      getUnclaimedReward();
    }
  }, [account]);

  useEffect(() => {
    if (balanceNft) {
      const initialChoose = balanceNft.findIndex((item) => item > 0);
      setChoosedNftId(initialChoose + 1);
    }
  }, [balanceNft]);

  const updateBalance = () => {
    getBalanceNft();
    setIsUpdateWeek(true);
  };

  const [totalNft, setTotalNft] = useState(0);

  useEffect(() => {
    if (balanceNft.length) {
      let total = balanceNft?.reduce((item, acc) => {
        return acc + item;
      }, 0);

      setTotalNft(total);
    }
  }, [balanceNft]);

  const isHaveStakedNft =
    (weekNfts && weekNfts?.current?.some((item) => item > 0)) || weekNfts?.next?.some((item) => item > 0);

  useEffect(() => {
    if (totalNft || isHaveStakedNft) {
      setIsLoadingState(true);
    } else {
      setIsLoadingState(false);
    }
  }, [totalNft, isHaveStakedNft, account]);

  return (
    <BaseLayout className="lg:!bg-[#0C0C0E] lg:justify-between">
      <div className="flex flex-col relative lg:space-y-[2.4rem] lg:justify-start">
        <div className="hidden lg:flex flex items-center space-x-[1.2rem] mt-[2rem] lg:px-[1.6rem]">
          <img src={energyActiveIcon} />
          <span className="text-[2.4rem] font-500 text-white">Energy</span>
        </div>
        {/* <Button onClick={() => claimNfts()}> Claim NFT</Button> */}
        {isLoadingState ? (
          <>
            <div className="flex flex-col w-full space-y-[8rem] lg:px-[1.6rem] lg:space-y-[2.4rem]">
              <EnergyInfo
                setChoosedNftId={setChoosedNftId}
                choosedNftId={choosedNftId}
                balanceNft={balanceNft}
                updateBalance={updateBalance}
                stakeNfts={stakeNfts}
              />
              <StakesList isUpdateWeek={isUpdateWeek} setIsUpdateWeek={setIsUpdateWeek} />
            </div>
            <img
              className="lg:hidden absolute top-[-2rem] z-[0] left-[10rem] lg:left-0"
              src="/images/energy/shadow.webp"
            />
          </>
        ) : (
          <WithoutNft />
        )}
        <img className="hidden lg:flex absolute top-[-2.4rem] w-full lg:h-full" src="/images/energy/mobShadow.webp" />
      </div>
    </BaseLayout>
  );
};
