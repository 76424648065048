import React from 'react';
import { useSelector } from 'react-redux';
import { getUser } from '../../../store/userSlice/selectors';
import { shortenAddress } from '../../../helpers/format';
import { copy } from '../../../helpers/copy';

export const Preview = () => {
  const currentUser = useSelector(getUser);
  const avatar = localStorage.getItem('avatar');
  const nickname = localStorage.getItem('nickname');

  const name = 'Ishad Rashad';

  const firstLetterNick = nickname
    ? nickname
        .split(' ')
        .map((word) => word[0])
        .join('')
    : '';

  const styleBg =
    avatar !== 'null'
      ? {
          backgroundImage: `url(${avatar})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }
      : {
          backgroundColor: '#8247E5',
        };

  return (
    <div className="flex flex-col items-center justify-center h-full bg-black-300 p-[4rem] relative rounded-[2rem] max-w-[32.9rem] lg:hidden">
      <div className="flex flex-col items-center space-y-[4.8rem] w-full">
        <div className="flex items-center justify-center w-[17rem] h-[17rem] rounded-full border-[0.8rem] border-white-100 border-solid">
          <div style={styleBg} className="flex items-center justify-center rounded-full w-full h-full">
            {firstLetterNick && (!avatar || avatar === 'null') && (
              <span className="text-[8rem] text-white font-500 uppercase">{firstLetterNick}</span>
            )}
          </div>
        </div>
        <div className="w-full flex items-center justify-center flex-col space-y-[1.2rem] lg:space-y-[2rem]">
          <div className="flex flex-col items-center space-y-[2rem]">
            <div className="items-center justify-center bg-[rgba(175,127,255,0.10)] rounded-[2rem] px-[1.2rem] h-[2.8rem] lg:h-[2.6rem]">
              <span className="text-[1.6rem] text-[#AF7FFF] font-500 leading-[3rem] lg:text-[1.4rem]">
                id{currentUser?.internalId}
              </span>
            </div>
            {!!nickname && (
              <div className="flex flex-col items-center space-y-[2rem] lg:space-y-0">
                <span className="text-center text-[3.2rem] font-500 text-white leading-none lg:text-[2.4rem]">
                  {nickname}
                </span>
              </div>
            )}

            <div
              onClick={() => copy(currentUser?.referral?.id)}
              className="flex flex-col items-center space-y-[1.2rem] text-[1.2rem] font-500"
            >
              <span className="text-white-500">Your Upline </span>
              <span className="text-[#985AFF]">id{shortenAddress(currentUser?.referral?.id)} </span>
            </div>
          </div>
          {/* <span className="text-[1.2rem] text-white-500 leading-[1.68rem] text-center w-full lg:hidden">
            You can also share your referral link to your friends or sharing it on social media.
          </span> */}
        </div>
      </div>
      <div className="absolute top-[2rem] left-[2rem] flex items-center justify-center h-[2.6rem] px-[1.2rem] bg-white-50 rounded-[2rem]">
        <span className="text-white-500 text-[1.4rem] font-400">Preview</span>
      </div>
    </div>
  );
};
