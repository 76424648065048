import React from 'react';
import greenCheckIcon from '../../../../assets/icons/landing/loginBanner/roadMap/greenCheckIcon.svg';
import purpleCheckIcon from '../../../../assets/icons/landing/loginBanner/roadMap/purpleCheckIcon.svg';
import socialMiningIcon from '../../../../assets/icons/landing/loginBanner/roadMap/socialMiningIcon.svg';
import tiersIcon from '../../../../assets/icons/landing/loginBanner/roadMap/tiersIcon.svg';
import publicSaleIcon from '../../../../assets/icons/landing/loginBanner/roadMap/publicSaleIcon.svg';
import dexIcon from '../../../../assets/icons/landing/loginBanner/roadMap/dexIcon.svg';

export const RoadMap = () => {
  const roadMapList = [
    {
      title: 'Social Mining',
      icon: socialMiningIcon,
      state: 'now',
    },
    {
      title: '$POLLY on DEX',
      icon: dexIcon,
      state: 'now',
    },
    {
      title: '$POLLY Public Sale',
      icon: publicSaleIcon,
      state: 'complete',
    },
    {
      title: '$POLLY Tiers',
      icon: tiersIcon,
      state: 'complete',
    },
  ];
  return (
    <div className="flex space-x-[2rem] items-center justify-between w-full lg:flex-col lg:space-x-0 lg:space-y-[1.2rem] lg:px-[2rem] lg:pt-[4rem]">
      {roadMapList.map((item, itemIndex) => {
        const state =
          item?.state === 'now' ? (
            <div className="flex items-center space-x-[0.4rem]">
              <span className="text-[1.4rem] font-500 leading-[1.96rem] text-[#CEFA2F]">Now</span>
              <img src={greenCheckIcon} />
            </div>
          ) : (
            <div className="flex items-center space-x-[0.4rem]">
              <span className="text-[1.4rem] font-500 leading-[1.96rem] text-[#985AFF]">Complete</span>
              <img src={purpleCheckIcon} />
            </div>
          );

        return (
          <div
            className={`flex items-center w-full space-x-[2rem] w-full rounded-[3rem] h-[10rem] p-[1rem] border-[0.1rem] border-solid lg:rounded-[2rem] lg:h-[8rem] ${
              itemIndex === 0 ? 'bg-primary border-white-500' : 'bg-white-70 border-white-100'
            }`}
            key={itemIndex}
          >
            <div
              className={`flex items-center justify-center h-full w-[8rem] rounded-[2rem] lg:w-[6rem] lg:rounded-[1.2rem] p-[1.2rem] ${
                (itemIndex === 0 && 'bg-[rgba(2,2,4,0.50)]') ||
                (itemIndex === 1 && 'bg-[#985AFF]') ||
                (itemIndex > 1 && 'bg-white-100')
              }`}
            >
              <img src={item?.icon} />
            </div>
            <div className="flex flex-col space-y-[0.4rem]">
              <span className="text-[1.8rem] font-600 leading-[2.52rem] text-white">{item?.title}</span>
              {state}
            </div>
          </div>
        );
      })}
    </div>
  );
};
