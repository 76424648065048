import React, { useEffect } from 'react';
import { RegisterLayout } from '../../layouts';
import { Plans } from '../../features/register/plans';
import { useSearchParams } from 'react-router-dom';
import { setCookie } from 'nookies';

export const Register = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const refKey = searchParams.get('refKey') || false;

  useEffect(() => {
    if (refKey) {
      setCookie(null, 'polynetica_upline_key', refKey, {
        maxAge: 30 * 24 * 60 * 60,
        path: '/',
      });
    }
  }, [refKey]);

  return (
    <RegisterLayout>
      <Plans />
    </RegisterLayout>
  );
};
