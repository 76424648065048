import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { shortenAddress } from '../../helpers/format';
import notificationIcon from '../../assets/icons/header/notificationIcon.svg';
import { useSelector } from 'react-redux';
import { getBalance } from '../../store/walletSlice/selectors';
import WalletIcon from '../../assets/icons/main/wallet.svg';
import ArrowDownIcon from '../../assets/icons/main/arrowDownMini.svg';
import { WalletWidget } from '../../features/landing/Header/WalletWidget';
import { ButtonLanding } from '../Landing/ButtonLanding';
import { Trans } from 'react-i18next';
import { useModal } from '../../helpers/hooks/useModal';
import { ActivateModal } from '../Modals/ActivateModal';
import { useLocation } from 'react-router-dom';
import energyActiveIcon from '../../assets/icons/menu/active/energyIcon.svg';
import dashboardActiveIcon from '../../assets/icons/menu/active/dashboardIcon.svg';
import statisticsActiveIcon from '../../assets/icons/menu/active/statisticsIcon.svg';
import { PreviewModeButton } from '../PreviewModeButton';
import { getUser } from '../../store/userSlice/selectors';

export const Header = () => {
  const { account } = useWeb3React();
  const currentUser = useSelector(getUser);

  const [isOpenedWalletDesc, setIsOpenedWalletDesc] = useState(false);

  const { polly: balancePolly } = useSelector(getBalance);

  const { openedModal, onOpen, onClose } = useModal();

  const openWalletDesc = () => {
    setIsOpenedWalletDesc((value) => !value);
  };

  const location = useLocation();

  const refWallet = useRef();

  const connectWalletBtn = () => {
    return (
      <ButtonLanding onClick={() => onOpen()} type="white" className="h-[4rem] !border-white whitespace-nowrap">
        <Trans i18nKey="main.connectWallet"></Trans>
      </ButtonLanding>
    );
  };

  let icon = null;
  let text = '';

  switch (location.pathname) {
    case '/dashboard':
      icon = dashboardActiveIcon;
      text = 'Dashboard';
      break;
    case '/stats':
      icon = statisticsActiveIcon;
      text = 'Statistics';
      break;
    case '/energy':
      icon = energyActiveIcon;
      text = 'Energy';
      break;
    case '/profile':
      icon = null;
      text = 'Edit profile';
      break;
  }

  const walletState = useMemo(() => {
    if (account) {
      return (
        <button
          onClick={() => openWalletDesc()}
          className="custom-transition py-[0.8rem] px-[1.2rem] flex items-center justify-center space-x-[1rem] bg-white-40 hover:bg-white-70 border border-white-50 rounded-[1.2rem]"
        >
          <div className="h-full flex items-center justify-center">
            {balancePolly > 0 && (
              // <div className="flex items-center space-x-[1rem] bg-[rgba(152,90,255,0.10)] rounded-[0.8rem] h-[2.8rem] px-[0.8rem]">
              //   <span className="text-[1.4rem] font-500 text-[#985AFF]">Add $POLLY</span>
              //   <div className="bg-[#985AFF] h-[0.6rem] w-[0.6rem] rounded-full"></div>
              // </div>
              <div className="bg-[#985AFF] h-[0.6rem] w-[0.6rem] rounded-full"></div>
            )}
          </div>
          <img className="w-[2.4rem] h-[2.4rem]" src={WalletIcon} alt="" />
          <div className="lg:hidden  border border-white-100 h-[2rem]"></div>
          <div className="flex items-center justify-center space-x-[1rem]">
            <span className="text-white-500 text-[1.6rem] font-500 leading-[2.4rem]">{shortenAddress(account)}</span>
            <img
              className={`custom-transition w-[1.6rem] [h-1.6rem] opacity-50 ${isOpenedWalletDesc ? 'rotate-180' : ''}`}
              src={ArrowDownIcon}
              alt=""
            />
          </div>
        </button>
      );
    } else {
      return connectWalletBtn();
    }
  }, [account, isOpenedWalletDesc, balancePolly]);

  const styleBg =
    currentUser?.avatar !== 'null'
      ? {
          backgroundImage: `url(${currentUser?.avatar})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }
      : {
          backgroundColor: '#8247E5',
        };

  return (
    <div className="flex justify-between items-center w-full h-[8rem] border-b-[0.1rem] border-solid border-[#161616] p-[2rem] pr-[4rem] lg:hidden">
      <div className="flex space-x-[1.2rem]">
        <img src={icon} />
        <span className="text-[2.4rem] text-white font-500 leading-[2.4rem]">{text}</span>
      </div>
      <div className="flex items-center space-x-[2.4rem] h-full">
        <PreviewModeButton />
        <div className="flex space-x-[1.2rem] h-full">
          {/* <div className="flex items-center h-full space-x-[1rem] p-[0.8rem] pl-[1.2rem] bg-white-40 rounded-[1.2rem] border-[0.1rem] border-white-50 border-solid custom-transition hover:bg-white-70">
            <img src={notificationIcon} />
            <div className="flex items-center justify-center rounded-[0.6rem] bg-white-50 h-full px-[0.8rem]">
              <span className="text-[1.2rem] leading-[2.4rem] text-white opacity-50 font-400">2 new</span>
            </div>
          </div> */}
          <div className="flex lg:hidden z-[1]" ref={refWallet}>
            {walletState}
            <WalletWidget isOpened={isOpenedWalletDesc} onClose={() => setIsOpenedWalletDesc(false)} />
          </div>
        </div>
        {account?.toLocaleLowerCase() === currentUser?.id?.toLocaleLowerCase() && (
          <div className="flex items-center space-x-[1.2rem]">
            <div className="flex flex-col items-end space-y-[0.3rem]">
              <span className="text-[1.4rem] font-500 text-white">{currentUser?.nickname}</span>
              {/* <span className="text-white opacity-50 text-[1.2rem] font-500">XP {currentUser?.rank}</span> */}
            </div>
            <div
              style={styleBg}
              className="flex items-center justify-center w-[4rem] h-[4rem] bg-white rounded-full"
            ></div>
          </div>
        )}
      </div>
      <ActivateModal openedModal={openedModal} handleCloseModal={onClose} />
    </div>
  );
};
