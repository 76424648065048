import React from 'react';
import GreenArrow from '../../../../assets/icons/dashboard/greenArrow.svg';
import { getUnixTime } from 'date-fns';
import { bigIntToInt } from '../../../../helpers/numbers';

export const Statistics = ({ stats }) => {
  const todayNum = Math.floor(getUnixTime(new Date()) / (60 * 60 * 24));

  const dayProfit = () => {
    let sum = 0;
    if (stats?.x2LastTokens24Day === todayNum) {
      sum = sum + Number(stats?.x2TotalTokens24);
    }
    if (stats?.x4LastTokens24Day === todayNum) {
      sum = sum + Number(stats?.x4TotalTokens24);
    }

    return (sum / 1e18).toFixed(0) || 0;
  };

  const statisticsList = [
    {
      title: 'Users',
      value: stats?.totalUsers / 2,
      newValue: Number(stats?.lastUsers24Day) === todayNum ? Number(stats?.totalUsers24) : 0,
    },
    {
      title: 'Total profit, POL',
      value: ((Number(stats?.x2TotalTokens) + Number(stats?.x4TotalTokens)) / 1e18).toFixed(0),
      newValue: dayProfit(),
    },
  ];

  return (
    <div className="flex items-center space-x-[2rem] lg:flex-col lg:space-x-0 lg:space-y-[1.2rem]">
      {statisticsList.map((item, itemIndex) => {
        return (
          <div
            className="flex items-center justify-between w-full h-[7.2rem] px-[2rem] rounded-[2rem] border-[0.1rem] border-solid border-white-50 bg-black-300 lg:h-full lg:flex-col lg:items-start lg:py-[2rem] lg:space-y-[1.2rem] lg:bg-white-30"
            key={itemIndex}
          >
            <span className="text-[1.4rem] font-500 text-white-500">{item?.title}</span>
            <div className="flex items-center space-x-[1rem]">
              <span className="text-[2rem] font-600 text-white leading-normal">{item?.value}</span>
              {item?.newValue && (
                <div className="flex items-center justify-center space-x-[0.5rem]">
                  <img src={GreenArrow} />
                  <span className="text-[#51F689] text-[1.4rem] font-400 leading-none">{item?.newValue}</span>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
