import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import PolIcon from '../../../../assets/icons/main/polCoin.svg';
import DuoIcon from '../../../../assets/icons/dashboard/programs/programCard/duoIcon.svg';
import QuattroIcon from '../../../../assets/icons/dashboard/programs/programCard/quattroIcon.svg';

import { useUpgradeLvl } from '../../../../helpers/hooks/useUpgradeLvl';
import { PROGRAM_NAMES, PROGRAM_PRICES_WEI, PROGRAM_FEE_WEI } from '../../../../helpers/constants';
import { Button } from '../../../../components/Button';
import { useLocation } from 'react-router-dom';
import { useCallTransaction } from '../../../../helpers/hooks/useCallTransaction';
import { usePreviewMode } from '../../../../helpers/hooks/useCheckPreviewMode';
import { round } from '../../../../helpers/numbers';

export const ProgramCard = ({ type, profit = 0, maxActiveLevel = 0, updateInfo }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const maxLevel = 15;
  const percentProgress = ((maxActiveLevel / maxLevel) * 100).toFixed(0) || 0;
  const { upgradeLvl } = useUpgradeLvl();
  const { onCallTransaction, transactionInfo, resetTransactionInfo } = useCallTransaction();
  const { previewUserAddress } = usePreviewMode();

  useEffect(() => {
    if (transactionInfo.isSuccess) {
      updateInfo();
      resetTransactionInfo();
    }
  }, [transactionInfo]);

  const upgradeClick = async (levelsX2, levelsX4) => {
    try {
      const result = await upgradeLvl(levelsX2, levelsX4);

      if (result) {
        onCallTransaction(result);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const levelsX2 = type === PROGRAM_NAMES.X2 ? [maxActiveLevel + 1] : [];
  const levelsX4 = type === PROGRAM_NAMES.X4 ? [maxActiveLevel + 1] : [];

  const totalPrice =
    (Number(PROGRAM_PRICES_WEI[type][maxActiveLevel]) + Number(PROGRAM_FEE_WEI[type][maxActiveLevel])) / 1e18;

  return (
    <div className="relative flex flex-col space-y-[4.8rem] items-center dashboard-block-styles p-[2rem] lg:w-[89%] lg:shrink-0 lg:space-y-[5.6rem]">
      <Link to={`/dashboard/${type.toLowerCase()}?${params.toString()}`} className="flex justify-between w-full ">
        <div className="flex items-center space-x-[1.2rem]">
          <img className="w-[2.8rem] h-[2.8rem]" src={type === PROGRAM_NAMES.X2 ? DuoIcon : QuattroIcon} />
          <span className="text-[1.8rem] font-600 text-white lg:text-[1.6rem]">{type} Program</span>
        </div>
        <div className="flex items-center space-x-[0.8rem]">
          <span className="text-[2.4rem] text-white font-600 leading-[5.76px] lg:text-[2rem]">
            {round(Number(profit) / 1e18)}
          </span>
          <img className="w-[2rem] h-[2rem]" src={PolIcon} />
        </div>
      </Link>
      <div className="flex flex-col space-y-[2.4rem] w-full z-[11]">
        <div className="flex flex-col space-y-[1.6rem] w-full">
          <div className="flex items-center justify-between w-full lg:flex-col-reverse lg:items-start lg:space-y-reverse lg:space-y-[2.4rem]">
            <div className="flex items-center space-x-[1.2rem] ">
              <span className="text-[3.2rem] text-white font-600 leading-[7.68px]">{percentProgress}%</span>
              <span className="text-[1.2rem] text-white-500 tracking-[-0.24px] text-left">
                Total
                <br /> activity
              </span>
            </div>
            <div className="grid grid-cols-6 grid-rows-2 items-center justify-center">
              {new Array(maxLevel).fill({}).map((item, itemIndex) => {
                const isActive = itemIndex < maxActiveLevel;
                const isFirstAfterActive = itemIndex === maxActiveLevel;
                return (
                  <div className="flex justify-center items-center w-[2.5rem] h-[2.5rem]">
                    <div
                      //сделал бы объектом
                      key={itemIndex}
                      className={`rounded-full ${
                        isActive
                          ? `w-[1.8rem] h-[1.8rem] ${type === PROGRAM_NAMES.X2 ? 'bg-[#51F689]' : 'bg-[#F1A13B]'}`
                          : isFirstAfterActive
                          ? `w-[1.4rem] h-[1.4rem] ${
                              type === PROGRAM_NAMES.X2 ? 'bg-[rgba(81,246,137,0.30)]' : 'bg-[rgba(241,161,59,0.30)]'
                            }`
                          : 'bg-white-100 w-[1.2rem] h-[1.2rem]'
                      }`}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex items-center space-x-[0.4rem] w-full">
            <div
              style={{ width: `${percentProgress}%` }}
              className={`h-[0.8rem] ${
                type === PROGRAM_NAMES.X2 ? 'bg-[#51F689]' : 'bg-[#F1A13B]'
              } bg-[#51F689] rounded-[2rem]`}
            />
            <div className="h-[0.8rem] bg-white-100 rounded-[2rem] flex-1" />
          </div>
        </div>
        <div className="flex items-center space-x-[1.2rem] w-full">
          <Link
            to={`/dashboard/${type.toLowerCase()}?${params.toString()}`}
            className="flex items-center justify-center h-[4rem] w-full"
          >
            <Button type="black" className="h-full w-full">
              <span className="text-[1.4rem] font-500 leading-[1.96rem]">View</span>
            </Button>
          </Link>
          {maxActiveLevel !== maxLevel && (
            <Button
              onClick={() => upgradeClick(levelsX2, levelsX4)}
              type={type === PROGRAM_NAMES.X2 ? 'green' : 'orange'}
              className={`h-[4rem] w-full flex-col`}
              loadingType="black"
              loading={transactionInfo.isWaiting}
              disabled={transactionInfo.isWaiting || previewUserAddress}
            >
              <span className="text-[1.3rem] font-500 leading-[1.6rem]">Activate</span>
              <div className="flex items-center justify-center space-x-[0.2rem] text-[1.2rem] font-500 leading-[1.5rem]">
                <span>for {totalPrice} </span>
                <img className="w-[1rem] h-[1rem]" src={PolIcon} />
              </div>
            </Button>
          )}
        </div>
      </div>
      <img
        className="absolute bottom-0 z-[0]"
        src={`/images/dashboard/programs/programCard/${type === PROGRAM_NAMES.X2 ? 'duoShadow' : 'quattroShadow'}.webp`}
      />
    </div>
  );
};
