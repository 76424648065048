import React from 'react';
// import { MobHeader } from '../../components/MobHeader';
import { Footer } from '../../features/landing/Footer';
import { Link } from 'react-router-dom';

export const RegisterLayout = ({ children }) => {
  return (
    <div className="relative flex flex-col bg-[#0C0C0E] items-center justify-start min-h-screen min-w-full overflow-hidden lg:bg-[#020204] lg:px-[1.6rem]">
      {/* <MobHeader className="!bg-[rgba(2,2,4,0.80)] !backdrop-blur-[1.5rem]" /> */}
      <Link className="absolute left-[6rem] top-[3rem] lg:hidden z-[11] cursor-pointer" to={'/'}>
        <img className="w-[15.3rem] m-[0.8rem] z-[1] " src="/images/layouts/logo.webp" />
      </Link>
      <div className="flex items-center justify-center w-full max-w-[134rem] my-auto z-[1] lg:justify-start lg:items-start lg:mb-0 lg:mt-[6rem]">
        {children}
      </div>
      <Footer className="!hidden lg:!flex " />
      <img className="absolute right-0 lg:hidden z-[0]" src="/images/layouts/register/rightShadow.webp" />
      <img className="absolute top-0 w-full hidden lg:flex z-[0]" src="/images/layouts/register/mobShadow.webp" />
      <img
        className="absolute bottom-0 w-full hidden lg:flex z-[0]"
        src="/images/layouts/register/mobBottomShadow.webp"
      />
    </div>
  );
};
