import { useEffect, useState } from 'react';
import { ethers } from 'ethers';

// ABI для метода getReserves
const UNISWAP_V2_PAIR_ABI = [
  'function getReserves() external view returns (uint112 reserve0, uint112 reserve1, uint32 blockTimestampLast)',
];

const useTokenPrice = () => {
  const [tokenAmount, setTokenAmount] = useState(null);

  const fetchTokenAmount = async (pairAddress, provider, maticAmount = 1, tokenDecimals = 18, maticDecimals = 18) => {
    // Создание экземпляра контракта пула Uniswap V2
    const pairContract = new ethers.Contract(pairAddress, UNISWAP_V2_PAIR_ABI, provider);

    try {
      // Получение резервов токенов в пуле
      const reserves = await pairContract.getReserves();
      const reserve0 = reserves.reserve0; // Резерв токена 0
      const reserve1 = reserves.reserve1; // Резерв токена 1 (MATIC)

      // Преобразование BigInt в числа (number)
      const reserve0Number = Number(reserve1);
      const reserve1Number = Number(reserve0);

      // Количество MATIC, которое мы хотим обменять (10 MATIC)
      const maticAmountInWei = maticAmount * 10 ** maticDecimals;

      // Формула обмена Uniswap V2: amountOut = (reserve0 * amountIn) / (reserve1 + amountIn)
      const tokenAmountInWei = (reserve0Number * maticAmountInWei) / (reserve1Number + maticAmountInWei);

      // Конвертация в читаемый формат с учетом decimals токена
      const tokenAmountFormatted = tokenAmountInWei / 10 ** tokenDecimals;

      setTokenAmount(tokenAmountFormatted);
      return tokenAmountFormatted;
    } catch (error) {
      console.error('Error fetching token amount:', error);
    }
  };

  return { tokenAmount, fetchTokenAmount };
};

export default useTokenPrice;
