import React from 'react';
import telegramIcon from '../../assets/icons/socials/telegramIcon.svg';
import youtubeIcon from '../../assets/icons/socials/youtubeIcon.svg';
import twitterIcon from '../../assets/icons/socials/twitterIcon.svg';
import discordIcon from '../../assets/icons/socials/discordIcon.svg';
import galxeIcon from '../../assets/icons/socials/galxeIcon.svg';
import redditIcon from '../../assets/icons/socials/redditIcon.svg';

import { socialLinks } from '../../helpers/socialLinks';
import { Link } from 'react-router-dom';

export const SocialLinksComp = ({ className }) => {
  return (
    <div className={`grid grid-cols-4 lg:grid-cols-6 gap-[1.375rem] w-fit ${className}`}>
      <Link className="custom-transition hover:opacity-80" target="blank" to={socialLinks.telegramChannel}>
        <img src={telegramIcon} />
      </Link>
      <Link className="custom-transition hover:opacity-80" target="blank" to={socialLinks.youtube}>
        <img src={youtubeIcon} />
      </Link>
      <Link className="custom-transition hover:opacity-80" target="blank" to={socialLinks.twitter}>
        <img src={twitterIcon} />
      </Link>
      <Link className="custom-transition hover:opacity-80" target="blank" to={socialLinks.discord}>
        <img src={discordIcon} />
      </Link>
      <Link className="custom-transition hover:opacity-80" target="blank" to={socialLinks.galxe}>
        <img src={galxeIcon} />
      </Link>
      <Link className="custom-transition hover:opacity-80" target="blank" to={socialLinks.reddit}>
        <img src={redditIcon} />
      </Link>
    </div>
  );
};
