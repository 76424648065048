import React from 'react';
import { ButtonLanding } from '../../../components/Landing/ButtonLanding';
import { Link } from 'react-router-dom';

export const Future = () => {
  return (
    <div className="flex flex-col items-center futureBg w-full h-[108.8rem] lg:h-[85rem]">
      <div className="flex flex-col items-center space-y-[12.6rem] z-[1] pt-[14rem] relative lg:space-y-[5.5rem]">
        <img className="h-[4.27rem] lg:h-[2.4rem]" src="/images/layouts/logo.webp" />
        <div className="flex flex-col items-center space-y-[6rem] lg:space-y-[3rem] z-[1]">
          <span className="text-white text-[9rem] font-600 leading-[9.9rem] max-w-[98rem] text-center lg:text-[4.2rem] lg:leading-[4.62rem] lg:px-[1rem]">
            The Future of Social Mining with Polynetica
          </span>
          <span className="text-[2.4rem] font-500 leading-[2.64rem] text-white-500 lg:text-[1.6rem] lg:leading-[1.76rem]">
            Duo & Quattro | <span className="text-white">Unlimited Earnings in Web3</span>
          </span>
          <Link to="/register">
            <ButtonLanding className="w-[20rem] h-[4.8rem]" type="white">
              <span className="text-black text-[1.4rem] leading-none">Join Now</span>
            </ButtonLanding>
          </Link>
        </div>
        <img
          className="absolute w-full top-[-10rem] z-[0] scale-[2] max-w-[180rem] lg:hidden"
          src="/images/target/Future/polly.webp"
        />
        <img
          className="hidden lg:flex absolute w-full z-[0] scale-[6] max-w-[40rem]"
          src="/images/target/Future/pollyMob.webp"
        />
      </div>
    </div>
  );
};
