import React, { useEffect, useState } from 'react';
import { Button } from '../../../components/Button';
import { useEnergyNft } from '../../../helpers/hooks/useEnergyNft';
import { useWeb3React } from '@web3-react/core';
import { useCallTransaction } from '../../../helpers/hooks/useCallTransaction';
import { useNavigate } from 'react-router-dom';
import { bigIntToInt } from '../../../helpers/numbers';
import GrayClosedIcon from '../../../assets/icons/generalIcons/grayClosedIcon.svg';
import { useSelector } from 'react-redux';
import { getBalance } from '../../../store/walletSlice/selectors';
import { callNotification } from '../../../helpers/notification';
import config from '../../../helpers/config';

export const NftBanner = ({ mob }) => {
  const navigate = useNavigate();
  const { account } = useWeb3React();
  const { getAvailableNft, claimNfts, availableToMint } = useEnergyNft();
  const { onCallTransaction, resetTransactionInfo, transactionInfo } = useCallTransaction();

  const [bannerClosed, setBannerClosed] = useState(false);

  const { polly: balancePolly } = useSelector(getBalance);

  useEffect(() => {
    if (account) {
      getAvailableNft();
    }
  }, [account]);

  const mintCount =
    !!availableToMint &&
    availableToMint.reduce((item, acc) => {
      return Number(acc) + Number(item);
    }, 0);

  const clickClaim = () => {
    if (balancePolly >= 1000000 || config.stand !== 'prod') {
      try {
        const res = claimNfts();

        if (res) {
          onCallTransaction(res);
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      callNotification({ type: 'error', message: 'Polly balance less than 1mln' });
    }
  };

  useEffect(() => {
    if (transactionInfo.isSuccess) {
      resetTransactionInfo();
      getAvailableNft();
      navigate('/enegry');
    }
  }, [transactionInfo]);

  return (
    <div
      className={`nft-banner-border relative min-h-[14.1rem] p-[4rem] flex items-center justify-between lg:flex-col lg:items-start lg:bg-white-30 rounded-[2rem] opacity-100 lg:space-y-[2rem] lg:p-[2rem] custom-transition ${
        !mintCount ? '!opacity-20 pointer-events-none' : ''
      } ${bannerClosed && '!hidden'} ${mob ? 'hidden lg:flex' : 'lg:hidden'} `}
    >
      <div className="flex flex-col items-start justify-center space-y-[1.2rem]">
        <div className="flex items-center justify-start space-x-[0.8rem]">
          <span className="text-[2.4rem] font-500 text-white-500 lg:text-[1.8rem]">You Can Claim</span>{' '}
          <span className="text-[2.4rem] font-600 text-white lg:text-[1.8rem]">{mintCount || 0} NFT</span>
        </div>
        <span className="text-[1.4rem] font-400 text-white-500 lg:text-[1.2rem] lg:max-w-[19.5rem]">
          Claim and stake them to unlock passive rewards.
        </span>
      </div>
      <div className="flex items-center justify-end">
        <Button
          loading={transactionInfo.isWaiting}
          loadingType="white"
          onClick={() => clickClaim()}
          type="gray"
          className="h-[4rem] w-[8.8rem] rounded-[3rem] text-[1.4rem] font-500"
        >
          Claim
        </Button>
      </div>
      <img className="absolute right-[15%] top-[-5rem] lg:hidden" src="/images/dashboard/nftsBanner.webp" />
      <img className="hidden lg:flex absolute right-[1rem] top-[-5rem]" src="/images/dashboard/nftsBannerMob.webp" />
      <button
        onClick={() => setBannerClosed(!bannerClosed)}
        className="z-[999] absolute right-[1rem] top-[1rem] custom-transition hover:opacity-80 lg:top-[-1rem]"
      >
        <img src={GrayClosedIcon} />
      </button>
    </div>
  );
};
