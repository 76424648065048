import React, { useState, useEffect } from 'react';
import { ProgramCard } from './ProgramCard';
import { useSelector } from 'react-redux';
import { getUser } from '../../../store/userSlice/selectors';
import { PROGRAM_NAMES } from '../../../helpers/constants';
import { useWeb3React } from '@web3-react/core';
import { useQuery } from '@apollo/client';
import { GET_MATRIX_DATA } from '../../../helpers/graphRequests';
import { useDispatch } from 'react-redux';
import { updateMatrixX2X4 } from '../../../store/matrixX2X4Slice';
import { getMatrixX2X4 } from '../../../store/matrixX2X4Slice/selectors';
import { usePreviewMode } from '../../../helpers/hooks/useCheckPreviewMode';

export const Programs = () => {
  const currentUser = useSelector(getUser);
  const matrixData = useSelector(getMatrixX2X4);
  const { account } = useWeb3React();
  const { previewUserAddress } = usePreviewMode();
  const [userAddress, setUserAddress] = useState(null);

  useEffect(() => {
    if (previewUserAddress) {
      setUserAddress(previewUserAddress?.toLocaleLowerCase());
    } else {
      setUserAddress(account?.toLocaleLowerCase());
    }
  }, [account, previewUserAddress]);

  const dispatch = useDispatch();

  const [isNeedToCall, setIsNeedToCall] = useState(false);
  const [isFirstCall, setIsFirstCall] = useState(true);

  useEffect(() => {
    if (userAddress) {
      setIsNeedToCall(true);
      setIsFirstCall(false);
    }
  }, [userAddress]);

  const updateInfo = () => {
    if (!isFirstCall) {
      setIsNeedToCall(true);
    }
  };

  const { data, error, loading, called, refetch } = useQuery(GET_MATRIX_DATA, {
    variables: { user: userAddress },
    skip: !isNeedToCall,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (data) {
      if (!!data?.user?.levelsX2 || !!data?.user?.levelsX4) {
        dispatch(
          updateMatrixX2X4({
            loading: loading,
            called: called,
            levelsX2: data?.user?.levelsX2,
            levelsX4: data?.user?.levelsX4,
          }),
        );
      }
    }
  }, [data, isNeedToCall]);

  const maxActiveLevelX2 = matrixData?.levelsX2?.reduce((acc, item) => {
    if (!!item.isActivated) {
      return (acc = acc + 1);
    }
    return acc;
  }, 0);

  const maxActiveLevelX4 = matrixData?.levelsX4?.reduce((acc, item) => {
    if (!!item.isActivated) {
      return (acc = acc + 1);
    }
    return acc;
  }, 0);

  const user_maxActiveLevelX2 = currentUser?.levelsX2?.reduce((acc, item) => {
    if (!!item.isActivated) {
      return (acc = acc + 1);
    }
    return acc;
  }, 0);

  const user_maxActiveLevelX4 = currentUser?.levelsX4?.reduce((acc, item) => {
    if (!!item.isActivated) {
      return (acc = acc + 1);
    }
    return acc;
  }, 0);

  return (
    <div className="flex flex-col space-y-[2.4rem] p-[2rem] rounded-[3rem] border-[0.1rem] border-solid border-white-50 bg-white-40 backdrop-blur-[1.5rem] lg:bg-transparent lg:border-none lg:p-0">
      <span className="text-[2.4rem] font-500 tracking-[-0.48px] text-white lg:px-[1.6rem] ">Polynetica Programs</span>
      <div className="grid grid-cols-2 gap-[2rem] lg:grid-cols-1 lg:w-full lg:flex lg:overflow-auto lg:w-full hide-scroll lg:gap-[1.2rem] lg:pl-[1.6rem] lg:pr-[1.6rem]">
        <ProgramCard
          type={PROGRAM_NAMES.X2}
          profit={currentUser?.totalRewardX2}
          maxActiveLevel={user_maxActiveLevelX2 >= maxActiveLevelX2 ? user_maxActiveLevelX2 : maxActiveLevelX2}
          updateInfo={() => updateInfo()}
        />
        <ProgramCard
          type={PROGRAM_NAMES.X4}
          profit={currentUser?.totalRewardX4}
          maxActiveLevel={user_maxActiveLevelX4 >= maxActiveLevelX4 ? user_maxActiveLevelX4 : maxActiveLevelX4}
          updateInfo={() => updateInfo()}
        />
      </div>
    </div>
  );
};
