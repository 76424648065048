import { contractRouterAbi } from './contractsAbi/router';
import { contractMatrixBaseAbi } from './contractsAbi/matrixBase';
import { contractNftPassAbi } from './contractsAbi/nftPassAbi';
import { contractNftPromoAbi } from './contractsAbi/nftPromo';
import { contractTierSaleAbi } from './contractsAbi/tierSale';
import { pancakeSwapAbi } from './contractsAbi/pancakeSwap';
import { pollyTokenAbi } from './contractsAbi/pollyToken';
import { contractStakingAbi } from './contractsAbi/stakingAbi';
import { energyPoolAbi } from './contractsAbi/energyPoolAbi';
import { energyNftAbi } from './contractsAbi/energyNftAbi';

export default {
  allowedChainId: Number(process.env.REACT_APP_ALLOWED_CHAIN_ID),
  apiQraph: process.env.REACT_APP_GRAPH_URL,
  apiUrl: process.env.REACT_APP_API_URL,
  stand: process.env.REACT_APP_STAND,

  contractRouter: process.env.REACT_APP_CONTRACT_ROUTER,
  contractRouterAbi: contractRouterAbi,

  contractMatrixX2X4: process.env.REACT_APP_CONTRACT_MATRIX_X2X4,
  contractMatrixX2X4Abi: contractMatrixBaseAbi,

  contractNftPass: process.env.REACT_APP_CONTRACT_NFT_PASS,
  contractNftPassAbi: contractNftPassAbi,

  contractNftPromo: process.env.REACT_APP_CONTRACT_NFT_PROMO,
  contractNftPromoAbi: contractNftPromoAbi,

  contractTiersSale: process.env.REACT_APP_CONTRACT_TIERS_SALE,
  contractTiersSaleAbi: contractTierSaleAbi,

  contractPancake: process.env.REACT_APP_PANCAKE,
  contractPancakeAbi: pancakeSwapAbi,

  contractPollyToken: process.env.REACT_APP_CONTRACT_POLLY_TOKEN,
  contractPollyTokenAbi: pollyTokenAbi,

  contractPollyPool: process.env.REACT_APP_CONTRACT_POLLY_POOL,

  contractStPollyToken: process.env.REACT_APP_CONTRACT_ST_POLLY_TOKEN,
  contractStPollyTokenAbi: pollyTokenAbi,

  contractStaking: process.env.REACT_APP_CONTRACT_STAKING,
  contractStakingAbi: contractStakingAbi,

  contractEnergyNft: process.env.REACT_APP_CONTRACT_ENERGY_NFT,
  contractEnergyNftAbi: energyNftAbi,

  contractEnergyPool: process.env.REACT_APP_CONTRACT_ENERGY_POOL,
  contractEnergyPoolAbi: energyPoolAbi,
};
