import React, { useEffect, useState } from 'react';
import { PlanCard } from './planCard';
import { REGISTER_PACKS, PROGRAM_PRICES_WEI, PROGRAM_NAMES, PROGRAM_FEE_WEI } from '../../../helpers/constants';

export const Plans = () => {
  const [openPlan, setOpenPlan] = useState(null);

  const basicBenefits = ['Polynetica SMP Dashboard', '+800 XP'];
  const proBenefits = [
    'Polynetica SMP Dashboard',
    '+7200 XP',
    'Account Rank: 7',
    'PRO Tool Access',
    'Mentorship Platform',
    'Premium Support (Care Dept.)',
  ];
  const popularBenefits = [
    'Polynetica SMP Dashboard',
    '+45600 XP',
    'Account Rank: 18',
    'PRO Tool Access',
    'Mentorship Platform',
    'Premium Support (Care Dept.)',
    'Elite Business Community Access',
    'Elite Royalty Performance Programs',
    'Private meeting with Polynetica Сreators',
  ];

  const handleClick = (id) => {
    if (openPlan === id) {
      setOpenPlan(null);
    } else {
      setOpenPlan(id);
    }
  };

  const planPrice = (id = 1) => {
    let totalX2 = 0;
    let totalX4 = 0;

    Object.values(PROGRAM_PRICES_WEI[PROGRAM_NAMES.X2]).map((item, itemIndex) => {
      if (itemIndex < REGISTER_PACKS?.[id][PROGRAM_NAMES.X2]) {
        totalX2 = totalX2 + item;
      }
    });

    Object.values(PROGRAM_PRICES_WEI[PROGRAM_NAMES.X4]).map((item, itemIndex) => {
      if (itemIndex < REGISTER_PACKS?.[id][PROGRAM_NAMES.X4]) {
        totalX4 = totalX4 + item;
      }
    });

    return totalX2 + totalX4;
  };

  const planCommision = (id = 1) => {
    let totalX2 = 0;
    let totalX4 = 0;

    Object.values(PROGRAM_FEE_WEI[PROGRAM_NAMES.X2]).map((item, itemIndex) => {
      if (itemIndex < REGISTER_PACKS?.[id][PROGRAM_NAMES.X2]) {
        totalX2 = totalX2 + item;
      }
    });

    Object.values(PROGRAM_FEE_WEI[PROGRAM_NAMES.X4]).map((item, itemIndex) => {
      if (itemIndex < REGISTER_PACKS?.[id][PROGRAM_NAMES.X4]) {
        totalX4 = totalX4 + item;
      }
    });

    return totalX2 + totalX4;
  };

  const plans = [
    {
      name: 'Basic',
      type: 'Base',
      color: 'white-100',
      textColor: 'white-500',
      duoLevel: '1',
      quattroLevel: '—',
      benefitsList: basicBenefits,
    },
    {
      name: 'Pro',
      type: 'Middle',
      color: 'white-100',
      textColor: 'white-500',
      duoLevel: '2',
      quattroLevel: '3 LVL',
      benefitsList: proBenefits,
    },
    {
      name: 'Elite',
      type: 'Popular',
      color: '[#985AFF]',
      shadow: 'shadow-[0px_5px_20px_-6px_rgba(152,90,255,0.90)]',
      textColor: 'white',
      duoLevel: '6',
      quattroLevel: '8 LVL',
      benefitsList: popularBenefits,
    },
  ];

  return (
    <div className="flex flex-col space-y-[5.4rem] items-center py-[4.8rem] justify-center w-full lg:space-y-[4.8rem] lg:py-0">
      {openPlan === null && (
        <div className="flex flex-col items-center justify-center space-y-[2.4rem] text-center register-plan-animation lg:space-y-[1.2rem]">
          <span className="text-[5.6rem] font-600 text-white tracking-[-1.12px] lg:text-[3.2rem] lg:tracking-[-0.64px]">
            Choose a plan
          </span>
          <span className="text-[1.8rem] text-white-500 font-500 leading-[2.88rem] max-w-[45.4rem] lg:text-[1.6rem] lg:leading-[2.56rem] lg:max-w-full lg:px-[5rem]">
            Select a plan to start working in Polynetica.
          </span>
        </div>
      )}
      <div className="flex items-center justify-center space-x-[2rem] w-full lg:flex-col lg:flex-col-reverse lg:space-x-0 lg:space-y-[1.2rem]">
        {plans.map((item, itemIndex) => {
          if (openPlan === null || openPlan === itemIndex) {
            return (
              <PlanCard
                itemIndex={itemIndex}
                price={planPrice(itemIndex)}
                fee={planCommision(itemIndex)}
                id={itemIndex}
                chosePlan={openPlan === itemIndex}
                onClick={handleClick}
                {...item}
              />
            );
          }
        })}
      </div>
    </div>
  );
};
