import React from 'react';
import { Trans } from 'react-i18next';

export const DepositRules = () => {
  const rulesList = [
    {
      title: <Trans i18nKey="site.staking.modal.rules.title1">Less than 5M $POLLY</Trans>,
      rate: <Trans i18nKey="site.staking.modal.rules.rate1">Base rate -30%.</Trans>,
    },
    {
      title: <Trans i18nKey="site.staking.modal.rules.title2">More than 5M $POLLY</Trans>,
      rate: <Trans i18nKey="site.staking.modal.rules.rate2">Base rate applies.</Trans>,
    },
  ];
  return (
    <div className="flex flex-col items-start space-y-[2.4rem] p-[2rem] rounded-[2rem] bg-white-30 w-full lg:p-[1.6rem]">
      <div className="flex flex-col items-start space-y-[2rem] w-full lg:space-y-[1.6rem]">
        <span className="text-[1.4rem] font-500 leading-[2.24rem] text-white-500 lg:text-[1.2rem] lg:leading-[1.92rem]">
          <Trans i18nKey="site.staking.modal.rules.title">Deposit Rules</Trans>
        </span>
        <div className="w-full bg-white-100 h-[0.1rem]" />
      </div>
      <div className="flex flex-col space-y-[1.2rem] w-full">
        {rulesList.map((item, itemIndex) => {
          return (
            <div
              className="relative flex items-center justify-between w-full text-[1.4rem] font-500 leading-[2.24rem]"
              key={itemIndex}
            >
              <span className="text-white-500">{item?.title}</span>
              <span className="text-[#51F689]">{item?.rate}</span>
            </div>
          );
        })}
      </div>
      <span className="text-[1.4rem] text-white font-500 leading-[2.24rem]">
        <Trans i18nKey="site.staking.modal.rules.maxDeposit">Max 3 deposits per period.</Trans>
      </span>
    </div>
  );
};
