import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

export const usePreviewMode = () => {
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [isDoneCheckedPreview, setIsDoneCheckedPreview] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const previewUserAddress = searchParams.get('previewUser') || false;
  const navigate = useNavigate();

  const setPreviewMode = (id, route) => {
    setSearchParams((params) => {
      params.set('previewUser', id);
      return params;
    });

    if (route) {
      navigate({
        pathname: route,
        search: location.search,
      });
    } else {
      navigate(0);
    }
  };

  const removePreviewMode = () => {
    searchParams.delete('previewUser');
    setIsPreviewMode(false);
    navigate(0);
  };

  useEffect(() => {
    if (!!searchParams && !!searchParams.get('previewUser')) {
      setIsPreviewMode(true);
    } else {
      setIsPreviewMode(false);
    }
    setIsDoneCheckedPreview(true);
  }, [searchParams]);

  return {
    setPreviewMode,
    removePreviewMode,
    previewUserAddress,
    isPreviewMode,
    isDoneCheckedPreview,
  };
};
