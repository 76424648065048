import { useState } from 'react';
import { useGetContract } from './useGetContract';
import { ContractNames } from '../constants';
import { useWeb3React } from '@web3-react/core';

export const useTierUpline = () => {
  const { account } = useWeb3React();
  const { getContract } = useGetContract();
  const [isCalled, setIsCalled] = useState(false);
  const [upline, setUpline] = useState(null);

  const resetUplineTier = () => {
    setIsCalled(false);
    setUpline(null);
  };

  const getUpline = async () => {
    setIsCalled(false);
    try {
      const contract = await getContract(ContractNames.TIERS_SALE);
      const result = await contract.users(account);

      if (result) {
        if (result.currentReferral === '0x0000000000000000000000000000000000000000') {
          setUpline(null);
        } else {
          setUpline(result?.currentReferral);
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsCalled(true);
    }
  };

  return {
    upline,
    getUpline,
    resetUplineTier,
    isCalled,
  };
};
