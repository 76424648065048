import React from 'react';
import { Cards } from './Cards';
import { Table } from './Table';

export const Earn = () => {
  return (
    <div className="flex flex-col space-y-[10.2rem] items-center justify-center w-full target-earn-bg mt-[12rem] lg:space-y-[3rem] ">
      <div className="flex flex-col items-center space-y-[6rem] text-center lg:space-y-[3rem]">
        <span className="target-title-color text-[9rem] font-600 leading-[9.9rem] max-w-[54.7rem] lg:text-[3.2rem] lg:leading-[3.52rem] lg:px-[9rem]">
          How much you earn here
        </span>
        <span className="text-[2.4rem] text-white-500 font-500 leading-[3.84rem] lg:text-[1.4rem] lg:leading-[2.24rem]">
          You’re Not Just Registering –
          <span className="text-white">
            You’re Earning Instantly <br /> with Social Mining
          </span>
        </span>
      </div>
      <Cards />
      <Table />
    </div>
  );
};
