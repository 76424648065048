import React from 'react';
import config from './helpers/config';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { PreviewPage } from './pages/PreviewPage';
import { Register } from './pages/Register';
import { Login } from './pages/Login';
import { Dashboard } from './pages/Dashboard';
import { Program } from './pages/Program';
import { Level } from './pages/Level';
import { Landing } from './pages/Landing';
import { StakingPage } from './pages/Staking';
import { Energy } from './pages/Energy';
import { Profile } from './pages/Profile';
import { RefferalCodeTransfer } from './pages/RefferalCodeTransfer';
import { Stats } from './pages/Stats';
import { Target } from './pages/Target';

export const RoutesFind = () => {

  const routes = () => {
    return (
      <Routes>
        <Route path="/register" element={<Register />} />
        {/* <Route path="/w" element={<Login />} /> */}
        <Route path="/" element={<Landing />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/dashboard/:program" element={<Program />} />
        <Route path="/r/:refKey" element={<RefferalCodeTransfer />} />
        <Route path="/level" element={<Level />} />
        <Route path="/energy" element={<Energy />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/stats" element={<Stats />} />
        <Route path="/entryPass" element={<PreviewPage />} />
        <Route path="/staking" element={<StakingPage />} />
        <Route path="/offer" element={<Target />} />
      </Routes>
    );
  };

  return <Router>{routes()}</Router>;
};
