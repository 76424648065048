import React from 'react';
import { SocialLinksComp } from '../../../components/SocialsListComp';
import telegramIcon from '../../../assets/icons/socials/telegramIcon.svg';
import youtubeIcon from '../../../assets/icons/socials/youtubeIcon.svg';
import twitterIcon from '../../../assets/icons/socials/twitterIcon.svg';
import discordIcon from '../../../assets/icons/socials/discordIcon.svg';
import galxeIcon from '../../../assets/icons/socials/galxeIcon.svg';
import redditIcon from '../../../assets/icons/socials/redditIcon.svg';
import { socialLinks } from '../../../helpers/socialLinks';
import { Link } from 'react-router-dom';

export const HurryUp = () => {
  const socialLists = [
    {
      icon: telegramIcon,
      link: socialLinks.telegramChannel,
    },
    {
      icon: youtubeIcon,
      link: socialLinks.youtube,
    },
    {
      icon: twitterIcon,
      link: socialLinks.twitter,
    },
    {
      icon: discordIcon,
      link: socialLinks.discord,
    },
    {
      icon: galxeIcon,
      link: socialLinks.galxe,
    },
    {
      icon: redditIcon,
      link: socialLinks.reddit,
    },
  ];
  return (
    <div className="flex flex-col items-center w-full pt-[12rem] pb-[26.5rem] hurryUp-bg lg:pt-[10.5rem] lg:pb-[7.5rem] lg:px-[2rem]">
      <div className="flex items-center space-x-[2.8rem] lg:space-x-[1.3rem] ">
        {socialLists.map((item, itemIndex) => {
          return (
            <Link className="hover:opacity-80 custom-transition" target="blank" to={item?.link} key={itemIndex}>
              <img className="w-[9.2rem] lg:w-[4.5rem]" src={item?.icon} />
            </Link>
          );
        })}
      </div>
      <div className="flex flex-col items-center text-center space-y-[14rem] max-w-[95.5rem] mt-[15.7rem] lg:mt-[4rem] lg:space-y-[4rem]">
        <span className="text-[5rem] text-white font-500 lg:text-[2.4rem]">Start Earning Now</span>
        <span className="text-[12rem] font-600 target-title-color lg:text-[5rem] lg:leading-[5.5rem]">
          Hurry up and start earning with Polynetica SMP
        </span>
        <span className="text-[2.4rem] text-white leading-[3.84rem] font-500 mix-blend-overlay lg:text-[1.4rem] lg:leading-[2.24rem]">
          You can simply register and start receiving cryptocurrency through spillovers, or accelerate your earnings by
          engaging partners and closing cycles faster. Duo and Quattro provide complete control over your SMP income.
        </span>
      </div>
    </div>
  );
};
