import React, { useEffect, useState, useRef } from 'react';
import { Header } from '../../features/landing/Header';
import { StepsList } from '../../features/landing/ProjectSteps/StepsList';
import { ProjectSteps } from '../../features/landing/ProjectSteps';
import { PolyPower } from '../../features/landing/PolyPower';
import { Partnerships } from '../../features/landing/Partnerships';
import { Programs } from '../../features/landing/Programs';
import { Advantage } from '../../features/landing/Advantage';
import { Journey } from '../../features/landing/Journey';
import { Economy } from '../../features/landing/Economy';
import { Footer } from '../../features/landing/Footer';
import { PROJECT_STATUS } from '../../helpers/projectProgress';
import { ScrollAnimation } from '../../components/Landing/ScrollAnimation';
import { Overview } from '../../features/landing/Overview';
import { LoginBanner } from '../../features/landing/LoginBanner';
import { DexBanner } from '../../features/landing/DexBanner';

export const Landing = () => {
  const [currentProjectStatus, setCurrentProjectStatus] = useState(PROJECT_STATUS.TIERS);

  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 },
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => observer.disconnect();
  }, []);

  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  const section4Ref = useRef(null);
  const section5Ref = useRef(null);

  const desktopOffsets = {
    tiers: 100,
    publicSale: 70,
    programs: -5,
    journey: -70,
    economy: -100,
  };

  const mobileOffsets = {
    tiers: 120,
    publicSale: 80,
    programs: 30,
    journey: 50,
    economy: 50,
  };

  const scrollToSection = (ref, offset) => {
    if (ref && ref.current) {
      const elementPosition = ref.current.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({ top: elementPosition - offset, behavior: 'smooth' });
    }
  };

  const isMobile = window.innerWidth <= 992;
  const sectionOffsets = isMobile ? mobileOffsets : desktopOffsets;

  return (
    <div className="select-none relative flex flex-col space-y-[6rem] bg-[#020204] items-center justify-start min-h-screen min-w-full overflow-hidden lg:space-y-0 pt-[8rem] lg:pt-[16rem]">
      <Header
        currentProjectStatus={currentProjectStatus}
        onNavigate={(section) => {
          if (section === 'tiers') scrollToSection(section1Ref, sectionOffsets.tiers);
          if (section === 'publicSale') scrollToSection(section2Ref, sectionOffsets.publicSale);
          if (section === 'programs') scrollToSection(section3Ref, sectionOffsets.programs);
          if (section === 'journey') scrollToSection(section4Ref, sectionOffsets.journey);
          if (section === 'economy') scrollToSection(section5Ref, sectionOffsets.economy);
        }}
      />
      <div className="flex flex-col items-center w-full fade">
        <ScrollAnimation className="flex flex-col w-full px-[2rem] max-w-[140rem] lg:max-w-full lg:px-0">
          <LoginBanner />
        </ScrollAnimation>
        <ScrollAnimation className="px-[2rem] max-w-[140rem] lg:max-w-full">
          <DexBanner />
        </ScrollAnimation>
        <ScrollAnimation>
          <PolyPower />
        </ScrollAnimation>
        <ScrollAnimation className="flex flex-col max-w-[120rem] lg:max-w-full lg:px-[2rem]">
          <Partnerships />
        </ScrollAnimation>
        <div className="flex flex-col max-w-[120rem] lg:max-w-full lg:px-[2rem]" ref={section3Ref}>
          <ScrollAnimation>
            <Programs />
          </ScrollAnimation>
        </div>
        <ScrollAnimation className="flex flex-col max-w-[100rem] lg:max-w-full lg:px-[2rem]">
          <Advantage />
        </ScrollAnimation>
        <div className="flex flex-col w-full max-w-[120rem] lg:max-w-full lg:px-[2rem]" ref={section4Ref}>
          <ScrollAnimation>
            <Journey />
          </ScrollAnimation>
        </div>
        <div className="flex flex-col w-full max-w-[120rem] lg:max-w-full lg:px-[2rem]" ref={section5Ref}>
          <ScrollAnimation>
            <Economy />
          </ScrollAnimation>
        </div>
        <ScrollAnimation className="flex flex-col w-full max-w-[120rem] lg:max-w-full lg:px-[2rem]">
          <Overview />
        </ScrollAnimation>
        <div className="flex flex-col w-full max-w-[160rem] lg:max-w-full lg:px-[2rem]">
          <img className="absolute left-0 bottom-0 z-[0] lg:w-[30rem]" src="/images/landing/overview/shadow.webp" />
          <img
            className="absolute right-0 bottom-0 z-[0] lg:w-[30rem]"
            src="/images/landing/overview/rightShadow.webp"
          />
          <Footer />
        </div>
      </div>
    </div>
  );
};
