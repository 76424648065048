import dashboardIcon from '../assets/icons/menu/normal/dashboardIcon.svg';
import statisticsIcon from '../assets/icons/menu/normal/statisticsIcon.svg';
import documentsIcon from '../assets/icons/menu/normal/documentsIcon.svg';
import calculatorIcon from '../assets/icons/menu/normal/calculatorIcon.svg';
import tgIcon from '../assets/icons/menu/normal/tgIcon.svg';
import dashboardActiveIcon from '../assets/icons/menu/active/dashboardIcon.svg';
import statisticsActiveIcon from '../assets/icons/menu/active/statisticsIcon.svg';
import documentsActiveIcon from '../assets/icons/menu/active/documentsIcon.svg';
import calculatorActiveIcon from '../assets/icons/menu/active/calculatorIcon.svg';
import tgActiveIcon from '../assets/icons/menu/active/tgIcon.svg';
import energyIcon from '../assets/icons/menu/normal/energyIcon.svg';
import energyActiveIcon from '../assets/icons/menu/active/energyIcon.svg';

import logoutIcon from '../assets/icons/main/logout.svg';

export const menuList = [
  {
    icon: dashboardIcon,
    activeIcon: dashboardActiveIcon,
    title: 'Dashboard',
    href: '/dashboard',
    key: 'dashboard',
  },
  {
    icon: statisticsIcon,
    activeIcon: statisticsActiveIcon,
    title: 'Statistics',
    href: '/stats',
    key: 'stats',
  },
  {
    icon: energyIcon,
    activeIcon: energyActiveIcon,
    title: 'Energy',
    href: '/energy',
    key: 'energy',
  },
  {
    icon: logoutIcon,
    title: 'Logout',
    href: '/',
    key: 'logout',
  },
  // {
  //   icon: documentsIcon,
  //   activeIcon: documentsActiveIcon,
  //   title: 'Documents',
  //   href: '/',
  //   key: '',
  // },
  // {
  //   icon: calculatorIcon,
  //   activeIcon: calculatorActiveIcon,
  //   title: 'Calculator',
  //   href: '/',
  //   key: '',
  // },
  // {
  //   icon: tgIcon,
  //   activeIcon: tgActiveIcon,
  //   title: 'Telegram bots',
  //   href: '/',
  //   key: '',
  // },
];
