import React from 'react';
import { Future } from '../../features/target/Future';
import { Header } from '../../features/landing/Header';
import { Earn } from '../../features/target/Earn';
import { StagesSocialMining } from '../../features/target/StagesSocialMining';
import { Streams } from '../../features/target/Streams';
import { DuoTable } from '../../features/target/DuoTable';
import { QuattroTable } from '../../features/target/QuattroTable';
import { Actions } from '../../features/target/Actions';
import { HurryUp } from '../../features/target/HurryUp';
import { TargetFooter } from '../../features/target/Footer';
import { Registration } from '../../features/target/Registration';

export const Target = () => {
  return (
    <div className="select-none relative flex flex-col bg-[#0C0C0E] items-center justify-start min-h-screen min-w-full overflow-hidden lg:space-y-0 ">
      <Header stakingBanner={false} className="!bg-[rgba(12,12,14,0.80)]" />
      <Future />
      <Earn />
      <Registration />
      <StagesSocialMining />
      <Streams />
      <div className="flex items-center flex-col space-y-[4rem] w-full relative lg:px-[2rem]">
        <DuoTable />
        <QuattroTable />
        <img
          className="absolute right-0 top-[-10rem] max-h-[200rem] z-[0] lg:hidden"
          src="/images/target/orangeShadow.webp"
        />
      </div>
      <Actions />
      <HurryUp />
      <TargetFooter />
    </div>
  );
};
