import React from 'react';
import { ContractAddress } from './ContractAddress';
import { Banner } from './Banner';
import { RoadMap } from './RoadMap';

export const LoginBanner = () => {
  return (
    <div className="flex flex-col space-y-[2rem] w-full lg:space-y-0">
      <ContractAddress />
      <Banner />
      <RoadMap />
    </div>
  );
};
