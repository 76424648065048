import React from 'react';

export const MobTitle = ({ icon, title, className }) => {
  return (
    <div className={`hidden lg:flex flex items-center space-x-[1.2rem] ${className}`}>
      <img className="w-[2.4rem] h-[2.4rem]" src={icon} />
      <span className="text-[2.4rem] font-500 text-white">{title}</span>
    </div>
  );
};
