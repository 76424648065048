import React from 'react';
import { BaseLayout } from 'layouts';
import { Profile } from '../../features/dashboard/Profile';
import { Stats } from '../../features/dashboard/Stats';
import { Programs } from '../../features/dashboard/Programs';
import { RecentActivity } from '../../features/dashboard/RecentActivity';

export const Dashboard = () => {
  return (
    <BaseLayout className="lg:!bg-[#0C0C0E]">
      <Profile />
      <Programs />
      <RecentActivity />
    </BaseLayout>
  );
};
