import React from 'react';

export const ButtonLanding = ({ className = '', type = 'orange', children, onClick, ...props }) => {
  const typeClasses = {
    white: 'bg-white text-black border-[0.1rem] border-solid border-white-100 hover:bg-white-800',
    grey: 'bg-white-50 border-white-100 backdrop-blur-[10px] text-white hover:bg-white-100 hover:border-white-500',
    transparent:
      'rounded-[1.2rem] botder-solid border-[0.1rem] border-white-300 p-[0.8rem] px-[1.2rem] hover:bg-white-50 hover:border-white-500',
    purple:
      'bg-[#985AFF] border-white-50 text-white hover:bg-[#B678FF] hover:shadow-[0px_10px_24px_0px_rgba(182,120,255,0.25)]',
    orange:
      'bg-[#FCB73E] border-white-50 text-[#020204] hover:bg-[#FFC664] hover:shadow-[0px_10px_24px_0px_rgba(252,183,62,0.25)] lg:rounded-[1.6rem] lg:text-[1.4rem]',
    purple:
      'bg-[rgba(152,90,255,0.10)] border-transparent !text-[1.4rem] !rounded-[0.8rem] text-[#985AFF] hover:bg-[rgba(152,90,255,0.20)] hover:border-[rgba(152,90,255,0.20)]',
  };
  const buttonClass = typeClasses[type] || typeClasses.orange;
  return (
    <button
      onClick={onClick}
      className={`${className} ${buttonClass} flex items-center justify-center custom-transition rounded-[1.2rem] px-[1.6rem] text-[1.6rem] font-500 border-[0.1rem] border-solid leading-normal tracking-[-0.48px] disabled:pointer-events-none`}
      {...props}
    >
      {children}
    </button>
  );
};
