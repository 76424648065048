import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DEX_START_DATA } from '../../../helpers/tiers';
import { isBefore, fromUnixTime } from 'date-fns';

export const DexBanner = () => {
  const [isOpenDex, setIsOpenDex] = useState(!isBefore(new Date(), fromUnixTime(DEX_START_DATA)));

  useEffect(() => {
    let interval;
    if (!document?.hidden) {
      interval = setInterval(() => {
        setIsOpenDex(!isBefore(new Date(), fromUnixTime(DEX_START_DATA)));
      }, 10000);
    }
    return () => clearInterval(interval);
  }, [document]);

  return (
    <div className="relative flex justify-between items-center w-full h-[43.2rem] bg-[#08070A] rounded-[3rem] mt-[2rem] overflow-hidden lg:flex-col lg:h-full">
      <div className="flex items-center justify-between w-full h-full border-[0.1rem] border-solid border-white-100 rounded-[3rem] pl-[8rem] pr-[2rem] lg:flex-col lg:text-center lg:px-[2rem] lg:py-[4rem] lg:space-y-[4rem] lg:border-none">
        <div className="flex flex-col justify-center space-y-[4.8rem] lg:space-y-[4rem] lg:items-center">
          <div className="flex flex-col space-y-[2.4rem] lg:items-center lg:justify-center">
            <div className="flex flex-col items-start space-y-[1.2rem] lg:items-center lg:justify-center">
              <div className="animate-pulse flex items-center justify-center px-[0.8rem] h-[3rem] rounded-[0.8rem] bg-[rgba(175,127,255,0.20)]">
                <span className="text-[1.6rem] text-[#AF7FFF] font-500 leading-[2.24rem]">New</span>
              </div>
              {isOpenDex ? (
                <span className="text-[4.8rem] text-white font-600 tracking-[-0.48px] lg:text-[4rem] lg:tracking-[-0.4px]">
                  $POLLY is Now Live: Start Trading on Uniswap
                </span>
              ) : (
                <span className="text-[4.8rem] text-white font-600 tracking-[-0.48px] lg:text-[4rem] lg:tracking-[-0.4px]">
                  $POLLY is Ready for DEX Listing
                </span>
              )}
            </div>
            {isOpenDex ? (
              <span className="text-[1.6rem] text-[#F0F0F0] font-500 leading-[2.56rem] opacity-50 max-w-[35rem]">
                $POLLY is live on Uniswap, and it’s time to dive in. Don’t miss your chance to buy and trade $POLLY
                token!
              </span>
            ) : (
              <span className="text-[1.6rem] text-[#F0F0F0] font-500 leading-[2.56rem] opacity-50 max-w-[35rem]">
                We’re now preparing for the highly anticipated DEX listing on Uniswap. Stay connected and get ready for
                trading to start soon!
              </span>
            )}
          </div>

          <Link
            to="https://app.uniswap.org/explore/tokens/polygon/0x53a7196eff4049d1049574ebb0f4a10376913a3d"
            target="_blank"
            className={`z-[10] flex items-center justify-center bg-[#985AFF] h-[4rem] rounded-[1.2rem] w-[20rem] custom-transition hover:bg-[#AF7FFF]`}
          >
            {isOpenDex ? (
              <span className="text-white text-[1.6rem] font-500 tracking-[-0.48px]">Buy $POLLY</span>
            ) : (
              <span className="text-white text-[1.6rem] font-500 tracking-[-0.48px]">View Pool on Uniswap</span>
            )}
          </Link>
        </div>
        <video
          preload="none"
          className={`w-[65.6rem] h-[39.6rem] right-0 bottom-0 z-[1] lg:h-full lg:w-full lg:max-w-[45rem]`}
          autoPlay
          loop
          muted
          playsInline
        >
          <source src={`/images/landing/dexBanner/video.mp4`} type="video/mp4" />
        </video>
        <div className="!mt-0 z-[2] absolute top-0 left-0 landing-dex-banner w-full h-full"></div>
      </div>
    </div>
  );
};
