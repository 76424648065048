import React from 'react';

export const Actions = () => {
  return (
    <div className="flex flex-col items-center justify-start w-full pt-[10rem] pb-[13rem] relative lg:px-[2rem] lg:pt-[6rem] lg:pb-[0]">
      <div className="flex flex-col items-start w-full lg:max-w-full max-w-[120rem]">
        <span className="target-title-color text-[6rem] font-600 leading-[6.6rem] max-w-[70rem] lg:text-[3rem] lg:leading-[3.3rem]">
          XP, Dashboard, Staking, Energy Reward NFT & $POLLY
        </span>
        <span className="hidden lg:flex text-white-500 font-500 text-[1.4rem] mt-[3.2rem]">
          Every action you <span className="text-white pl-[0.5rem]"> take increases your income flow</span>
        </span>
      </div>
      <div className="flex flex-col relative w-full h-[90rem] lg:max-w-full max-w-[120rem] lg:mt-[5rem] lg:h-[110rem]">
        <img
          className="absolute left-0 z-[0] lg:left-auto lg:right-[-5rem] lg:scale-[1.4] lg:z-[22] lg:max-w-[35rem]"
          src="/images/target/Actions/1.webp"
        />
        <img
          className="absolute right-[1rem] top-[2rem] z-[11] lg:right-auto lg:left-[-1.8rem] lg:top-[17rem] lg:scale-[1.4] lg:z-[11] lg:max-w-[35rem]"
          src="/images/target/Actions/2.webp"
        />
        <img
          className="absolute top-[20rem] left-[1.5rem] lg:scale-[1.4] lg:top-[35rem] lg:rotate-[-15.035deg] lg:left-auto lg:right-[-4rem] lg:max-w-[35rem] lg:z-[1]"
          src="/images/target/Actions/3.webp"
        />
        <img
          className="absolute right-[1rem] top-[30rem] z-[1] lg:scale-[1.4] lg:right-auto lg:left-[2rem] lg:rotate-[7deg] lg:top-[57rem] lg:max-w-[35rem] lg:z-[0]"
          src="/images/target/Actions/4.webp"
        />
        <img
          className="absolute top-[47rem] left-[5rem] z-[0] lg:scale-[1.4] lg:left-auto lg:right-[-2rem] lg:top-[81rem] lg:max-w-[35rem]"
          src="/images/target/Actions/5.webp"
        />
        <span className="text-[3rem] text-white-500 font-500 absolute max-w-[42rem] right-0 top-[-6rem] lg:static lg:text-[1.4rem] lg:mt-[3.2rem] lg:hidden">
          Every action you <span className="text-white">take increases your income flow</span>
        </span>
        <img className="absolute top-[-30rem] z-[0] lg:top-[-25rem] lg:left-0" src="/images/target/Actions/orangeShadow.webp" />
        <img
          className="absolute bottom-[-15rem] left-[10rem] z-[0] lg:hidden"
          src="/images/target/Actions/greenShadow.webp"
        />
      </div>
      <div className="flex flex-col items-center space-y-[4.8rem] text-center lg:space-y-[2.4rem]">
        <span className="text-[12rem] font-600 target-title-color lg:text-[5rem]">Every Action Counts</span>
        <span className="text-[3rem] text-white-500 font-500 leading-[4.8rem] max-w-[73.7rem] lg:text-[1.6rem] lg:leading-[2.56rem]">
          Watch how each move you <span className="text-white">make boosts your income stream.</span>
        </span>
      </div>
      <img className="absolute top-0 right-0 z-[0] lg:hidden" src="/images/target/Actions/purpleShadow.webp" />
    </div>
  );
};
